import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Table from '../../../components/templates/Table/Table';
import {
  type HomeTableColumns,
  SortOrder,
  FilterType,
  TherapistFilterOption,
  SortedCol,
} from '../../../types/tables';
import { useLoading } from '../../../providers/LoadingProvider';
import {
  type StyleSheet,
  useThemedComponent,
  ThemeContent,
} from '../../../providers/ThemeProvider';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../router';
import SearchBar from '../../../components/atoms/SearchBar/SearchBar';

import DropDownMultiSelect from '../../../components/atoms/DropDownSelect/DropDownMultiSelect';
import i18n from '../../../translations';
import { useTableColumns } from '../../../hooks/useTableColumns';
import DropDownSelect from '../../../components/atoms/DropDownSelect/DropDownSelect';
import { useHomeHelpers } from './useHomeHelpers';
import {
  KNOWN_CLOUD_FUNCTIONS,
  useCloudContext,
} from '../../../providers/CloudProvider';
import { useAuth } from '../../../providers/AuthProvider';
import { ROUTES_TYPE } from '../../../routes/ProtectedRoute';
import { HomeTile } from '../../../components/atoms/HomeTile/HomeTile';
import { ReactComponent as ExclamationCircle } from '../../../assets/ExclamationCircle.svg';
import { ReactComponent as UsersPrimary } from '../../../assets/UsersPrimary.svg';
import { useLogging } from '../../../providers/LoggingProvider';
import { commonTableStyles } from '../../../components/templates/Table/tableUtil';

const DEFAULT_SORTING = [
  { colKey: 'subjectID', index: 0, order: SortOrder.ASC },
] as SortedCol[];

export default function Home() {
  const { roles } = useAuth();
  const blankScreen = useMemo(() => {
    return roles?.every(roles =>
      ROUTES_TYPE.internalCreationManagerOnly.every(r => r === roles),
    );
  }, [roles]);

  const logger = useLogging(Home.name);
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { styles, theme } = useThemedComponent([commonTableStyles, HomeStyles]);
  const { cloudService } = useCloudContext();

  const [warningCount, setWarningCount] = useState(0);
  const [alertCount, setAlertCount] = useState(0);
  const [activeSubjectCount, setActiveSubjectCount] = useState(0);

  const { HOME_COLUMNS, transformForExport } = useHomeHelpers();
  const {
    columnHelper: columnHelperActive,
    tableData: tableDataActive,
    setTableData: setTableDataActive,
    filters: filtersActive,
    setFilters: setFiltersActive,
    sorting: sortingActive,
    setSorting: setSortingActive,
    search: searchActive,
    setSearch: setSearchActive,
    rowSelection: rowSelectionActive,
    setRowSelection: setRowSelectionActive,
    ExportDropDown: ExportDropDownActive,
    lastSynced: lastSyncedActive,
    setLastSynced: setLastSyncedActive,
  } = useTableColumns<HomeTableColumns>({
    tableName: i18n.t('home'),
    defaultSorting: DEFAULT_SORTING,
    transformExportData: transformForExport,
  });

  const {
    columnHelper: columnHelperInactive,
    tableData: tableDataInactive,
    setTableData: setTableDataInactive,
    filters: filtersInactive,
    setFilters: setFiltersInactive,
    sorting: sortingInactive,
    setSorting: setSortingInactive,
    search: searchInactive,
    setSearch: setSearchInactive,
    rowSelection: rowSelectionInactive,
    setRowSelection: setRowSelectionInactive,
    ExportDropDown: ExportDropDownInactive,
  } = useTableColumns<HomeTableColumns>({
    tableName: i18n.t('home'),
    defaultSorting: DEFAULT_SORTING,
    transformExportData: transformForExport,
  });

  const [selectedProfileIDsActive, setSelectedProfileIDsActive] = useState<
    string[]
  >([]);

  // update the selected profile IDs when the row selection changes in the active table
  useEffect(() => {
    const selectedRowsIndexes = Object.keys(rowSelectionActive);
    const newRowSelection = tableDataActive
      .filter((_, index) => selectedRowsIndexes.includes(index.toString()))
      .map(row => {
        // this is garunteed to be a home row
        const homeRow = row as HomeTableColumns;
        return homeRow.profileID;
      });
    setSelectedProfileIDsActive(newRowSelection);
    // clear the secondary therapist selection when the row selection changes
    setSecondaryTherapistSelectionActive(undefined);
  }, [rowSelectionActive, tableDataActive]);

  // all the therapist options
  const [therapists, setTherapists] = useState<TherapistFilterOption[]>([]);

  // selected therapists
  const [
    primaryTherapistFilterSelectedIndexesActive,
    setPrimaryTherapistFilterSelectedIndexesActive,
  ] = useState<number[]>();
  const [
    primaryTherapistFilterSelectedIndexesInactive,
    setPrimaryTherapistFilterSelectedIndexesInactive,
  ] = useState<number[]>();

  // when the primary therapist filter changes, refetch the data for the active table
  useEffect(() => {
    if (primaryTherapistFilterSelectedIndexesActive) {
      const newFilters = primaryTherapistFilterSelectedIndexesActive.map(
        index => ({
          columnName: 'primaryTherapist',
          filterType: FilterType.POINTER,
          equalTo: therapists[index].id,
        }),
      );
      setFiltersActive(newFilters);
    }
  }, [
    primaryTherapistFilterSelectedIndexesActive,
    setFiltersActive,
    therapists,
  ]);

  // when the primary therapist filter changes, refetch the data for the inactive table
  useEffect(() => {
    if (primaryTherapistFilterSelectedIndexesInactive) {
      const newFilters = primaryTherapistFilterSelectedIndexesInactive.map(
        index => ({
          columnName: 'primaryTherapist',
          filterType: FilterType.POINTER,
          equalTo: therapists[index].id,
        }),
      );
      setFiltersInactive(newFilters);
    }
  }, [
    primaryTherapistFilterSelectedIndexesInactive,
    setFiltersInactive,
    therapists,
  ]);

  // selected secondary therapists
  const [
    secondaryTherapistFilterSelectedIndexesActive,
    setSecondaryTherapistFilterSelectedIndexesActive,
  ] = useState<number[]>();
  const [
    secondaryTherapistFilterSelectedIndexesInactive,
    setSecondaryTherapistFilterSelectedIndexesInactive,
  ] = useState<number[]>();

  // when the secondary therapist filter changes, refetch the data for the active table
  useEffect(() => {
    if (secondaryTherapistFilterSelectedIndexesActive) {
      const newFilters = secondaryTherapistFilterSelectedIndexesActive.map(
        index => ({
          columnName: 'secondaryTherapist',
          filterType: FilterType.POINTER,
          equalTo: therapists[index].id,
        }),
      );
      setFiltersActive(newFilters);
    }
  }, [
    secondaryTherapistFilterSelectedIndexesActive,
    setFiltersActive,
    therapists,
  ]);

  // when the secondary therapist filter changes, refetch the data for the inactive table
  useEffect(() => {
    if (secondaryTherapistFilterSelectedIndexesInactive) {
      const newFilters = secondaryTherapistFilterSelectedIndexesInactive.map(
        index => ({
          columnName: 'secondaryTherapist',
          filterType: FilterType.POINTER,
          equalTo: therapists[index].id,
        }),
      );
      setFiltersInactive(newFilters);
    }
  }, [
    secondaryTherapistFilterSelectedIndexesInactive,
    setFiltersInactive,
    therapists,
  ]);

  const inactiveDataFetcher = useCallback(async () => {
    try {
      // make our api calls
      setLoading(true);

      const { data: fetchedDataInactive } =
        await cloudService.requestHomeSummary({
          sort: sortingInactive,
          search: searchInactive,
          filters: filtersInactive,
          active: false,
        });

      setTableDataInactive(fetchedDataInactive);
    } catch (e) {
      console.error('Error fetching inactive home summary data!', e);
    } finally {
      setLoading(false);
    }
  }, [
    setLoading,
    cloudService,
    sortingInactive,
    searchInactive,
    filtersInactive,
    setTableDataInactive,
  ]);

  const activeDataFetcher = useCallback(async () => {
    try {
      // make our api calls
      setLoading(true);

      const {
        data: fetchedDataActive,
        therapists: fetchedTherapists,
        lastSynced,
        warnings,
        alerts,
        totalEntries,
      } = await cloudService.requestHomeSummary({
        sort: sortingActive,
        search: searchActive,
        filters: filtersActive,
        active: true,
      });

      // fill in the therapists filters
      if (therapists.length !== fetchedTherapists.length) {
        setTherapists(fetchedTherapists);
      }

      // set the data for the component to use
      setTableDataActive(fetchedDataActive);

      // set the time this table is synced
      setLastSyncedActive(lastSynced);

      // set the counts
      setWarningCount(warnings);
      setAlertCount(alerts);
      setActiveSubjectCount(totalEntries);
    } catch (e) {
      console.error('Error fetching active home summary data!', e);
    } finally {
      setLoading(false);
    }
  }, [
    setLoading,
    cloudService,
    sortingActive,
    searchActive,
    filtersActive,
    therapists.length,
    setTableDataActive,
    setLastSyncedActive,
  ]);

  // fetch the data on a. first load and b. any sorting, searching, and filtering
  useEffect(() => {
    activeDataFetcher().catch(e => {
      console.error(e);
    });
  }, [activeDataFetcher]);
  useEffect(() => {
    inactiveDataFetcher().catch(e => {
      console.error(e);
    });
  }, [inactiveDataFetcher]);

  // set when a therapistId is provided, remove if it is undefined
  const setOrRemoveSecondaryTherapist = useCallback(
    (therapistId: string | undefined) => {
      if (selectedProfileIDsActive.length == 0) {
        return;
      }
      const params = JSON.parse(
        JSON.stringify({
          profileIds: selectedProfileIDsActive,
          secondaryTherapistId: therapistId,
        }),
      );
      cloudService
        .run(KNOWN_CLOUD_FUNCTIONS.SET_SECONDARY_THERAPIST, params)
        .then(() => {
          activeDataFetcher();
          // clear row selections once secondary therapist is set
          setRowSelectionActive({});
        })
        .catch(e => {
          logger.error(e);
        });
    },
    [
      activeDataFetcher,
      cloudService,
      logger,
      selectedProfileIDsActive,
      setRowSelectionActive,
    ],
  );

  // set the secondary therapist for active table
  const [
    secondaryTherapistSelectionActive,
    setSecondaryTherapistSelectionActive,
  ] = useState<number | undefined>(0);

  useEffect(() => {
    if (
      secondaryTherapistSelectionActive &&
      selectedProfileIDsActive.length > 0
    ) {
      const params = JSON.parse(
        JSON.stringify({
          profileIds: selectedProfileIDsActive,
          secondaryTherapistId:
            therapists[secondaryTherapistSelectionActive].id,
        }),
      );
      cloudService
        .run(KNOWN_CLOUD_FUNCTIONS.SET_SECONDARY_THERAPIST, params)
        .then(() => {
          activeDataFetcher();
          // clear row selections once secondary therapist is set
          setRowSelectionActive({});
        })
        .catch(e => {
          console.error(e);
        });
    }
  }, [
    secondaryTherapistSelectionActive,
    selectedProfileIDsActive,
    cloudService,
    activeDataFetcher,
    therapists,
    setRowSelectionActive,
  ]);

  const hasInternalRoles = useMemo(() => {
    return ROUTES_TYPE.internal.some(role => roles?.includes(role));
  }, [roles]);

  if (blankScreen) {
    return <></>;
  }

  return (
    <div style={styles.pageContainer}>
      <div style={{ ...styles.pageHeader }}>
        <h1 style={styles.h1}>{i18n.t('home')}</h1>
        <div>
          <button
            style={styles.primaryButton}
            onClick={async () => {
              navigate(ROUTES.CREATE_USER.path);
            }}>
            {i18n.t('createSubject.title')}
          </button>
          {hasInternalRoles && (
            <>
              <button
                style={styles.primaryButton}
                onClick={async () => {
                  navigate(ROUTES.ADD_SENSOR.path);
                }}>
                {i18n.t('addSensor.title')}
              </button>
              <button
                style={styles.primaryButton}
                onClick={async () => {
                  navigate(ROUTES.SET_EXTERNAL_ROLES.path);
                }}>
                {i18n.t('setExternalUserRoles.setRole')}
              </button>
            </>
          )}
        </div>
      </div>
      <div style={{ ...styles.row, ...styles.homeTiles }}>
        <HomeTile
          label={i18n.t('globals.subjects')}
          underTileLabel={i18n.t('globals.numberOfActiveSubjects')}
          count={activeSubjectCount}
          Icon={UsersPrimary}
        />
        <HomeTile
          label={i18n.t('globals.warningIndicators')}
          underTileLabel={i18n.t('globals.numberOfWarning')}
          count={warningCount}
          Icon={ExclamationCircle}
          iconStyle={{ color: theme.colors.yellow }}
        />
        <HomeTile
          label={i18n.t('globals.alertIndicators')}
          underTileLabel={i18n.t('globals.numberOfAlert')}
          count={alertCount}
          Icon={ExclamationCircle}
          iconStyle={{ color: theme.colors.red }}
        />
      </div>
      <div style={styles.row}>
        <p>{i18n.t('globals.allSubjects')}</p>
        {lastSyncedActive && (
          <p style={styles.gray}>
            {`${i18n.t('globals.lastSynced')} 
          ${lastSyncedActive.toLocaleDateString(i18n.locale, { hour: '2-digit', minute: '2-digit' })}`}
          </p>
        )}
      </div>
      <hr style={styles.divider} />
      <>
        <div style={styles.topControlsContainer}>
          <div style={styles.topContolsLeft}>
            <SearchBar
              search={searchActive}
              setSearch={setSearchActive}
              id="searchActive"
            />
            <DropDownMultiSelect
              options={therapists}
              labels={therapists.map(therapist => therapist.value)}
              selectedIndexes={
                primaryTherapistFilterSelectedIndexesActive ?? []
              }
              setSelectedIndexes={
                setPrimaryTherapistFilterSelectedIndexesActive
              }
              placeholder={i18n.t('primaryTherapist')}
              style={styles.filterMenu}
            />
            <DropDownMultiSelect
              options={therapists}
              labels={therapists.map(therapist => therapist.value)}
              selectedIndexes={
                secondaryTherapistFilterSelectedIndexesActive ?? []
              }
              setSelectedIndexes={
                setSecondaryTherapistFilterSelectedIndexesActive
              }
              placeholder={i18n.t('secondaryTherapist')}
              style={styles.filterMenu}
            />
          </div>
          <div style={styles.topContolsRight}>
            <button
              style={{ ...styles.primaryButton, ...styles.filterMenu }}
              onClick={() => setOrRemoveSecondaryTherapist(undefined)}>
              {i18n.t('homePage.removeSecondaryTherapist')}
            </button>
            <DropDownSelect
              options={therapists}
              labels={therapists.map(therapist => therapist.value)}
              setSelectionIndex={(index: number | undefined) => {
                if (index !== undefined && therapists[index] !== undefined) {
                  setOrRemoveSecondaryTherapist(therapists[index].id);
                }
              }}
              placeholder={i18n.t('homePage.choseSecondaryTherapist')}
              style={{ ...styles.filterMenu, ...styles.secondaryTherapistMenu }}
              maxOptionsBeforeScroll={5}
              menuAlignment="right"
            />
            <ExportDropDownActive
              style={{ ...styles.filterMenu, ...styles.exportButton }}
            />
          </div>
        </div>
        <div style={styles.tableContainer}>
          <Table
            columnHelper={columnHelperActive}
            columns={HOME_COLUMNS}
            sorting={sortingActive}
            setSorting={setSortingActive}
            data={tableDataActive}
            selectable={true}
            rowSelection={rowSelectionActive}
            setRowSelection={setRowSelectionActive}
          />
        </div>
      </>
      <div style={{ ...styles.row, ...styles.inactiveTable }}>
        <p>{i18n.t('globals.allInactiveSubjects')}</p>
      </div>
      <hr style={styles.divider} />
      <>
        <div style={styles.topControlsContainer}>
          <div style={styles.topContolsLeft}>
            <SearchBar
              search={searchInactive}
              setSearch={setSearchInactive}
              id="searchInactive"
            />
            <DropDownMultiSelect
              options={therapists}
              labels={therapists.map(therapist => therapist.value)}
              selectedIndexes={
                primaryTherapistFilterSelectedIndexesInactive ?? []
              }
              setSelectedIndexes={
                setPrimaryTherapistFilterSelectedIndexesInactive
              }
              placeholder={i18n.t('primaryTherapist')}
              style={styles.filterMenu}
            />
            <DropDownMultiSelect
              options={therapists}
              labels={therapists.map(therapist => therapist.value)}
              selectedIndexes={
                secondaryTherapistFilterSelectedIndexesInactive ?? []
              }
              setSelectedIndexes={
                setSecondaryTherapistFilterSelectedIndexesInactive
              }
              placeholder={i18n.t('secondaryTherapist')}
              style={styles.filterMenu}
            />
          </div>
          <div style={styles.topContolsRight}>
            <ExportDropDownInactive
              style={{ ...styles.filterMenu, ...styles.exportButton }}
            />
          </div>
        </div>
        <div style={{ ...styles.tableContainer, ...styles.lastRow }}>
          <Table
            columnHelper={columnHelperInactive}
            columns={HOME_COLUMNS}
            sorting={sortingInactive}
            setSorting={setSortingInactive}
            data={tableDataInactive}
            selectable={true}
            rowSelection={rowSelectionInactive}
            setRowSelection={setRowSelectionInactive}
          />
        </div>
      </>
    </div>
  );
}

const HomeStyles = (theme: ThemeContent): StyleSheet => ({
  topControlsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 0 1em',
  },
  topContolsLeft: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  topContolsRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  filterMenu: {
    height: '2.6em',
  },
  secondaryTherapistMenu: {
    color: theme.colors.white,
    backgroundColor: theme.colors.primary,
  },
  primaryButton: {
    borderStyle: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.875rem',
    fontWeight: '500',
    cursor: 'pointer',
    padding: '0.5rem 0.75rem',
    border: 'none',
    borderRadius: '0.375rem',
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
  },
  divider: {
    borderTop: '5px solid gray',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  homeTiles: {
    marginBottom: '2%',
    marginTop: '2vmin',
  },
  gray: {
    color: theme.colors.blackGrey,
    fontSize: '0.9rem',
  },
  inactiveTable: {
    marginTop: '2%',
  },
});
