import React, { useEffect, useMemo, useState } from 'react';

import { ReactComponent as Battery0 } from '../../../assets/Battery0.svg';
import { ReactComponent as Battery100 } from '../../../assets/Battery100.svg';
import { ReactComponent as Battery25 } from '../../../assets/Battery25.svg';
import { ReactComponent as Battery50 } from '../../../assets/Battery50.svg';
import { ReactComponent as Battery75 } from '../../../assets/Battery75.svg';

export interface BatteryIconProps {
  /**
   * Battery level of the sensor.
   * If undefined, the battery level is unknown. It will be displayed as empty.
   */
  battery: number | undefined;
  svgProps?: React.SVGProps<SVGSVGElement>;
}
const BatteryIcon = (props: BatteryIconProps) => {
  const [battery, setBattery] = useState<number>(0);

  /**
   * Adjust and round the battery level to the nearest 25% for display
   * 0% -> 0%
   * 1-33% -> 25%
   * 34-66% -> 50%
   * 67-99% -> 75%
   * 100% -> 100%
   */
  useEffect(() => {
    if (props.battery === undefined || props.battery === 0) {
      setBattery(0);
      return;
    }
    if (props.battery === 100) {
      setBattery(100);
      return;
    }
    if (props.battery >= 0 && props.battery <= 33) {
      setBattery(25);
      return;
    }
    if (props.battery >= 34 && props.battery <= 66) {
      setBattery(50);
      return;
    }
    if (props.battery >= 67 && props.battery <= 99) {
      setBattery(75);
    }
  }, [props]);

  const BatteryIconSVG = useMemo(() => {
    switch (battery) {
      case 25:
        return <Battery25 {...props.svgProps} />;
      case 50:
        return <Battery50 {...props.svgProps} />;
      case 75:
        return <Battery75 {...props.svgProps} />;
      case 100:
        return <Battery100 {...props.svgProps} />;
      default:
        return <Battery0 {...props.svgProps} />;
    }
  }, [battery, props]);

  return <>{BatteryIconSVG}</>;
};
export default BatteryIcon;
