import React from 'react';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import { BoxTile } from '../BoxTile/BoxTile';
import { StyleSheet } from '../../../providers/ThemeProvider';
import { datesAreOnSameDay } from '../../../utils/dateTime';
import i18n from '../../../translations';

export enum CompletionStatus {
  COMPLETE,
  INCOMPLETE,
  TODAY,
}

export type DailyStatus = {
  date: Date;
  status: CompletionStatus;
};

export interface TileWeekGraph {
  name: string;
  weeklyData: DailyStatus[];
}

export interface WeekTilesProps {
  label: string;
  weeklyData: DailyStatus[];
}

export const WeekTiles = ({ label, weeklyData }: WeekTilesProps) => {
  const { styles } = useThemedComponent(WeekTilesStyles);

  return (
    <div style={styles.container}>
      <p style={styles.label}>{label}</p>
      <div style={styles.boxesContainer}>
        {weeklyData.map((daily, i) => (
          <BoxTile
            key={i}
            status={daily.status}
            label={daily.date.toLocaleDateString(i18n.locale, {
              weekday: 'narrow',
            })}
            underTileLabel={
              datesAreOnSameDay(daily.date, new Date())
                ? i18n.t('globals.today')
                : daily.date.toLocaleDateString(i18n.locale, {
                    month: 'short',
                    day: '2-digit',
                  })
            }
          />
        ))}
      </div>
    </div>
  );
};

const WeekTilesStyles = (): StyleSheet => ({
  container: {
    marginTop: '2%',
    marginBottom: '4%',
  },
  boxesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  label: {
    marginBottom: '2%',
    fontWeight: '450',
  },
  bold: {
    fontWeight: '500',
  },
});
