import { useMemo } from 'react';
import useErrorHandling from '../hooks/useErrorHandling';
import Parse from '../parse';
import { Profile } from './Profile';
import { OwnableObject } from './interfaces/OwnableObject';
import { DateTime } from 'luxon';

/**
 * These represent all the action plans from the user.
 * Any customized data should be stored in JSON via the meta attribute.
 */
export interface ActionPlanAttributes extends OwnableObject {
  name: string;
  actionPlan: string;
  confidence: number;
  difficulty: number;

  when: string;
  where: string;
  how: string;
  who: string;

  notes?: string;

  completeToday?: Date;
  closed?: Date;
  planID: string;
  previousRecord: ActionPlan | null;
}

export const classname = 'ActionPlan';

export class ActionPlan extends Parse.Object<ActionPlanAttributes> {
  constructor(attributes: ActionPlanAttributes) {
    super(classname, attributes);
  }
}

Parse.Object.registerSubclass(classname, ActionPlan);

export function useActionPlanService() {
  const getTimezone = useErrorHandling(async (profile: Profile) => {
    const lastDaap = await getLast(profile);
    if (lastDaap != undefined) {
      return lastDaap.attributes.timezone;
    }
    return profile.attributes.timezone;
  }, []);

  const getOpenActionPlans = useErrorHandling(async (profile: Profile) => {
    const daapsQuery = new Parse.Query(ActionPlan);
    daapsQuery.equalTo('owner', profile);
    daapsQuery.doesNotExist('closed');

    return await daapsQuery.find();
  }, []);

  const getClosedActionPlans = useErrorHandling(async (profile: Profile) => {
    const daapsQuery = new Parse.Query(ActionPlan);
    daapsQuery.equalTo('owner', profile);
    daapsQuery.exists('closed');

    return await daapsQuery.find();
  }, []);

  /**
   * Helper function to get the edits for some base action plans
   * @param profile The profile to get the current action plans edits for
   * @param basePlans The base plans to get the edits for
   * @returns The all the edits for the base plans (including the base plans themselves)
   */
  const getLatestUnfilteredSubsquentEdits = useErrorHandling(
    async (profile: Profile, basePlans: ActionPlan[]) => {
      const editsQuery = new Parse.Query(ActionPlan);
      editsQuery.equalTo('owner', profile);
      editsQuery.descending('localCreatedAt');
      editsQuery.containedIn(
        'planID',
        basePlans.map(daap => daap.get('planID')),
      );
      return await editsQuery.find();
    },
    [],
  );

  /**
   * Get the current action plans for a profile.
   * There may be 3 or less plans.
   * A base plan is the orginal before any editing
   * @param profile The profile to get the current action plans for
   * @returns array of the current base action plans
   */
  const getCurrentBaseActionPlans = useErrorHandling(
    async (profile: Profile) => {
      // get the base records, the most recent 3 (or less) that have no previousRecord
      // these could have newer edits that indicate they are closed
      const latestBaseDaapQuery = new Parse.Query(ActionPlan);
      latestBaseDaapQuery.equalTo('owner', profile);
      latestBaseDaapQuery.equalTo('previousRecord', null);
      latestBaseDaapQuery.descending('localCreatedAt');
      latestBaseDaapQuery.limit(3);
      const latest3BaseDaaps = await latestBaseDaapQuery.find();

      // get all intermediate records from base (may include the base too)
      const latestSubsequentEdits = await getLatestUnfilteredSubsquentEdits(
        profile,
        latest3BaseDaaps,
      );

      // get the planIDs of the closed plans, we shouldn't include these
      const closedPlanIds = Array.from(
        new Set(
          latestSubsequentEdits
            .filter(edit => edit.get('closed'))
            .map(edit => edit.get('planID')),
        ),
      );

      // filter out the closed plans, so we are left with the most recent plans
      const filteredDaaps = latest3BaseDaaps.filter(
        daap => !closedPlanIds.includes(daap.get('planID')),
      );

      return filteredDaaps;
    },
    [],
  );

  /**
   * Get the most recent and open 3 action plan edits for a profile
   * @param profile The profile to get the current action plans edits for
   * @returns The most recent 3 action plan edits for the profile
   */
  const getCurrentActionPlanLatestEdits = useErrorHandling(
    async (profile: Profile) => {
      // get the base records, the most recent 3 (or less) that have no previousRecord
      // these could have newer edits that indicate they are closed
      const base = await getCurrentBaseActionPlans(profile);

      // get all intermediate records from base (may include the base too)
      const latestSubsequentEdits = await getLatestUnfilteredSubsquentEdits(
        profile,
        base,
      );

      // only the edits where the base has the same planID,
      // and adding only the most recent edit
      const planMap = new Map<string, ActionPlan>();
      latestSubsequentEdits.forEach(edit => {
        const planId = edit.get('planID');
        const created = edit.get('localCreatedAt');
        const existing = planMap.get(planId);
        if (
          !existing ||
          created.getTime() > existing.get('localCreatedAt').getTime()
        ) {
          planMap.set(planId, edit);
        }
      });

      return Array.from(planMap.values());
    },
    [getCurrentBaseActionPlans],
  );

  /**
   * Get the latest action plans in a given range
   */
  const getLatestActionPlansInRange = useErrorHandling(
    async (profile: Profile, startDate: Date, endDate: Date) => {
      // Fetch timezone once
      const timezone = await getTimezone(profile);

      // Calculate the true start and end dates
      const trueStartDate = DateTime.fromJSDate(startDate, { zone: timezone })
        .startOf('day')
        .toJSDate();

      const trueEndDate = DateTime.fromJSDate(endDate, { zone: timezone })
        .endOf('day')
        .toJSDate();

      // Get the action plans within the date range
      const query = new Parse.Query(ActionPlan);
      query.equalTo('owner', profile);
      query.greaterThanOrEqualTo('localCreatedAt', trueStartDate);
      query.lessThanOrEqualTo('localCreatedAt', trueEndDate);
      query.descending('localCreatedAt');
      return await query.find();
    },
    [getTimezone],
  );

  const getFirst = useErrorHandling(async (profile: Profile) => {
    const query = new Parse.Query(ActionPlan);
    query.equalTo('owner', profile);
    query.ascending('localCreatedAt');
    query.limit(1);

    return await query.first();
  }, []);

  const getOngoingActionPlans = useErrorHandling(
    async (profile: Profile, startDate: Date, endDate: Date) => {
      // get all base plans that are created before the end date
      const basePlansQuery = new Parse.Query(ActionPlan);
      basePlansQuery.equalTo('owner', profile);
      basePlansQuery.equalTo('previousRecord', null);
      basePlansQuery.lessThan('localCreatedAt', endDate);
      const basePlans = await basePlansQuery.find();

      // get all the closed edits that are created before startDate,
      // and have the same planID as the base plan
      const editsQuery = new Parse.Query(ActionPlan);
      editsQuery.equalTo('owner', profile);
      editsQuery.lessThan('localCreatedAt', startDate);
      editsQuery.exists('closed');
      editsQuery.containedIn(
        'planID',
        basePlans.map(daap => daap.get('planID')),
      );
      const edits = await editsQuery.find();

      // filter out the base plans that have a closed edits before the start date
      const ongoingBasePlans = basePlans.filter(
        daap => !edits.some(edit => edit.get('planID') === daap.get('planID')),
      );

      // get all the edits that are created before the end date,
      // and have the same planID as the base plan
      const ongoingEditsQuery = new Parse.Query(ActionPlan);
      ongoingEditsQuery.equalTo('owner', profile);
      ongoingEditsQuery.lessThan('localCreatedAt', endDate);
      ongoingEditsQuery.containedIn(
        'planID',
        ongoingBasePlans.map(daap => daap.get('planID')),
      );

      const ongoingEdits = await ongoingEditsQuery.find();
      return ongoingEdits;
    },
    [],
  );

  const getLast = useErrorHandling(async (profile: Profile) => {
    const query = new Parse.Query(ActionPlan);
    query.equalTo('owner', profile);
    query.descending('localCreatedAt');
    query.limit(1);

    return await query.first();
  }, []);

  const addNewDAAP = useErrorHandling(async (daap: ActionPlanAttributes) => {
    const newDaap = new ActionPlan(daap);

    // should have the same acl as the profile user
    const ownerAcl = daap.owner.getACL();
    if (ownerAcl) {
      newDaap.setACL(ownerAcl);
    }

    return await newDaap.save();
  }, []);

  return useMemo(
    () => ({
      getClosedActionPlans,
      getFirst,
      getLast,
      addNewDAAP,
      getTimezone,
      getOpenActionPlans,
      getLatestActionPlansInRange,
      getCurrentBaseActionPlans,
      getCurrentActionPlanLatestEdits,
      getOngoingActionPlans,
    }),
    [
      addNewDAAP,
      getClosedActionPlans,
      getCurrentActionPlanLatestEdits,
      getCurrentBaseActionPlans,
      getFirst,
      getLast,
      getLatestActionPlansInRange,
      getOngoingActionPlans,
      getOpenActionPlans,
      getTimezone,
    ],
  );
}
