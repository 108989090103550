import {
  type ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: ThemeContent): StyleSheet => ({
  gridContainer: {
    display: 'grid',
    gap: '1%',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  tableRowContainer: {
    width: '32.5%',
  },
  primaryBtn: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    height: '2rem',
    minWidth: '7rem',
    borderStyle: 'none',
    borderRadius: '0.375rem',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.8rem',
    fontWeight: '500',
    cursor: 'pointer',
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
  },
  fixedHeightTable: {
    maxHeight: '66vh',
    overflowY: 'auto',
  },
});
