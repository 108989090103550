import {
  type ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

export default (theme: ThemeContent): StyleSheet => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  dataRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dataRowCell: {
    display: 'flex',
  },
  xAxisText: {
    color: theme.colors.primary,
    fontSize: '1rem',
    textAlign: 'center',
    marginTop: '0.5rem',
  },
  yAxisRow: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    verticalAlign: 'middle',
  },
  yAxisText: {
    color: theme.colors.primary,
    fontSize: '1rem',
    marginRight: '0.5rem',
  },
});
