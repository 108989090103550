import {
  type ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

export default (theme: ThemeContent): StyleSheet => ({
  btnGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: 1,
    gap: '1%',
  },
  dropdown: {
    position: 'relative',
  },
  menu: {
    position: 'absolute',
    width: 'auto',
    margin: 5,
    padding: 5,
    backgroundColor: theme.colors.white,
  },
  menuItem: {
    padding: 5,
    backgroundColor: theme.colors.white,
    border: 0,
    textAlign: 'center',
    borderRadius: 10,
  },
  dropdownButton: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    minWidth: '7rem',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '0.375rem',
    fontSize: '0.8rem',
    margin: 0,
  },
  transparentBg: {
    backgroundColor: theme.colors.transparentWhite,
    color: theme.colors.primary,
  },
  itemBtnContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-evenly',
    marginTop: 10,
  },
  itemBtn: {
    height: '100%',
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    borderRadius: 5,
    padding: 3,
    borderStyle: 'solid',
    borderWidth: '1px',
    minWidth: 60,
  },
  noMargin: {
    margin: 0,
  },
});
