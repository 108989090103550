import {
  type ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: ThemeContent): StyleSheet => ({
  primaryBtn: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    height: '2rem',
    minWidth: '7rem',
    borderStyle: 'none',
    borderRadius: '0.375rem',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.8rem',
    fontWeight: '500',
    cursor: 'pointer',
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  h1: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
  },
});
