import React, { useCallback, useEffect } from 'react';
import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { useTableColumns } from '../../../hooks/useTableColumns';
import {
  CompletedDAAPColumns,
  SortOrder,
  SubjectSummaryTableColumns,
} from '../../../types/tables';
import { useLoading } from '../../../providers/LoadingProvider';
import i18n from '../../../translations';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import DAAPStyles from './DAAPStyles';
import Table from '../../../components/templates/Table/Table';
import {
  COMPLETED_DAAP_COLUMNS,
  SUBJECT_SUMMARY_COLUMNS,
  transformCompletedDaapForExport,
} from './helpers';
import { commonTableStyles } from '../../../components/templates/Table/tableUtil';
import { useCloudContext } from '../../../providers/CloudProvider';
import { ROUTES } from '../../../router';
import { Group } from '../../../schemas/Profile';
import { useSubjectContext } from '../../../providers/SubjectContext';
import { useLogging } from '../../../providers/LoggingProvider';

export interface SubjectParams {
  subjectId: string;
  daapId: string;
}
interface SubjectDataResponse {
  subjectId: string;
  daapId: string;
}

export const loader = async ({
  params,
}: LoaderFunctionArgs<SubjectParams>): Promise<SubjectDataResponse> => {
  if (!params.subjectId || !params.daapId) {
    throw new Error('Invalid params');
  }

  const data = {
    subjectId: params.subjectId,
    daapId: params.daapId,
  };
  return data;
};

export default function SingleDAAPHistoryPage() {
  const pageData = useLoaderData() as SubjectDataResponse;
  const { setLoading } = useLoading();
  const { styles } = useThemedComponent([commonTableStyles, DAAPStyles]);
  const { cloudService, profileService } = useCloudContext();
  const navigate = useNavigate();
  const [name, setName] = React.useState<string>('');
  const { deactivatedAt } = useSubjectContext();

  // subject summary table state
  const {
    columnHelper: subjectSummarySolumnHelper,
    tableData: subjectSummaryTableData,
    setTableData: setSubjectSummaryTableData,
    sorting: subjectSummarySorting,
  } = useTableColumns<SubjectSummaryTableColumns>();

  // daap history table state
  const {
    columnHelper: completedDaapHistoryColumnHelper,
    tableData: completedDaapHistoryTableData,
    setTableData: setCompletedDaapHistoryTableData,
    sorting: completedDaapHistorySorting,
    setSorting: setCompletedDaapHistorySorting,
    ExportDropDown: CompletedDAAPHistoryExportDropDown,
  } = useTableColumns<CompletedDAAPColumns>({
    defaultSorting: [{ colKey: 'date', index: 10, order: SortOrder.DESC }],
    tableName: i18n.t('tables.daapGoalHistory.title'),
    transformExportData: transformCompletedDaapForExport,
  });

  const logger = useLogging(SingleDAAPHistoryPage.name);

  // fetch the data, call from a useEffect
  const dataFetcher = useCallback(async () => {
    try {
      // make our api calls
      setLoading(true);

      profileService.getProfileBySubjectId(pageData.subjectId).then(profile => {
        if (!profile || profile?.get('group') === Group.CONTROL) {
          logger.warn('Profile not found or is in control group');
          navigate(-1); // go back to previous page
        }
      });

      // get the subject summary data
      const subjectSummaryRes = await cloudService.requestSubjectSummary({
        subjectId: pageData.subjectId,
        sort: subjectSummarySorting,
      });
      const newSubjectSummaryData: SubjectSummaryTableColumns[] =
        subjectSummaryRes.data;

      setSubjectSummaryTableData(newSubjectSummaryData);

      // get the current daap information data
      const currentDaapRes =
        await cloudService.requestCompletedDAAPHistoryTable({
          subjectId: pageData.subjectId,
          planId: pageData.daapId,
          sort: completedDaapHistorySorting,
        });
      const newCurrentDaapData: CompletedDAAPColumns[] = currentDaapRes.data;

      setName(currentDaapRes.name);

      setCompletedDaapHistoryTableData(newCurrentDaapData);
    } catch (e) {
      logger.error(
        'Error fetching subject summary or daap information tables!',
        e,
      );
    } finally {
      setLoading(false);
    }
  }, [
    cloudService,
    completedDaapHistorySorting,
    logger,
    navigate,
    pageData.daapId,
    pageData.subjectId,
    profileService,
    setCompletedDaapHistoryTableData,
    setLoading,
    setSubjectSummaryTableData,
    subjectSummarySorting,
  ]);

  // fetch data when inputs change
  useEffect(() => {
    dataFetcher().catch(e => {
      logger.error(e);
    });
  }, [completedDaapHistorySorting, dataFetcher, logger]);

  return (
    <div style={styles.pageContainer}>
      <div style={styles.pageHeader}>
        <h1 style={styles.h1}>
          {i18n.t('globals.subject', { subjectId: pageData.subjectId })}
        </h1>
        <div style={styles.btnGroup}>
          {!deactivatedAt && (
            <button
              style={styles.primaryBtn}
              onClick={() => {
                navigate(
                  ROUTES.NEW_DAAP.buildPath({ subjectId: pageData.subjectId }),
                );
              }}>
              {i18n.t('subject.setGoal')}
            </button>
          )}
        </div>
      </div>
      <div style={styles.tableSectionContainer}>
        <div style={{ ...styles.rowContainer, ...styles.tableTopRow }}>
          <h2 style={styles.h2}>{i18n.t('tables.subjectSummary.title')}</h2>
        </div>
        <Table
          columnHelper={subjectSummarySolumnHelper}
          columns={SUBJECT_SUMMARY_COLUMNS}
          data={subjectSummaryTableData}
        />
      </div>
      <div style={{ ...styles.rowContainer, ...styles.tableSectionContainer }}>
        <div style={{ ...styles.rowContainer, ...styles.tableTopRow }}>
          <h2 style={styles.h2}>{name}</h2>
          <div style={styles.btnGroup}>
            <CompletedDAAPHistoryExportDropDown
              style={{ ...styles.rightButton, ...styles.exportButton }}
            />
          </div>
        </div>
        <Table
          sorting={completedDaapHistorySorting}
          setSorting={setCompletedDaapHistorySorting}
          columnHelper={completedDaapHistoryColumnHelper}
          columns={COMPLETED_DAAP_COLUMNS}
          data={completedDaapHistoryTableData}
        />
      </div>
    </div>
  );
}
