import Parse from 'parse';

const appId = process.env.REACT_APP_APP_ID!;
const serverURL = process.env.REACT_APP_PUBLIC_SERVER_URL!;

if (!appId || !serverURL) {
  throw new Error('Missing required environment variables');
}

// Before using the SDK...
Parse.initialize(appId);
Parse.serverURL = serverURL;
Parse.enableLocalDatastore();
export default Parse;
