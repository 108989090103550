import { transformForExportBase } from '../../../components/templates/Table/tableUtil';
import { Sensor } from '../../../schemas/Sensor';
import i18n from '../../../translations';
import {
  CustomColumnDefintion,
  CustomTableColumns,
  SensorColumns,
} from '../../../types/tables';

/**
 * The columns definition for the set user roles page.
 * This is the order of the columns in the table and any customizations
 */
export const SENSOR_COLUMNS: CustomColumnDefintion[] = [
  { column: 'mac', headerName: i18n.t('addSensor.macAddress') },
  { column: 'dateAdded', headerName: i18n.t('addSensor.dateAdded') },
  { column: 'batteryNumber', headerName: i18n.t('addSensor.batteryNumber') },
  { column: 'weldNumber', headerName: i18n.t('addSensor.weldNumber') },
];

export const formatSensors = (activeSensors: Sensor[]) => {
  if (activeSensors) {
    const sensorRows: SensorColumns[] = activeSensors.map(s => ({
      id: s.id,
      mac: s.attributes.mac,
      dateAdded: s.createdAt,
      batteryNumber: s.attributes.batteryNumber ?? '',
      weldNumber: s.attributes.weldNumber ?? '',
    }));
    return sensorRows;
  }
};

export const transformForExport = (data: CustomTableColumns[]) => {
  return transformForExportBase(data, SENSOR_COLUMNS);
};
