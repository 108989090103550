import {
  type ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

export default (theme: ThemeContent): StyleSheet => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%',
    gap: 10,
    marginTop: 15,
  },
  dialogContainer: {
    position: 'relative',
    zIndex: 10,
  },
  blurContainer: {
    position: 'fixed',
    inset: 0,
    backgroundColor: theme.colors.gray,
    opacity: '50%',
  },
  dialogOuterContainer: {
    position: 'fixed',
    inset: 0,
    overflowY: 'auto',
    zIndex: 10,
  },
  dialogInnerContainer: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'end',
  },
  panel: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: 'white',
    alignSelf: 'center',
    transition: 'all',
    boxShadow:
      '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
    borderRadius: '0.5rem',
    padding: 30,
  },
  panelDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btn: {
    display: 'flex',
    marginTop: 20,
    width: '100%',
    height: '10%',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  xmark: {
    position: 'absolute',
    right: 10,
    top: 10,
    color: '#808080',
    cursor: 'pointer',
  },
});
