import { useMemo } from 'react';
import useErrorHandling from '../hooks/useErrorHandling';
import Parse from '../parse';
import { SensorHistory, SensorHistoryAttributes } from './SensorHistory';

/**
 * Stores a sensor reference
 * Only 1 unique MAC address can be active in the table
 * All MAC addresses must be unique to a owner (_User)
 * A "_User" may only have 2 active sensors at a time
 */
export interface SensorAttributes {
  createdBy: Parse.User;
  active: boolean;
  guid?: string;
  mac: string;
  serialNumber: string;
  batteryNumber?: string;
  weldNumber?: string;
}

export const classname = 'Sensor';

export class Sensor extends Parse.Object<SensorAttributes> {
  constructor(attributes: SensorAttributes) {
    super(classname, attributes);
  }
}

Parse.Object.registerSubclass(classname, Sensor);

export enum SensorPosition {
  LEFT_WRIST = 'LEFT_WRIST',
  RIGHT_WRIST = 'RIGHT_WRIST',
}

export function useSensorService() {
  const getAllActiveSensors = useErrorHandling(async () => {
    const sensorQuery = new Parse.Query(Sensor);
    sensorQuery.equalTo('active', true);
    sensorQuery.descending('createdAt');

    return await sensorQuery.find();
  }, []);

  const getAvailableSensors = useErrorHandling(async () => {
    // get sensors that does not have paired or pending status in the sensor history table
    const unavailableSensorsQuery = new Parse.Query(SensorHistory);
    unavailableSensorsQuery.doesNotExist('unpaired');
    unavailableSensorsQuery.include('sensor');

    const sensorQuery = new Parse.Query(Sensor);
    sensorQuery.doesNotMatchKeyInQuery(
      'objectId',
      'sensor.objectId' as keyof SensorHistoryAttributes, // this is valid, just not explicitly typed
      unavailableSensorsQuery,
    );
    const sensors = await sensorQuery.find();
    return sensors;
  }, []);

  const getSensorById = useErrorHandling(async (id: string) => {
    const query = new Parse.Query(Sensor);
    query.equalTo('objectId', id);
    query.equalTo('active', true);
    return await query.first();
  }, []);

  return useMemo(
    () => ({
      getAllActiveSensors,
      getAvailableSensors,
      getSensorById,
    }),
    [getAllActiveSensors, getAvailableSensors, getSensorById],
  );
}
