import i18n from '../../../translations';
import {
  CustomColumnDefintion,
  CustomTableColumns,
  HistoricalSensorColumns,
} from '../../../types/tables';
import {
  CompletionStatus,
  DailyStatus,
  TileWeekGraph,
} from '../../../components/atoms/WeekTiles/WeekTiles';
import { cloneDate } from '../../../utils/dateTime';
import {
  CloudContent,
  KNOWN_CLOUD_FUNCTIONS,
} from '../../../providers/CloudProvider';
import { makeDateRangeOptions, rowToExportString } from '../../../utils/tables';
import { Sensor } from '../../../schemas/Sensor';
import { Profile } from '../../../schemas/Profile';
import { useCallback, useMemo } from 'react';
import { StrokeWearRoles } from '../../../types/cloud';
import Parse from '../../../parse';
import { DateTime } from 'luxon';
import { SubjectContent } from '../../../providers/SubjectContext';

interface SubjectHelperProps {
  cloudContext: CloudContent;
  subjectContext?: SubjectContent;
  subjectId: string;
}

type SensorStatusReponse = {
  batteryPercentage?: number;
  flashPercentage?: number;
  connectionStatus: boolean;
  lastSync?: Date;
  lastUsage?: Date;
};

export type SensorStatusesReponse = {
  left: SensorStatusReponse;
  right: SensorStatusReponse;
};

export type GDMTrendData = {
  previousGoalCount: number;
  newGoalCount: number;
};
export type MostRecentSyncData = {
  lastSync?: Date;
  lastUsage?: Date;
};

/**
 * The style for the centered cells
 */
const cellStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '0.5em',
  marginRight: '0.5em',
};

const subjectSummaryColumnsPrefix = 'tables.subjectSummary.columns';
const SUBJECT_SUMMARY_COLUMNS: CustomColumnDefintion[] = [
  {
    column: 'subjectID',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.subjectId`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'redcapID',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.redcapId`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'primaryTherapist',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.primaryTherapist`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'secondaryTherapist',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.secondaryTherapist`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'weekNumber',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.weekNumber`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'rightMAC',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.rightMAC`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'leftMAC',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.leftMAC`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'affectedSide',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.affectedSide`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
];

const historicalSensorDataColumnsPrefix = 'tables.historicalSensorData.columns';
const HISTORICAL_SENSOR_DATA_COLUMNS: CustomColumnDefintion[] = [
  {
    column: 'subjectID',
    headerName: i18n.t(`${historicalSensorDataColumnsPrefix}.subjectId`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'mac',
    headerName: i18n.t(`${historicalSensorDataColumnsPrefix}.mac`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'side',
    headerName: i18n.t(`${historicalSensorDataColumnsPrefix}.side`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'affectedSide',
    headerName: i18n.t(`${historicalSensorDataColumnsPrefix}.affectedSide`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'initialPairDate',
    headerName: i18n.t(`${historicalSensorDataColumnsPrefix}.initialPairDate`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'initialConnectionDate',
    headerName: i18n.t(
      `${historicalSensorDataColumnsPrefix}.initialConnectionDate`,
    ),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
];

// Get the first day of the week from the given date (eg. week starts from Sunday)
const getFirstDayOfWeekAsSunday = (d: Date) => {
  const day = d.getDay();
  const diff = d.getDate() - day;
  const sunday = new Date(cloneDate(d).setDate(diff));
  sunday.setHours(0, 0);
  return sunday;
};

// Get the list of 7 days from the given date
const displayingWeek = (fstDay: Date): Date[] => {
  const week: Date[] = [];
  let currentDay = fstDay;
  for (let i = 0; i < 7; i++) {
    week.push(currentDay);
    currentDay = new Date(
      cloneDate(currentDay).setDate(currentDay.getDate() + 1),
    );
  }
  return week;
};

const transformSensorSummaryForExport = (data: CustomTableColumns[]) => {
  const exportData = data as HistoricalSensorColumns[];
  return exportData.map(row => {
    const newRowValues = rowToExportString(row);
    // get the keys we want to see
    const exportHeaders = HISTORICAL_SENSOR_DATA_COLUMNS.map(
      column => column.headerName,
    );

    // create the new object
    const finalExport: Record<string, string> = {};
    for (let i = 0; i < exportHeaders.length; i++) {
      finalExport[exportHeaders[i]] = newRowValues[i].toString();
    }

    return finalExport;
  });
};

export const useSubjectHelpers = ({
  subjectId,
  cloudContext,
  subjectContext,
}: SubjectHelperProps) => {
  const {
    profileService,
    exerciseService,
    movementService,
    actionPlanService,
    sensorHistoryService,
    sensorEventService,
    goalService,
    cloudService,
    gdmInfoService,
  } = cloudContext;

  /**
   * Return all gdm info belongs to the subjectID in the given period of time.
   * Also support rezoning time to subject's timezone (keeping local time)
   * if the given start and end dates are not in subject's timezone.
   * @constrain Start date should be chronologically earlier than end date
   */
  const gdmInfoBasedGraphQuery = useCallback(
    async (startDate: DateTime, endDate: DateTime) => {
      const profile = await profileService.getProfileBySubjectId(subjectId);
      if (!profile) {
        throw new Error('Profile not found');
      }
      const gdmInfos = await gdmInfoService.getGDMInfosBetween(
        profile,
        startDate,
        endDate,
      );
      return gdmInfos;
    },
    [gdmInfoService, profileService, subjectId],
  );

  /**
   * Return the data to populate exercise weekly graph
   */
  const getExerciseWeeklyData = useCallback(
    async (startDate: Date): Promise<DailyStatus[]> => {
      const profile = await profileService.getProfileBySubjectId(subjectId);
      if (!profile) {
        return [];
      }

      const weeklyData: DailyStatus[] = [];

      const fstDay = getFirstDayOfWeekAsSunday(cloneDate(startDate));
      const fullWeek: Date[] = displayingWeek(cloneDate(fstDay));

      const startOfFirstDay = new Date(cloneDate(fullWeek[0]).setHours(0, 0));
      const endOfLastDay = new Date(
        cloneDate(fullWeek[6]).setHours(23, 59, 59),
      );

      const exerciseData = await exerciseService.getExercisesBetween(
        profile,
        startOfFirstDay,
        endOfLastDay,
      );

      for (let i = 0; i < 7; i++) {
        const date = new Date(cloneDate(fullWeek[i]).setHours(0, 0));
        const exerciseOfTheDay = exerciseData.find(exercise => {
          const exerciseTime = new Date(exercise.get('startTime'));
          return exerciseTime.toDateString() === date.toDateString();
        });

        const exerciseDay = {
          status: exerciseOfTheDay
            ? CompletionStatus.COMPLETE
            : date.toDateString() === new Date().toDateString()
              ? CompletionStatus.TODAY
              : CompletionStatus.INCOMPLETE,
          date,
        };

        weeklyData.push(exerciseDay);
      }

      return weeklyData;
    },
    [exerciseService, profileService, subjectId],
  );

  /**
   * Return the data to populate the ongoing daaps graph
   * @param subjectId
   * @param startDate
   * @returns
   */
  const getDaapsData = useCallback(
    async (startDate: Date): Promise<TileWeekGraph[]> => {
      const profile = await profileService.getProfileBySubjectId(subjectId);
      if (!profile) {
        return [];
      }

      const graphs: TileWeekGraph[] = [];
      //planid, [name, DailyStatus[]]
      const graphMap = new Map<string, Map<string, DailyStatus[]>>();

      const now = DateTime.now().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const defaultWeek = (): DailyStatus[] =>
        Array.from({ length: 7 }, (_, i) => {
          const dayOfWeek = DateTime.fromJSDate(startDate)
            .plus({ days: i })
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
          return {
            date: dayOfWeek.toJSDate(),
            status: now.equals(dayOfWeek)
              ? CompletionStatus.TODAY
              : CompletionStatus.INCOMPLETE,
          };
        });

      const endDate = DateTime.fromJSDate(startDate)
        .plus({ days: 7 })
        .endOf('day')
        .toJSDate();

      // get every action plan that is ongoing before the end date
      // and were not closed before the start date
      const plans = await actionPlanService.getOngoingActionPlans(
        profile,
        startDate,
        endDate,
      );

      for (const plan of plans) {
        const id = plan.get('planID');
        const name = plan.get('name');
        const graphsForPlan =
          graphMap.get(id) ??
          new Map<string, DailyStatus[]>([[name, defaultWeek()]]);
        const weeklyPlanData: DailyStatus[] =
          graphsForPlan.get(name) ?? defaultWeek();

        // get the date to use for the plan
        const dateToUse =
          plan.attributes.completeToday ?? plan.attributes.closed;

        if (dateToUse) {
          // if the date is not in the week, skip we dont need to display it
          if (
            dateToUse.getTime() < startDate.getTime() ||
            dateToUse.getTime() > endDate.getTime()
          ) {
            continue;
          }

          const completedDate = DateTime.fromJSDate(dateToUse, {
            zone: plan.attributes.timezone,
          }).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

          const completeDay = {
            status: CompletionStatus.COMPLETE,
            date: completedDate.toJSDate(),
          };

          weeklyPlanData[completedDate.toJSDate().getDay()] = completeDay;
        }

        // even if nothing was completed this week, we still want to display the plan name
        graphsForPlan.set(name, weeklyPlanData);
        graphMap.set(id, graphsForPlan);
      }

      for (const plans of graphMap) {
        for (const [name, weeklyData] of plans[1]) {
          graphs.push({
            name,
            weeklyData,
          });
        }
      }
      return graphs;
    },
    [actionPlanService, profileService, subjectId],
  );

  const getSensorStatus = useCallback(
    async (
      profile: Profile,
      sensor: Sensor | undefined,
    ): Promise<SensorStatusReponse> => {
      if (!sensor) {
        if (!profile.get('deactivatedAt')) {
          throw new Error('No sensor found for the given profile');
        }
        return {
          batteryPercentage: undefined,
          flashPercentage: undefined,
          connectionStatus: false,
          lastSync: undefined,
          lastUsage: undefined,
        };
      }

      // get the last sensor events
      const lastSyncEvent = await sensorEventService.getMostRecentSync(
        profile,
        sensor,
      );

      const lastUsageEvent = await sensorEventService.getMostRecentUsage(
        profile,
        sensor,
      );

      if (!lastSyncEvent || !lastUsageEvent) {
        return {
          batteryPercentage: undefined,
          flashPercentage: undefined,
          connectionStatus: false,
          lastSync: undefined,
          lastUsage: undefined,
        };
      }

      // should match partially at least
      type GetUsageResponse = {
        batteryUsage: number;
        flashUsage: number;
        batteryCapacity: number;
        usedFlashBlocks: number;
        bootCount: number;
      };

      // extract the usage data from the last usage event
      const usageMetaData = JSON.parse(
        JSON.stringify(lastUsageEvent.get('meta')),
      ).getUsage as Partial<GetUsageResponse>;

      const batteryPercentage = usageMetaData.batteryUsage;
      const flashPercentage = usageMetaData.flashUsage;
      const FIFTEEN_MINUTES = 15 * 60 * 1000;
      // TODO: do we want to check this via a push or a livequery to make it more realtime?
      // connected if the most recent usage is withtin 15 minutes
      const connectionStatus =
        lastUsageEvent.get('time').getTime() >=
        new Date().getTime() - FIFTEEN_MINUTES;
      const lastSync = lastSyncEvent.get('time');
      const lastUsage = lastUsageEvent.get('time');

      return {
        batteryPercentage,
        flashPercentage,
        connectionStatus,
        lastSync,
        lastUsage,
      };
    },
    [sensorEventService],
  );

  /**
   *
   * @param subjectId Get the sensor statuses for the subject
   * @returns the sensor statuses for the subject
   */
  const getSensorStatuses =
    useCallback(async (): Promise<SensorStatusesReponse> => {
      // get profile
      const profile = await profileService.getProfileBySubjectId(subjectId);

      if (!profile) {
        throw new Error('Profile not found');
      }

      // get the sensor for the sensor type
      const sensors =
        await sensorHistoryService.getSubjectsCurrentSensors(profile);

      if (!sensors) {
        throw new Error('No sensors found');
      }

      return {
        left: await getSensorStatus(profile, sensors.left),
        right: await getSensorStatus(profile, sensors.right),
      };
    }, [profileService, sensorHistoryService, getSensorStatus, subjectId]);

  /**
   * Generates the GDM trend data for the subject by calculating the percentage change
   * between the subjects latest two goals.
   * @param subjectId The subject to get the GDM trend data for
   * @returns The GDM trend data for the subject
   */
  const getGDMTrendData = useCallback(async (): Promise<GDMTrendData> => {
    // get profile
    const profile = await profileService.getProfileBySubjectId(subjectId);

    if (!profile) {
      throw new Error('Profile not found');
    }

    // Returns the latest two goals for the subject, in descending order
    const [newGoal, previousGoal] =
      await goalService.getMostRecentNumberOfGoals(profile, 2, new Date());

    return {
      previousGoalCount: previousGoal?.attributes.count,
      newGoalCount: newGoal?.attributes.count,
    };
  }, [goalService, profileService, subjectId]);

  /**
   * Get the most recent sync dates among the sensors
   * @param sensorStatuses Data containing left and right sensor statuses
   * @returns The most recent sync dates among the sensors
   */
  const getMostRecentSync = useCallback(
    async (
      sensorStatuses: SensorStatusesReponse,
    ): Promise<MostRecentSyncData> => {
      if (
        sensorStatuses.left === undefined ||
        sensorStatuses.right === undefined
      ) {
        if (!subjectContext?.deactivatedAt) {
          throw new Error('No sensors found for the given profile');
        }
        return { lastSync: undefined, lastUsage: undefined };
      }

      const { lastSync: leftLastSync, lastUsage: leftLastUsage } =
        sensorStatuses.left;
      const { lastSync: rightLastSync, lastUsage: rightLastUsage } =
        sensorStatuses.right;

      const lastSync =
        leftLastSync && rightLastSync
          ? leftLastSync > rightLastSync
            ? leftLastSync
            : rightLastSync
          : leftLastSync ?? rightLastSync;

      const lastUsage =
        leftLastUsage && rightLastUsage
          ? leftLastUsage > rightLastUsage
            ? leftLastUsage
            : rightLastUsage
          : leftLastUsage ?? rightLastUsage;
      return { lastSync, lastUsage };
    },
    [subjectContext?.deactivatedAt],
  );

  const constructedDateRangeOptions = useMemo(async () => {
    const profile = await profileService.getProfileBySubjectId(subjectId);
    if (!profile) {
      throw new Error('Profile not found');
    }
    const movementStart = (
      await movementService.getFirstMovement(profile)
    )?.get('startTime');
    const exerciseStart = (
      await exerciseService.getFirstExercise(profile)
    )?.get('startTime');
    const daapStart = (await actionPlanService.getFirst(profile))?.get(
      'localCreatedAt',
    );
    // filter out undefines
    const startDates = [movementStart, exerciseStart, daapStart].filter(
      date => date !== undefined,
    ) as Date[];
    // if this is a new subject and no records found at all, return the current week
    if (startDates.length === 0) {
      const startWeek = getFirstDayOfWeekAsSunday(new Date());
      return makeDateRangeOptions(startWeek, new Date());
    }
    // else get the earliest start date
    const start = Math.min(...startDates.map(d => d.getTime()));
    // end date is either today or the date that study ends
    const end =
      subjectContext?.deactivatedAt?.getTime() ?? new Date().getTime();
    return makeDateRangeOptions(new Date(start), new Date(end));
  }, [
    subjectId,
    profileService,
    movementService,
    exerciseService,
    actionPlanService,
    subjectContext?.deactivatedAt,
  ]);

  /**
   * Populate dropdown data with available secondary therapists
   * @returns
   */
  const getAllTherapists = useCallback(async () => {
    const profile = await profileService.getProfileBySubjectId(subjectId);
    if (!profile) {
      throw new Error('Profile not found');
    }
    const excludingIds = [profile.get('therapist')].map(
      t => t?.id ?? undefined,
    );
    const therapist = (await cloudService.getUsersWithRole(
      StrokeWearRoles.Therapist,
    )) as Parse.User[];
    return therapist.filter(user => !excludingIds.includes(user.id));
  }, [cloudService, profileService, subjectId]);

  const setSecondaryTherapist = useCallback(
    async (therapist: Parse.User | undefined) => {
      const profile = await profileService.getProfileBySubjectId(subjectId);
      if (!profile) {
        throw new Error('Profile not found');
      }
      const params = JSON.parse(
        JSON.stringify({
          profileIds: [profile.id],
          secondaryTherapistId: therapist?.id,
        }),
      );
      return await cloudContext.cloudService.run(
        KNOWN_CLOUD_FUNCTIONS.SET_SECONDARY_THERAPIST,
        params,
      );
    },
    [cloudContext, profileService, subjectId],
  );

  return useMemo(
    () => ({
      cellStyle,
      SUBJECT_SUMMARY_COLUMNS,
      HISTORICAL_SENSOR_DATA_COLUMNS,
      getExerciseWeeklyData,
      getDaapsData,
      transformSensorSummaryForExport,
      getSensorStatuses,
      getGDMTrendData,
      getMostRecentSync,
      constructedDateRangeOptions,
      getAllTherapists,
      setSecondaryTherapist,
      gdmInfoBasedGraphQuery,
    }),
    [
      constructedDateRangeOptions,
      gdmInfoBasedGraphQuery,
      getAllTherapists,
      getDaapsData,
      getExerciseWeeklyData,
      getGDMTrendData,
      getMostRecentSync,
      getSensorStatuses,
      setSecondaryTherapist,
    ],
  );
};
