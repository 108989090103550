import { MutableRefObject, useEffect, useMemo, useState } from 'react';
import {
  useThemedComponent,
  ThemeContent,
  StyleSheet,
} from '../../../providers/ThemeProvider';
import i18n from '../../../translations';
import CandleBarGraph, {
  CandleBarGraphData,
  CandleBarGraphDataCol,
  CandleBarGraphCell,
} from '../CandleBarGraph/CandleBarGraph';
import { GraphLabels } from '../../atoms/GraphLabels/GraphLabels';
import { DateTime } from 'luxon';
import { useCloudContext } from '../../../providers/CloudProvider';
import { Side } from '../../../types/cloud';
import { GDMInfo } from '../../../schemas/GDMInfo';
import { DEFAULT_TIMEZONE } from '../../../utils/dateTime';
import { useLogging } from '../../../providers/LoggingProvider';

export const COMPLIANCE_THRESHOLD = 35;

interface ComplianceBarGraphProps {
  gdmInfoData: GDMInfo[];
  containerRef: MutableRefObject<HTMLDivElement | null>;
  // the first day of the week (ie. sunday when week is defined as sun-sat)
  // CONSTRAIN: must be in subject's timezone for accuracy
  startDate: DateTime;
  subjectId: string;
}

export default function ComplianceBarGraph({
  containerRef,
  startDate,
  subjectId,
  gdmInfoData,
}: ComplianceBarGraphProps) {
  const { styles, theme } = useThemedComponent([ComplianceBarGraphStyles]);
  const { sensorHistoryService, profileService } = useCloudContext();

  const [leftLabel, setLeftLabel] = useState<string>('');
  const [rightLabel, setRightLabel] = useState<string>('');
  const logger = useLogging(ComplianceBarGraph.name);

  const [timezone, setTimezone] = useState<string>(DEFAULT_TIMEZONE);

  useEffect(() => {
    profileService
      .getProfileBySubjectId(subjectId)
      .then(profile => {
        if (!profile) {
          logger.warn('No profile provided for compliance bar graph');
          return;
        }

        setTimezone(profile.attributes.timezone);
        sensorHistoryService.getProfileAffectedSide(profile).then(side => {
          if (side === Side.LEFT) {
            setLeftLabel(i18n.t('subject.affectedSide'));
            setRightLabel(i18n.t('subject.unaffectedSide'));
          } else {
            setLeftLabel(i18n.t('subject.unaffectedSide'));
            setRightLabel(i18n.t('subject.affectedSide'));
          }
        });
      })
      .catch(error => {
        logger.error('Error getting affected side', error);
      });
  }, [logger, profileService, sensorHistoryService, subjectId]);

  /**
   * Calculate the total filled cells in each row of a column
   * @param hours the column to calculate the total filled cells
   * @returns an array of the total filled cells in each row
   */
  const calculateTotalFilledInCol = (hours: number[][]): number[] => {
    const totals: number[] = [];
    // for each row
    for (let i = 0; i < hours.length; i++) {
      // for each cell in the row
      hours[i].map((cell, index) => {
        // if the cell is at least COMPLIANCE_THRESHOLD, add one to the total
        totals[index] = totals[index]
          ? totals[index] + (cell >= COMPLIANCE_THRESHOLD ? 1 : 0)
          : cell >= COMPLIANCE_THRESHOLD
            ? 1
            : 0;
      });
    }
    return totals;
  };

  const transposeComplianceData = useMemo(() => {
    // 7 days in a week, 24 hours in a day, 2 movement types
    const groupedMovements = [...Array(7).keys()].map(() =>
      [...Array(24).keys()].map(() => [0, 0]),
    );
    const xAxisDates = [...Array(7).keys()].map(idx =>
      startDate.plus({ days: idx }).startOf('day'),
    );
    gdmInfoData.forEach(gdmInfo => {
      const dayIdx = xAxisDates.findIndex(
        date =>
          date.toISODate() ===
          DateTime.fromJSDate(gdmInfo.attributes.date, {
            zone: timezone,
          }).toISODate(),
      );
      if (dayIdx === -1) {
        logger.warn(`GDM Info from compliance query does not appear in range 
        ${startDate} to ${startDate.plus({ days: 6 })}`);
      } else {
        const gdmHourlyData = gdmInfo.attributes.hourly;
        Object.values(gdmHourlyData).forEach(
          ({ hour, affectedSideTotal, unaffectedSideTotal }) => {
            groupedMovements[dayIdx][hour][0] = affectedSideTotal;
            groupedMovements[dayIdx][hour][1] = unaffectedSideTotal;
          },
        );
      }
    });

    const data: CandleBarGraphData = {
      // 24 hours in a day, sorted in descending order
      yAxisValues: [...Array(24).keys()]
        .map(i => {
          // make a date
          const date = new Date();
          // format into am/pm format
          date.setHours(i);
          date.setMinutes(0);
          return date.toLocaleString(i18n.locale, {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          });
        })
        .reverse(),
      cols: Object.entries(groupedMovements).map(
        ([day, hours]): CandleBarGraphDataCol => {
          const xAxisDate = xAxisDates[+day];
          const xAxisValue = (
            <div style={styles.complianceXAxis}>
              <div>{xAxisDate.toLocaleString({ weekday: 'short' })}</div>
              <div style={styles.complianceXAxisTextBottom}>
                {xAxisDate.toLocaleString({ month: 'short' })} {xAxisDate.day}
              </div>
            </div>
          );

          const toolTip = calculateTotalFilledInCol(hours).map(
            (total, index) => {
              return (
                <div key={index}>
                  <p style={styles.toolTipText}>
                    <span
                      style={{
                        color:
                          index === 0 ? theme.colors.blue : theme.colors.green,
                        fontWeight: 'bold',
                      }}>
                      {index === 0
                        ? i18n.t('subject.leftShortPrefix')
                        : i18n.t('subject.rightShortPrefix')}{' '}
                    </span>
                    {i18n.t('subject.complianceToolTipHours', { hours: total })}
                  </p>
                </div>
              );
            },
          );
          return {
            toolTipContent: toolTip,
            xAxisValue,
            // hours are in ascending order-- need to reverse to match with xAxis labels (desc)
            rows: hours
              .reverse()
              .map(([affectedCount, unaffectedCount]): CandleBarGraphCell[] => {
                const filled = (value: number) => value >= 1;
                return [
                  {
                    filled: filled,
                    color: theme.colors.blue,
                    value: affectedCount < COMPLIANCE_THRESHOLD ? 0 : 1,
                  },
                  {
                    filled: filled,
                    color: theme.colors.green,
                    value: unaffectedCount < COMPLIANCE_THRESHOLD ? 0 : 1,
                  },
                ];
              }),
          };
        },
      ),
    };
    return data;
  }, [
    gdmInfoData,
    startDate,
    timezone,
    logger,
    styles.complianceXAxis,
    styles.complianceXAxisTextBottom,
    styles.toolTipText,
    theme.colors.blue,
    theme.colors.green,
  ]);

  return (
    <>
      <GraphLabels
        labels={[
          { label: leftLabel, color: theme.colors.blue },
          { label: rightLabel, color: theme.colors.green },
        ]}
      />
      <CandleBarGraph
        containerRef={containerRef}
        yInterval={3}
        yIntervalOffset={1}
        forceFirstYAxis={true}
        forceLastYAxis={true}
        data={transposeComplianceData}
        style={styles.complianceGraph}
      />
    </>
  );
}

const ComplianceBarGraphStyles = (theme: ThemeContent): StyleSheet => ({
  complianceGraphTile: {
    width: '90%',
    padding: '2%',
    borderRadius: '1.5%',
  },
  complianceGraph: {
    justifyContent: 'flex-start',
    marginBottom: '5%',
  },
  graph: {
    boxShadow: '#00000026 2px 2px 2px 2px',
    justifyContent: 'space-evenly',
    padding: '2% 5% 5% 5%',
    alignItems: 'flex-start',
    backgroundColor: theme.colors.white,
    borderRadius: 10,
    marginTop: '3%',
  },
  graphTitle: {
    fontWeight: 'bold',
    fontSize: 20,
    marginBottom: '5vw',
  },
  complianceXAxis: {
    marginTop: '0.5rem',
  },
  complianceXAxisTextBottom: {
    color: theme.colors.transparentBlack,
    fontSize: '0.8rem',
    marginTop: '0.2rem',
  },
  toolTipText: {
    fontSize: '0.8rem',
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
  },
});
