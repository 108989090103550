import { MutableRefObject, useEffect, useMemo, useState } from 'react';
import i18n from '../../../translations';
import CandleBarGraph, {
  CandleBarGraphData,
  CandleBarGraphCell,
} from '../CandleBarGraph/CandleBarGraph';
import {
  ThemeContent,
  StyleSheet,
  useThemedComponent,
} from '../../../providers/ThemeProvider';
import { GraphLabels } from '../../atoms/GraphLabels/GraphLabels';
import { SensorPosition } from '../../../schemas/Sensor';
import { DateTime } from 'luxon';
import { useCloudContext } from '../../../providers/CloudProvider';
import { Side } from '../../../types/cloud';
import { GDMInfo } from '../../../schemas/GDMInfo';
import { useLogging } from '../../../providers/LoggingProvider';
interface MovementBarGraphProps {
  gdmGraphSelection: number;
  containerRef: MutableRefObject<HTMLDivElement | null>;
  // the first day of the graph data,
  // CONSTRAIN: must be in subject's timezone for accuracy
  startDate: DateTime;
  subjectId: string;
  // the gdm info for a week starting from the startDate
  gdmInfoData: GDMInfo[];
}

export default function MovementBarGraph({
  containerRef,
  gdmGraphSelection,
  startDate,
  subjectId,
  gdmInfoData,
}: MovementBarGraphProps) {
  const { styles, theme } = useThemedComponent([MovementBarGraphStyles]);
  const { sensorHistoryService, profileService } = useCloudContext();

  const [leftLabel, setLeftLabel] = useState<string>('');
  const [rightLabel, setRightLabel] = useState<string>('');
  const [affectedSide, setAffectedSide] = useState<SensorPosition>(
    SensorPosition.LEFT_WRIST,
  );
  const [timezone, setTimezone] = useState<string>();
  const logger = useLogging(MovementBarGraph.name);

  useEffect(() => {
    profileService
      .getProfileBySubjectId(subjectId)
      .then(profile => {
        if (!profile) {
          logger.warn('No profile provided for compliance bar graph');
          return;
        }
        setTimezone(profile.attributes.timezone);
        sensorHistoryService.getProfileAffectedSide(profile).then(side => {
          if (side === Side.LEFT) {
            setLeftLabel(i18n.t('subject.affectedSide'));
            setRightLabel(i18n.t('subject.unaffectedSide'));
          } else {
            setLeftLabel(i18n.t('subject.unaffectedSide'));
            setRightLabel(i18n.t('subject.affectedSide'));
          }
          setAffectedSide(
            side === Side.LEFT
              ? SensorPosition.LEFT_WRIST
              : SensorPosition.RIGHT_WRIST,
          );
        });
      })
      .catch(error => {
        logger.error('Error getting affected side', error);
      });
  }, [logger, profileService, sensorHistoryService, subjectId]);

  const transposeGDMData = useMemo(() => {
    // USE TO SEE FAKE DATA
    // const ranKey = 50
    // const movementSums = [...Array(7).keys()]
    //   .map(() => [Math.ceil(Math.random() * ranKey), Math.ceil(Math.random() * ranKey)])

    const movementSums = [...Array(7).keys()].map(() => [0, 0]);
    const xAxisDates = [...Array(7).keys()].map(idx =>
      startDate.plus({ days: idx }).startOf('day'),
    );
    const affectedSideIdx = affectedSide === SensorPosition.LEFT_WRIST ? 0 : 1;
    const unaffectedSideIdx =
      affectedSide === SensorPosition.LEFT_WRIST ? 1 : 0;
    gdmInfoData.forEach(gdmInfo => {
      const dayIdx = xAxisDates.findIndex(
        date =>
          date.toISODate() ==
          DateTime.fromJSDate(gdmInfo.attributes.date, {
            zone: timezone,
          }).toISODate(),
      );
      if (dayIdx === -1) {
        logger.warn(`GDM Info from graph query has a date that is not in range 
        ${startDate.toISO()} - ${startDate.plus({ days: 6 }).toISO()}`);
      } else {
        (movementSums[dayIdx][affectedSideIdx] =
          gdmInfo.attributes.affectedSideTotal),
          (movementSums[dayIdx][unaffectedSideIdx] =
            gdmInfo.attributes.unaffectedSideTotal);
      }
    });

    // find the max value for each of the sums
    const maxLeft = Math.max(...movementSums.map(sum => sum[0]));
    const maxRight = Math.max(...movementSums.map(sum => sum[1]));

    // chose the max value to be the max value for the graph
    const maxValue = Math.max(maxLeft, maxRight);

    // find the number of y axis values to display, if the max value is greater than 16, then only show 16 values
    const numberOfYAxisValues = maxRight > 100 || maxLeft > 100 ? 16 : 8;

    // find the scale to round the max value to, supports up to 10,000's scaling
    const roundScale =
      maxRight > 10000 || maxLeft > 10000
        ? 1000
        : maxRight > 100 || maxLeft > 100
          ? 100
          : maxRight > 10 || maxLeft > 10
            ? 10
            : 1;

    // find the scale need to have numberOfYAxisValues, round up to the nearest roundScale
    // note the interval visual will skip every other value, so we will only see half the numberOfYAxisValues
    const scale =
      Math.ceil(maxValue / numberOfYAxisValues / roundScale) * roundScale;

    // add one more scale to the max value, but ensure it is divisable by the numberOfYAxisValues
    let maxGraphValue = 0;
    for (let scaleMultiplier = 0; scaleMultiplier < 10; scaleMultiplier++) {
      const scaleToTest = scale * scaleMultiplier;
      const potentialMax = Math.ceil(maxValue / scaleToTest) * scaleToTest;
      if (potentialMax % numberOfYAxisValues === 0) {
        maxGraphValue = potentialMax;
        break;
      }
    }

    // create the y axis points, must include 0 and the max value
    const nummberOfPoints =
      (isNaN(maxGraphValue / scale) ? 0 : maxGraphValue / scale) + 1;
    const yAxisPoints = [...Array(nummberOfPoints).keys()].map(
      i => i * scale ?? 1,
    );

    const data: CandleBarGraphData = {
      yAxisValues: yAxisPoints.reverse().map(i => {
        return `${i}`;
      }),
      cols: movementSums.map(([left, right], index) => {
        const xAxisDate = xAxisDates[index];
        const xAxisValue = (
          <div style={styles.complianceXAxis}>
            <div>{xAxisDate.toLocaleString({ weekday: 'short' })}</div>
            <div style={styles.complianceXAxisTextBottom}>
              {xAxisDate.toLocaleString({ month: 'short' })} {xAxisDate.day}
            </div>
          </div>
        );

        // only show the tooltip for the affected count if affected is selected,
        // unaffected tooltip value will be undefine in that case
        const toolTipArray = [
          gdmGraphSelection === 0 || affectedSide === SensorPosition.LEFT_WRIST
            ? left
            : undefined,
          gdmGraphSelection === 0 || affectedSide === SensorPosition.RIGHT_WRIST
            ? right
            : undefined,
        ];
        // generate the tooltip content
        const toolTip = toolTipArray.map((total, index) => {
          return total === undefined ? (
            <></>
          ) : (
            <div key={index}>
              <p style={styles.toolTipText}>
                <span
                  style={{
                    color: index === 0 ? theme.colors.blue : theme.colors.green,
                    fontWeight: 'bold',
                  }}>
                  {index === 0
                    ? i18n.t('subject.leftShortPrefix')
                    : i18n.t('subject.rightShortPrefix')}{' '}
                </span>
                {total}{' '}
              </p>
            </div>
          );
        });
        return {
          xAxisValue: xAxisValue,
          toolTipContent: toolTip,
          // for each day, each sum need to all all cells to the max value
          rows: yAxisPoints.map((i): CandleBarGraphCell[] => {
            const cell0 = {
              filled: (value: number) => value <= left,
              color: theme.colors.blue,
              value: i + 1, // add one to the value to make it 1 indexed
            };
            const cell1 = {
              filled: (value: number) => value <= right,
              color: theme.colors.green,
              value: i + 1, // add one to the value to make it 1 indexed
            };
            const cells =
              gdmGraphSelection === 0
                ? [cell0, cell1]
                : affectedSide === SensorPosition.LEFT_WRIST
                  ? [cell0]
                  : [cell1];
            return cells;
          }),
        };
      }),
    };
    return data;
  }, [
    affectedSide,
    gdmInfoData,
    startDate,
    timezone,
    logger,
    styles.complianceXAxis,
    styles.complianceXAxisTextBottom,
    styles.toolTipText,
    gdmGraphSelection,
    theme.colors.blue,
    theme.colors.green,
  ]);

  return (
    <>
      <GraphLabels
        labels={[
          { label: leftLabel, color: theme.colors.blue },
          { label: rightLabel, color: theme.colors.green },
        ]}
      />
      <CandleBarGraph
        containerRef={containerRef}
        yInterval={2}
        forceFirstYAxis={true}
        forceLastYAxis={true}
        data={transposeGDMData}
        roundBottoms={false}
        cellScaler={gdmGraphSelection === 0 ? 1 : 0.5}
        style={styles.complianceGraph}
      />
    </>
  );
}

const MovementBarGraphStyles = (theme: ThemeContent): StyleSheet => ({
  complianceGraphTile: {
    width: '90%',
    padding: '2%',
    borderRadius: '1.5%',
  },
  complianceGraph: {
    justifyContent: 'flex-start',
  },
  graph: {
    boxShadow: '#00000026 2px 2px 2px 2px',
    justifyContent: 'space-evenly',
    padding: '2% 5% 5% 5%',
    alignItems: 'flex-start',
    backgroundColor: theme.colors.white,
    borderRadius: 10,
    marginTop: '3%',
  },
  graphTitle: {
    fontWeight: 'bold',
    fontSize: 20,
    marginBottom: '5vw',
  },
  complianceXAxis: {
    marginTop: '0.5rem',
  },
  complianceXAxisTextBottom: {
    color: theme.colors.transparentBlack,
    fontSize: '0.8rem',
    marginTop: '0.2rem',
  },
  toolTipText: {
    fontSize: '0.8rem',
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
  },
});
