import { useMemo } from 'react';
import useErrorHandling from '../hooks/useErrorHandling';
import Parse from '../parse';
import { ClientRecord } from './interfaces/ClientRecord';
import { useSensorHistoryService } from './SensorHistory';

export enum Group {
  CONTROL,
  INTERVENTION,
}

export enum AvatarType {
  PENGUIN = 'PENGUIN',
  CHICK = 'CHICK',
  ELEPHANT = 'ELEPHANT',
  WHALE = 'WHALE',
  RABBIT = 'RABBIT',
  MOUSE = 'MOUSE',
  SHEEP = 'SHEEP',
  SNAIL = 'SNAIL',
  BIRD = 'BIRD',
  MONKEY = 'MONKEY',
  FROG = 'FROG',
  KOALA = 'KOALA',
  BEE = 'BEE',
  CAT = 'CAT',
  DOG = 'DOG',
}

/**
 * Stores general user information to avoid updating "_User".
 */
export interface ProfileAttributes extends ClientRecord {
  createdFor: Parse.User;
  subjectId: string;
  redcapId: string;
  group: Group;
  therapist: Parse.User;
  secondaryTherapist: Parse.User | null;
  studyCoordinator: Parse.User;
  avatar: AvatarType;
  activatedAt?: Date;
  accessCode?: string;
  timezone: string;
  deactivatedAt?: Date;
}

const classname = 'Profile';

export class Profile extends Parse.Object<ProfileAttributes> {
  constructor(attributes: ProfileAttributes) {
    super(classname, attributes);
  }
}

Parse.Object.registerSubclass(classname, Profile);

export function useProfileService() {
  const sensorHistoryService = useSensorHistoryService();

  const getProfileBySubjectId = useErrorHandling(async (subjectId: string) => {
    const profileQuery = new Parse.Query(Profile);
    profileQuery.equalTo('subjectId', subjectId);
    profileQuery.fromPin();
    let profile = await profileQuery.first();
    // try LDS first, if not then fresh data
    if (!profile) {
      const freshQuery = new Parse.Query(Profile);
      freshQuery.equalTo('subjectId', subjectId);
      profile = await freshQuery.first();
      await profile?.pin();
    }

    return profile;
  }, []);

  const isActivated = useErrorHandling(
    async (subjectId: string): Promise<Date | undefined> => {
      const profile = await getProfileBySubjectId(subjectId);
      if (!profile) {
        throw new Error('Profile not found');
      }
      const activatedAt = profile.get('activatedAt');
      return activatedAt;
    },
    [],
  );

  const activate = useErrorHandling(async (subjectId: string, date: Date) => {
    const profile = await getProfileBySubjectId(subjectId);
    if (!profile) {
      throw new Error('Profile not found');
    }
    profile.set('activatedAt', date);
    return await profile.save();
  }, []);

  const deactivate = useErrorHandling(async (subjectId: string, date: Date) => {
    const profile = await getProfileBySubjectId(subjectId);
    if (!profile) {
      throw new Error('Profile not found');
    }
    profile.set('deactivatedAt', date);

    const { leftHistory, rightHistory } =
      await sensorHistoryService.getSubjectsCurrentSensorHistory(profile);

    if (leftHistory) {
      leftHistory.set('unpaired', date);
      await leftHistory.save();
    }

    if (rightHistory) {
      rightHistory.set('unpaired', date);
      await rightHistory.save();
    }

    return await profile.save();
  }, []);

  return useMemo(
    () => ({
      getProfileBySubjectId,
      isActivated,
      activate,
      deactivate,
    }),
    [getProfileBySubjectId, isActivated, activate, deactivate],
  );
}
