import i18n from '../../../translations';
import {
  CompletedDAAPColumns,
  CurrentDAAPGoalColumns,
  CustomColumnDefintion,
  CustomTableColumns,
} from '../../../types/tables';
import { rowToExportString } from '../../../utils/tables';

export const transformCurrentDaapForExport = (data: CustomTableColumns[]) => {
  const exportData = data as CurrentDAAPGoalColumns[];
  return exportData.map(row => {
    const newRowValues = rowToExportString(row);
    // get the keys we want to see
    const exportHeaders = DAAP_INFORMATION_COLUMNS.map(
      column => column.headerName,
    );

    // create the new object
    const finalExport: Record<string, string> = {};
    for (let i = 0; i < exportHeaders.length; i++) {
      finalExport[exportHeaders[i]] = newRowValues[i].toString();
    }

    return finalExport;
  });
};

export const transformCompletedDaapForExport = (data: CustomTableColumns[]) => {
  const exportData = data as CompletedDAAPColumns[];
  return exportData.map(row => {
    const newRowValues = rowToExportString(row);
    // get the keys we want to see
    const exportHeaders = COMPLETED_DAAP_COLUMNS.map(
      column => column.headerName,
    );

    // create the new object
    const finalExport: Record<string, string> = {};
    for (let i = 0; i < exportHeaders.length; i++) {
      finalExport[exportHeaders[i]] = newRowValues[i].toString();
    }

    return finalExport;
  });
};

/**
 * The style for the cells
 */
export const cellStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '0.5em',
  marginRight: '0.5em',
  textAlign: 'center',
};

export const subjectSummaryColumnsPrefix = 'tables.subjectSummary.columns';
export const SUBJECT_SUMMARY_COLUMNS: CustomColumnDefintion[] = [
  {
    column: 'subjectID',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.subjectId`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'redcapID',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.redcapId`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'primaryTherapist',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.primaryTherapist`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'secondaryTherapist',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.secondaryTherapist`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'weekNumber',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.weekNumber`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'rightMAC',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.rightMAC`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'leftMAC',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.leftMAC`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'affectedSide',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.affectedSide`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
];

export const currentDaapColumnsPrefix = 'tables.currentDAAPGoals.columns';
export const DAAP_INFORMATION_COLUMNS: CustomColumnDefintion[] = [
  {
    column: 'subjectID',
    sortable: false,
    headerName: i18n.t(`${currentDaapColumnsPrefix}.subjectId`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'name',
    sortable: false,
    headerName: i18n.t(`${currentDaapColumnsPrefix}.name`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'actionPlan',
    sortable: false,
    headerName: i18n.t(`${currentDaapColumnsPrefix}.actionPlan`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'confidence',
    sortable: false,
    headerName: i18n.t(`${currentDaapColumnsPrefix}.confidence`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'difficulty',
    sortable: false,
    headerName: i18n.t(`${currentDaapColumnsPrefix}.difficulty`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'when',
    sortable: false,
    headerName: i18n.t(`${currentDaapColumnsPrefix}.when`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'where',
    sortable: false,
    headerName: i18n.t(`${currentDaapColumnsPrefix}.where`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'how',
    sortable: false,
    headerName: i18n.t(`${currentDaapColumnsPrefix}.how`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'who',
    sortable: false,
    headerName: i18n.t(`${currentDaapColumnsPrefix}.who`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'startDate',
    sortable: false,
    headerName: i18n.t(`${currentDaapColumnsPrefix}.startDate`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
];

export const completedDaapColumnsPrefix = 'tables.completedDAAP.columns';
export const COMPLETED_DAAP_COLUMNS: CustomColumnDefintion[] = [
  {
    column: 'subjectID',
    sortable: false,
    headerName: i18n.t(`${completedDaapColumnsPrefix}.subjectId`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'date',
    sortable: false,
    headerName: i18n.t(`${completedDaapColumnsPrefix}.date`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'timeCompleted',
    sortable: false,
    headerName: i18n.t(`${completedDaapColumnsPrefix}.timeCompleted`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'note',
    sortable: false,
    headerName: i18n.t(`${completedDaapColumnsPrefix}.note`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
];
