import React, { createContext, useContext, useMemo, useState } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { colorizeLottie } from '../utils/lottie';
import LoaderJSON from '../lottie/Loader.json';
import { useTheme } from './ThemeProvider';

export interface LoaderDemensions {
  width: string;
  height: string;
}

/**
 * Wether or not the app is loading
 */
interface LoadingContextContent {
  /**
   * Wether or not the app is loading
   */
  loading: boolean;
  /**
   * Set the loading state
   */
  setLoading: (loading: boolean) => void;
  /**
   * Set the demensions for the loader box. The loader will be centered in the box
   */
  setLoaderDemensions: (loaderDemensions: LoaderDemensions) => void;
}

const LoadingContext = createContext<LoadingContextContent>(undefined!);

/**
 * Provider for the LoadingContext. Displays a loading animation when loading is true
 * @param param0 children to wrap
 * @returns children wrapped with LoadingContext
 */
const LoadingProvider = ({
  children,
  initialLoadingState,
}: {
  children: React.ReactNode;
  initialLoadingState: boolean;
}) => {
  const [loading, setLoading] = useState(initialLoadingState);
  const [loaderDemensions, setLoaderDemensions] = useState<LoaderDemensions>({
    width: '100%',
    height: '100%',
  });
  const { theme } = useTheme();

  const Loader = useMemo(() => {
    const colorizedSource = colorizeLottie(LoaderJSON, {
      // green ring 1.Ellipse 1.Stroke 1
      'layers.0.shapes.0.it.1.c.k': theme.colors.primary,
      // flamingo ring 3.Ellipse 1.Stroke 1
      'layers.1.shapes.0.it.1.c.k': theme.colors.coolGrey,
      // flamingo ring 2.Ellipse 1.Stroke 1
      'layers.2.shapes.0.it.1.c.k': theme.colors.coolGrey,
      // flaming ring 1.Ellipse 1.Stroke 1
      'layers.3.shapes.0.it.1.c.k': theme.colors.coolGrey,
    });
    return (
      <div
        style={{
          zIndex: 99999,
          position: 'absolute',
          bottom: 0,
          right: 0,
          width: loaderDemensions.width,
          height: loaderDemensions.height,
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Player
          loop={true}
          autoplay={true}
          src={colorizedSource}
          renderer="svg"
          style={{
            height: '100px',
            width: '100px',
          }}
        />
      </div>
    );
  }, [
    theme.colors.primary,
    theme.colors.coolGrey,
    loaderDemensions.width,
    loaderDemensions.height,
  ]);

  return (
    <LoadingContext.Provider
      value={{ loading, setLoading, setLoaderDemensions }}>
      {children}
      {loading && Loader}
    </LoadingContext.Provider>
  );
};
const useLoading = () => {
  return useContext(LoadingContext);
};

export { useLoading, LoadingProvider };
