import { useMemo } from 'react';
import useErrorHandling from '../hooks/useErrorHandling';
import { Goal } from './Goal';
import { Movement } from './Movement';
import { OwnableObject } from './interfaces/OwnableObject';
import { DateTime } from 'luxon';
import { largeParseQuery } from '../utils/network';
import { Profile } from './Profile';
import Parse from '../parse';

export type GDMInfoHourly = {
  hour: number;
  affectedSideTotal: number;
  unaffectedSideTotal: number;
}[];

export interface GDMInfoAttributes extends OwnableObject {
  date: Date;
  affectedSideTotal: number;
  unaffectedSideTotal: number;
  hourly: GDMInfoHourly;
  streak: number;
  goal: Goal | null;
  goalMet: boolean;
  latestAffectedMovement: Movement | null;
  latestUnaffectedMovement: Movement | null;
}

export const classname = 'GDMInfo';

export class GDMInfo extends Parse.Object<GDMInfoAttributes> {
  constructor(attributes: GDMInfoAttributes) {
    super(classname, attributes);
  }
}

export function useGDMInfoService() {
  const getGDMInfosBetween = useErrorHandling(
    async (profile: Profile, start: DateTime, end: DateTime) => {
      let startInSubjectTimezone: Date;
      let endInSubjectTimezone: Date;
      const timezone = profile.attributes.timezone;
      if (start.zoneName === timezone) {
        startInSubjectTimezone = start.toJSDate();
        endInSubjectTimezone = end.toJSDate();
      } else {
        startInSubjectTimezone = start
          .setZone(timezone, { keepLocalTime: true })
          .toJSDate();
        endInSubjectTimezone = end
          .setZone(timezone, { keepLocalTime: true })
          .toJSDate();
      }
      const gdmInfoQuery = new Parse.Query(GDMInfo);
      gdmInfoQuery.equalTo('owner', profile);
      gdmInfoQuery.greaterThanOrEqualTo('date', startInSubjectTimezone);
      gdmInfoQuery.lessThanOrEqualTo('date', endInSubjectTimezone);
      gdmInfoQuery.ascending('date');
      const count = await gdmInfoQuery.count();

      return largeParseQuery(gdmInfoQuery, count);
    },
    [],
  );

  const getFirstGDMInfo = useErrorHandling(async (profile: Profile) => {
    const gdmInfoQuery = new Parse.Query(GDMInfo);
    gdmInfoQuery.equalTo('owner', profile);
    gdmInfoQuery.ascending('date');
    gdmInfoQuery.limit(1);

    return await gdmInfoQuery.first();
  }, []);

  const getLastGDMInfo = useErrorHandling(async (profile: Profile) => {
    const gdmInfoQuery = new Parse.Query(GDMInfo);
    gdmInfoQuery.equalTo('owner', profile);
    gdmInfoQuery.descending('date');
    gdmInfoQuery.limit(1);

    return await gdmInfoQuery.first();
  }, []);

  return useMemo(
    () => ({
      getGDMInfosBetween,
      getFirstGDMInfo,
      getLastGDMInfo,
    }),
    [getFirstGDMInfo, getGDMInfosBetween, getLastGDMInfo],
  );
}
