import {
  type ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

export default (theme: ThemeContent): StyleSheet => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  tableContainer: {
    backgroundColor: theme.colors.white,
    borderRadius: '0.5em',
    // account for padding, (1em * 2)
    width: 'calc(100% - 2em)',
    boxShadow: `0 0 0.6250em 0 ${theme.colors.transparentBlackShadow}`,
    padding: '1em',
    overflowX: 'auto',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    height: '100%',
  },
  headerCellContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0.5em 0',
  },
  headerText: {
    color: theme.colors.primary,
    fontSize: '1rem',
  },
  headerButton: {
    backgroundColor: 'inherit',
    border: 'none',
    padding: '0',
  },
  th: {
    textAlign: 'left',
    verticalAlign: 'middle',
  },
  notSortableHeader: {
    cursor: 'text',
  },
  inSortHeader: {
    marginRight: '-0.9rem', // size of the arrow
  },
  cellContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0.5em 0 ',
    color: theme.colors.primary,
  },
  cellText: {
    fontSize: '0.9rem',
  },
  helperContainer: {
    marginLeft: '0.2em',
  },
  link: {
    color: theme.colors.blue,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  rowIdCell: {
    color: theme.colors.greySecondary,
  },
  divider: {
    height: '0.5em',
    color: theme.colors.white,
    borderBottom: `1px solid ${theme.colors.gray}`,
    margin: '0',
  },
  paginationContainer: {
    width: 'calc(90% + 2em)',
    marginTop: '1em',
  },
  pageSizeContainer: {
    marginLeft: 'auto',
  },
});
