import React from 'react';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
}

interface ButtonRowProps {
  buttons: ButtonProps[];
  style: React.CSSProperties;
}

export const ButtonRow = ({ buttons, style }: ButtonRowProps) => (
  <div style={style}>
    {buttons.map((b, i) => (
      <button style={b.style} key={i} onClick={b.onClick} type={b.type}>
        {b.title}
      </button>
    ))}
  </div>
);
