import React, { MutableRefObject, useEffect, useState } from 'react';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import { styled } from 'styled-components';
import CandleBarGraphStyles from './CandleBarGraphStyles';

interface CandleBarGraphProps {
  data: CandleBarGraphData;
  yInterval?: number;
  yIntervalOffset?: number;
  forceFirstYAxis?: boolean;
  forceLastYAxis?: boolean;
  enableDataScaleToMatchInterval?: boolean;
  style?: React.CSSProperties;
  containerRef?: MutableRefObject<HTMLDivElement | null>;
  yAxisStyle?: React.CSSProperties;
  roundTops?: boolean;
  roundBottoms?: boolean;
  cellScaler?: number;
}

export type CandleBarGraphCell = {
  filled: (value: number) => boolean;
  value: number;
  color?: string;
};

export type CandleBarGraphDataCol = {
  xAxisValue: string | number | React.ReactNode | React.ReactNode[];
  xAxisStyle?: React.CSSProperties;
  rows: CandleBarGraphCell[][];
  rowHeight?: number;
  toolTipContent?: React.ReactNode;
};

export type CandleBarGraphData = {
  cols: CandleBarGraphDataCol[];
  yAxisValues: string[] | number[] | React.ReactNode[][];
};

interface ToolTipProps {
  $bgColor: string;
  $cellSizeRaw: number;
}

// need to use styled-components for the tooltip after pseudo element
// see https://styled-components.com/docs/api#transient-props for '$' prefix on passed props
const ToolTip = styled.div<ToolTipProps>`
  & {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.2em;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
    background-color: ${props => props.$bgColor};
    padding: 0.5em;
    gap: 0.5em;
    width: 50%;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -${props => props.$cellSizeRaw / 4}em;
    border-width: ${props => props.$cellSizeRaw / 4}em;
    border-style: solid;
    border-color: transparent transparent ${props => props.$bgColor} transparent;
  }
`;

const CandleBarGraph = (props: CandleBarGraphProps) => {
  const [colHovered, setColHovered] = React.useState<number | undefined>();

  const [cellSize, setCellSize] = useState<string>();
  const yInterval = props.yInterval ?? 1;
  const yIntervalOffset = props.yIntervalOffset ?? 0;
  const forceFirstYAxis = props.forceFirstYAxis ?? false;
  const forceLastYAxis = props.forceLastYAxis ?? false;
  const enableDataScaleToMatchInterval =
    props.enableDataScaleToMatchInterval ?? false;
  const roundTops = props.roundTops ?? true;
  const roundBottoms = props.roundBottoms ?? true;

  const { styles, theme } = useThemedComponent([CandleBarGraphStyles]);

  const [windowWidth, setWindowWidth] = useState<number>();

  // keep track of the window width, so we can scale the ems accordingly
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // scale the ems
  useEffect(() => {
    // get a percentage of the container width
    const containerWidth = props.containerRef?.current?.offsetWidth ?? 0;
    const allotedWidth = containerWidth * 0.35;
    // get the number of columns
    const numCols = props.data.cols.length;
    // get the number of cells in each column row
    const numCells = props.data.cols[0].rows[0].length;
    // divide the container width by the number of columns
    const colWidth = allotedWidth / numCols;
    // divide the result by the number of cells in each column row
    const cellWidth = colWidth / numCells;
    // convert the result to ems
    const cellSize = (cellWidth * (props?.cellScaler ?? 1)) / 16;
    setCellSize(`${cellSize}em`);
  }, [props.data, props.containerRef, windowWidth, props?.cellScaler]);

  /**
   * Derive the border radius for each cell in the graph
   * @param col the column to calculate the border radius
   * @param rowIndex the index of the row being evaluated
   * @param cellIndex the index of the cell being evaluated
   * @returns a string representing the border radius for the cell
   */
  const calculateBorderRadius = (
    col: CandleBarGraphDataCol,
    rowIndex: number,
    cellIndex: number,
  ) => {
    // if the cell should be filled, the data before the current data shuld not be filled or its the first cell,
    // then add a border on top
    const addTopBorderRadius = roundTops
      ? rowIndex == 0
        ? true
        : col.rows[rowIndex][cellIndex].filled(
              col.rows[rowIndex][cellIndex].value,
            ) && rowIndex > 0
          ? !col.rows[rowIndex - 1][cellIndex].filled(
              col.rows[rowIndex - 1][cellIndex].value,
            ) ?? false
          : false
      : false;

    // if the cell should be filled, the data after the current data shuld not be filled or its the last cell,
    // then add a border on the bottom
    const addBottomBorderRadius = roundBottoms
      ? rowIndex == col.rows.length - 1
        ? true
        : col.rows[rowIndex][cellIndex].filled(
              col.rows[rowIndex][cellIndex].value,
            ) && rowIndex < col.rows.length - 1
          ? !col.rows[rowIndex + 1][cellIndex].filled(
              col.rows[rowIndex + 1][cellIndex].value,
            ) ?? false
          : false
      : false;

    if (!cellSize) {
      return '0 0';
    }
    // find the scalled border radius
    const borderRadius = parseFloat(cellSize) / 2.5;
    return `${
      addTopBorderRadius ? `${borderRadius}em ${borderRadius}em` : '0 0'
    } ${addBottomBorderRadius ? `${borderRadius}em ${borderRadius}em` : '0 0'}`;
  };

  // sizing and spacing config
  const [cellSizeRaw, setCellSizeRaw] = useState<number>();
  useEffect(() => {
    if (!cellSize) return;
    setCellSizeRaw(parseFloat(cellSize));
  }, [cellSize]);

  const [colGap, setColGap] = useState<number>();
  const [colPaddingHorizontal, setColPaddingHorizontal] = useState<number>();
  const [defaultYAxisHeight, setDefaultYAxisHeight] = useState<number>();
  const [scaledCellHeight, setScaledCellHeight] = useState<number>();
  // cell size * number of cells
  const [xAxisWidth, setXAxisWidth] = useState<number>();

  useEffect(() => {
    if (!cellSizeRaw) return;
    setColGap(cellSizeRaw / 8);
    setColPaddingHorizontal(cellSizeRaw);
    setDefaultYAxisHeight(cellSizeRaw);
    setScaledCellHeight(cellSizeRaw / yInterval);
  }, [cellSizeRaw, yInterval]);

  useEffect(() => {
    if (!cellSizeRaw || !colGap || !colPaddingHorizontal) return;
    setXAxisWidth(
      cellSizeRaw * props.data.cols[0].rows[0].length +
        colGap +
        colPaddingHorizontal * 2,
    );
  }, [cellSizeRaw, colGap, colPaddingHorizontal, props.data.cols]);

  /**
   * Helper function to determine if a row should be rendered based on the interval
   * @param index The index of the row to determine if it should be rendered
   * @returns if the row should be rendered
   */
  const shouldRender = (index: number) => {
    return (
      (index + yIntervalOffset) % yInterval === 0 ||
      (forceFirstYAxis && index === props.data.yAxisValues?.length - 1) ||
      (forceLastYAxis && index === 0)
    );
  };

  return (
    <section style={{ ...styles.container, ...props.style }}>
      {/* Y axis label */}
      <div style={{ ...styles.yAxis }}>
        {props.data.yAxisValues?.map((yAxisValue, index) => {
          return (
            <div
              key={index}
              style={{
                ...styles.yAxisRow,
                height: `${defaultYAxisHeight}em`,
                ...(enableDataScaleToMatchInterval && !shouldRender(index)
                  ? { height: `${scaledCellHeight}em` }
                  : {}),
                ...props.yAxisStyle,
              }}>
              {
                <label style={styles.yAxisText}>
                  {shouldRender(index) ? yAxisValue : ''}{' '}
                </label>
              }
            </div>
          );
        })}
      </div>
      {/* Data */}
      <div style={{ ...styles.dataContainer }}>
        {props.data.cols.map((col, colIndex) => {
          return (
            <div key={colIndex} style={{ ...styles.col }}>
              <div
                style={{
                  ...(colHovered === colIndex
                    ? { backgroundColor: theme.colors.transparentGrey }
                    : {}),
                  // last data column should not have a right border
                  ...(colIndex === props.data.cols.length - 1
                    ? {
                        borderRight: `1px dashed ${theme.colors.transparentBlack}`,
                      }
                    : {}),
                  borderLeft: `1px dashed ${theme.colors.transparentBlack}`,
                  padding: `0 ${colPaddingHorizontal}em`,
                }}
                onMouseOver={() => {
                  setColHovered(colIndex);
                }}
                onMouseLeave={() => {
                  setColHovered(undefined);
                }}>
                {col.rows.map((row, rowIndex) => {
                  return (
                    <div
                      key={rowIndex}
                      style={{
                        ...styles.dataRow,
                        gap: `${colGap}em`,
                        height: cellSize,
                        ...(enableDataScaleToMatchInterval &&
                        !shouldRender(rowIndex)
                          ? { height: `${scaledCellHeight}em` }
                          : {}),
                      }}>
                      {row.map((cell, cellIndex) => {
                        return (
                          <div
                            key={cellIndex}
                            style={{
                              ...styles.dataRowCell,
                              height: cellSize,
                              width: cellSize,
                              ...{
                                backgroundColor: cell.filled(cell.value)
                                  ? cell.color
                                  : 'transparent',
                              },
                              ...{
                                borderRadius: `${calculateBorderRadius(col, rowIndex, cellIndex)}`,
                              },
                              ...(enableDataScaleToMatchInterval &&
                              !shouldRender(rowIndex)
                                ? { height: `${scaledCellHeight}em` }
                                : {}),
                            }}></div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
              {/* Tool tip */}
              {colHovered === colIndex && (
                <ToolTip
                  $cellSizeRaw={cellSizeRaw ?? 0}
                  $bgColor={theme.colors.white}
                  onMouseOver={() => {
                    setColHovered(colIndex);
                  }}>
                  {col.toolTipContent}
                </ToolTip>
              )}
              {/* X axis label */}
              <div
                style={{
                  ...{ width: `${xAxisWidth}em` },
                  ...styles.xAxisText,
                  ...col.xAxisStyle,
                }}>
                {col.xAxisValue}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default CandleBarGraph;
