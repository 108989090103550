import i18n from '../../../translations';
import {
  CustomColumnDefintion,
  CustomTableColumns,
  SensorWearHoursDailyColumns,
  SensorWearHoursWeeklyColumns,
} from '../../../types/tables';
import { rowToExportString } from '../../../utils/tables';

export const transformWeeklyComplianceDataForExport = (
  data: CustomTableColumns[],
) => {
  const exportData = data as SensorWearHoursWeeklyColumns[];
  return exportData.map(row => {
    const newRowValues = rowToExportString(row);
    // get the keys we want to see
    const exportHeaders = WEEKLY_COMPLIANCE_COLUMNS.map(
      column => column.headerName,
    );

    // create the new object
    const finalExport: Record<string, string> = {};
    for (let i = 0; i < exportHeaders.length; i++) {
      finalExport[exportHeaders[i]] = newRowValues[i].toString();
    }

    return finalExport;
  });
};

export const transformDailyComplianceDataForExport = (
  data: CustomTableColumns[],
) => {
  const exportData = data as SensorWearHoursDailyColumns[];
  return exportData.map(row => {
    const newRowValues = rowToExportString(row);
    // get the keys we want to see
    const exportHeaders = DAILY_COMPLIANCE_COLUMNS.map(
      column => column.headerName,
    );

    // create the new object
    const finalExport: Record<string, string> = {};
    for (let i = 0; i < exportHeaders.length; i++) {
      finalExport[exportHeaders[i]] = newRowValues[i].toString();
    }

    return finalExport;
  });
};

/**
 * The style for the cells
 */
export const cellStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '0.5em',
  marginRight: '0.5em',
};

export const subjectSummaryColumnsPrefix = 'tables.subjectSummary.columns';
export const SUBJECT_SUMMARY_COLUMNS: CustomColumnDefintion[] = [
  {
    column: 'subjectID',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.subjectId`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'redcapID',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.redcapId`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'primaryTherapist',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.primaryTherapist`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'secondaryTherapist',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.secondaryTherapist`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'weekNumber',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.weekNumber`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'rightMAC',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.rightMAC`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'leftMAC',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.leftMAC`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'affectedSide',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.affectedSide`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
];

export const weeklyComplianceColumnsPrefix =
  'tables.sensorWearHoursWeekly.columns';
export const WEEKLY_COMPLIANCE_COLUMNS: CustomColumnDefintion[] = [
  {
    column: 'subjectID',
    sortable: false,
    headerName: i18n.t(`${weeklyComplianceColumnsPrefix}.subjectId`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'date',
    sortable: false,
    headerName: i18n.t(`${weeklyComplianceColumnsPrefix}.date`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'affectedSideHours',
    sortable: false,
    headerName: i18n.t(`${weeklyComplianceColumnsPrefix}.affectedSideHours`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'unaffectedSideHours',
    sortable: false,
    headerName: i18n.t(`${weeklyComplianceColumnsPrefix}.unaffectedSideHours`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
];

export const dailyComplianceColumnsPrefix =
  'tables.sensorWearHoursDaily.columns';
export const DAILY_COMPLIANCE_COLUMNS: CustomColumnDefintion[] = [
  {
    column: 'subjectID',
    sortable: false,
    headerName: i18n.t(`${dailyComplianceColumnsPrefix}.subjectId`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'date',
    sortable: false,
    headerName: i18n.t(`${dailyComplianceColumnsPrefix}.date`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'hour',
    sortable: false,
    headerName: i18n.t(`${dailyComplianceColumnsPrefix}.hour`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'affectedSideHours',
    sortable: false,
    headerName: i18n.t(`${dailyComplianceColumnsPrefix}.affectedSideHours`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'unaffectedSideHours',
    sortable: false,
    headerName: i18n.t(`${dailyComplianceColumnsPrefix}.unaffectedSideHours`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
];
