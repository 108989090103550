import { I18n } from 'i18n-js';
import en from './en.json';

const i18n = new I18n(
  {
    en,
  },
  {
    missingBehavior: 'error',
  },
);

export default i18n;
