import {
  type ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

export default (theme: ThemeContent): StyleSheet => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  leftSection: {
    marginLeft: '2%',
    marginRight: '2%',
  },
  rightSection: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '0.5em',
  },
  labelsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '0.5em',
  },
  defaultLabel: {
    backgroundColor: theme.colors.aliceBlue,
    color: theme.colors.black,
    borderRadius: '0.25em',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.3em',
    padding: '0.3em',
  },
  h1: {
    fontWeight: 'bold',
  },
  whiteText: {
    color: theme.colors.white,
  },
});
