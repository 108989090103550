import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import { Listbox, Transition } from '@headlessui/react';
import FormDropdownStyles from './FormDropdownStyles';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

interface FormDropdownProps {
  name: string;
  options: Array<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    label: string;
  }>;
  placeholder?: string;
  label: string;
  labelStyle?: React.CSSProperties;
  maxOptionsBeforeScroll?: number;
}

/**
 * Dropdown used in forms that has formik useField hook
 */
export const FormDropdown = ({
  name,
  options,
  placeholder,
  ...props
}: FormDropdownProps) => {
  const { styles } = useThemedComponent(FormDropdownStyles);
  const [field, meta] = useField({ name });
  const [buttonText, setButtonText] = useState<string>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    setError(meta.error !== undefined && meta.touched);
  }, [meta.error, meta.touched]);

  useEffect(() => {
    if (field.value === undefined) {
      setButtonText(placeholder);
    } else {
      const selected = options.find(opt => {
        return field.value === opt.value;
      });
      setButtonText(selected?.label ?? placeholder);
    }
  }, [field, options, placeholder]);

  return (
    <div style={styles.fieldContainer}>
      <div style={styles.labelContainer}>
        <label style={{ ...styles.label, ...props.labelStyle }}>
          {props.label}
        </label>
      </div>
      <Listbox
        as="div"
        className="space-y-1"
        value={field.value}
        onChange={value => {
          field.onChange({ target: { value, name } });
        }}
        style={styles.relative}>
        {({ open }) => (
          <>
            <div style={styles.relative}>
              <span className="inline-block w-full rounded-md shadow-sm">
                <Listbox.Button
                  style={{
                    ...styles.dropdownButton,
                    ...(error ? styles.inputError : {}),
                  }}>
                  <span
                    style={
                      buttonText === placeholder
                        ? styles.placeholder
                        : undefined
                    }>
                    {buttonText}
                  </span>
                  <ChevronDownIcon
                    aria-hidden="true"
                    style={styles.chevronIcon}
                  />
                </Listbox.Button>
              </span>

              <Transition
                show={open}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="absolute mt-1 w-full rounded-md bg-white shadow-lg">
                <Listbox.Options
                  static
                  style={{
                    ...styles.menuItems,
                    ...(props.maxOptionsBeforeScroll
                      ? {
                          maxHeight: `${props.maxOptionsBeforeScroll * 1.875}rem`,
                        }
                      : {}),
                  }}>
                  {options.map(({ value, label }, index) => (
                    <Listbox.Option key={index} value={value}>
                      {({ active }) => (
                        <span
                          style={
                            active || field.value === value
                              ? { ...styles.menuItem, ...styles.activeMenuItem }
                              : styles.menuItem
                          }
                          onClick={() => {
                            field.onChange({ target: { value, name } });
                            setButtonText(label);
                          }}>
                          {label}
                        </span>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};
