/* eslint-disable @typescript-eslint/no-explicit-any */
import { type ROUTES } from '../router';
import { Group } from '../schemas/Profile';
import { type UnionToIntersection } from '../utils/type';
import { ISOString } from '../utils/dateTime';

/**
 * All tables in the database.
 * Remember to add any new table interfaces here!
 */
export type CustomTableColumns = {
  /**
   * This optional field is used for row identifier in row selection manipulations.
   * It will be used in getRowId if we want to key rowSelection state by something else
   * (most likely Parse object id) instead of the default indices.
   */
  id?: string;
} & (
  | HomeTableColumns
  | SystemInfoTableColumns
  | ExternalUserRolesColumns
  | InternalUserRolesColumns
  | SubjectSummaryTableColumns
  | HistoricalSensorColumns
  | GDMInformationColumns
  | GDMHourlyColumns
  | HEPInformationColumns
  | CurrentDAAPGoalColumns
  | CompletedDAAPColumns
  | DAAPGoalHistoryColumns
  | SensorColumns
  | SensorWearHoursWeeklyColumns
  | SensorWearHoursDailyColumns
);

/**
 * All keys of the tables in the database. Useful for making definitions.
 */
export type CustomTableColumnKeys = UnionToIntersection<CustomTableColumns>;

export type TableLink<T> = {
  params: object;
  value: T;
};

export interface HomeTableColumns {
  profileID: string;
  active: boolean;
  subjectID: string;
  redcapID: string;
  primaryTherapist: string;
  primaryTherapistID: string;
  secondaryTherapist: string;
  secondaryTherapistID: string | undefined;
  group: Group;
  weekNumber: number;
  appLastOpened: TableColumnStatus;
  sensorStatus: TableColumnStatus;
  sensorUse: TableColumnStatus;
  gdmGoalSet: TableColumnStatus;
  gdmGoalMet: TableColumnStatus;
  daapGoalSet: TableColumnStatus;
  daapGoalMet: TableColumnStatus;
  hepStatus: TableColumnStatus;
}

export interface ExternalUserRolesColumns {
  externalUser: string;
  dateAdded: Date;
  role: string;
}

export interface SensorColumns {
  mac: string;
  dateAdded: Date;
  batteryNumber: string;
  weldNumber: string;
}

export interface SystemInfoTableColumns {
  subjectID: string;
  redcapID: string;
  rightMAC: string;
  leftMAC: string;
  affectedSide: string;
  enrollmentDate: Date;
}

export interface InternalUserRolesColumns {
  internalUser: string;
  dateAdded: Date;
  role: string;
}

export interface SubjectSummaryTableColumns {
  subjectID: string;
  redcapID: string;
  primaryTherapist: string;
  secondaryTherapist: string;
  weekNumber: number;
  rightMAC?: string;
  leftMAC?: string;
  affectedSide: string;
}

export interface HistoricalSensorColumns {
  subjectID: string;
  mac: string;
  side: string;
  affectedSide: string;
  initialPairDate: string | Date;
  initialConnectionDate: string | Date;
}

export interface GDMInformationColumns {
  subjectID: string;
  date: Date;
  affectedSideTotal: number;
  unaffectedSideTotal: number;
  goal: number;
}

export interface GDMHourlyColumns {
  subjectID: string;
  date: Date;
  hour: number;
  affectedCount: number;
  unaffectedCount: number;
  affectedSide: string;
}

export interface HEPInformationColumns {
  subjectID: string;
  date: string;
  startTime: string;
  endTime: string;
  totalTime: string;
  note: string;
}

export interface CurrentDAAPGoalColumns {
  subjectID: string;
  name: string;
  actionPlan: string;
  confidence: number;
  difficulty: number;
  when: string;
  where: string;
  how: string;
  who: string;
  startDate: string;
}

export interface CompletedDAAPColumns {
  subjectID: string;
  date: string;
  timeCompleted: string;
  note: string;
}

export interface DAAPGoalHistoryColumns {
  subjectID: string;
  name: TableLink<string>;
  actionPlan: string;
  confidence: number;
  difficulty: number;
  when: string;
  where: string;
  how: string;
  who: string;
  startDate: string;
  endDate: string;
  planId: string;
}

export interface SensorWearHoursWeeklyColumns {
  subjectID: string;
  date: string;
  affectedSideHours: string;
  unaffectedSideHours: string;
}

export interface SensorWearHoursDailyColumns {
  subjectID: string;
  date: string;
  hour: number;
  affectedSideHours: string;
  unaffectedSideHours: string;
}

/** Table Helpers **/

export enum TableColumnStatus {
  GOOD = 'GOOD',
  ALERT = 'ALERT',
  WARNING = 'WARNING',
  NEUTRAL = 'NEUTRAL',
  UNKNOWN = 'UNKNOWN',
}

/**
 * The definition of a column in a table.
 */
export interface CustomColumnDefintion {
  column: keyof CustomTableColumnKeys; // the key of the column matching the type
  sortable?: boolean; // wether or not the column is sortable, defaults to true
  headerName: string; // the name of the column
  headerHelperText?: string; // the helper text for the column
  headerStyle?: React.CSSProperties; // the style of the header
  cellLink?: keyof typeof ROUTES; // the route (with out any parameters) to the page
  cellTransform?: (data: any) => any; // a function to transform the data
  cellTextColor?: (data: any) => string; // a function to color the data
  cellStyle?: React.CSSProperties; // the style of each cell in the column
  exportTransform?: (data: any) => any; // a function to transform the data for export
  includeInExport?: boolean; // whether or not to include the column in the export, defaults to true
  cellParams?: any; // any additional parameters to pass to the cell for links
}

export enum SortOrder {
  ASC,
  DESC,
}

export interface SortedCol {
  colKey: keyof CustomTableColumnKeys; // the key of the column matching the type;
  index: number; // the index of the column in the table
  order: SortOrder;
}

export interface TableOptions {
  /**
   * The columns that are sorted and their order
   */
  sort: SortedCol[];
  /**
   * The search string to filter the data
   */
  search?: string;
  /**
   * The filters to apply to the data
   */
  filters?: ColumnFilter[];
}

export enum FilterType {
  NUMBER,
  STRING,
  DATE,
  ARRAY_NUMBER,
  ARRAY_STRING,
  ARRAY_DATE,
  POINTER,
}

/**
 * Possible filters for a single column
 */
export interface ColumnFilter {
  columnName: string;
  filterType: FilterType;
  equalTo?: string;
  notEqualTo?: string;
  greaterThan?: string;
  lessThan?: string;
  greaterThanOrEqualTo?: string;
  lessThanOrEqualTo?: string;
  contains?: string;
  regex?: string;
}

export interface DateRangeOption {
  asString: string;
  startDate: Date;
  endDate: Date;
}

export interface TableResponse<T> extends TableOptions {
  data: T[];
  totalEntries: number;
}

export interface TableRequest extends TableOptions {}

export interface SingleSubjectRequest extends TableRequest {
  subjectId: string;
}

export interface SubjectSummaryRequest extends SingleSubjectRequest {}

export interface SensorHistoryRequest extends SingleSubjectRequest {}

export interface DateRangePageRequest extends TableRequest {
  startDateISO: ISOString;
  endDateISO: ISOString;
}

export interface SingleDateRequest extends TableRequest {
  dateISO: ISOString;
}

export interface GDMInfoRequest
  extends SingleSubjectRequest,
    DateRangePageRequest {}

export interface GDMHourlyRequest
  extends SingleSubjectRequest,
    SingleDateRequest {}

export interface HEPInfoRequest
  extends SingleSubjectRequest,
    DateRangePageRequest {}

export interface SensorWearWeeklyRequest
  extends SingleSubjectRequest,
    DateRangePageRequest {}

export interface SensorWearDailyRequest
  extends SingleSubjectRequest,
    SingleDateRequest {}

export type TherapistFilterOption = {
  value: string;
  id: string;
};

export interface CurrentDAAPRequest extends SingleSubjectRequest {}

export interface CompletedDAAPRequest extends SingleSubjectRequest {}

export interface CompletedDAAPHistoryRequest extends SingleSubjectRequest {
  planId: string;
}

export interface DAAPRHistoryRequest extends SingleSubjectRequest {}
export interface HomeSummaryResponse extends TableResponse<HomeTableColumns> {
  therapists: TherapistFilterOption[];
  lastSynced: Date;
  warnings: number;
  alerts: number;
}
interface KeyedTableReponse<T> extends TableResponse<T> {
  name: string;
}

export type CompletedDAAPResponse = Array<
  KeyedTableReponse<CompletedDAAPColumns>
>;

export type CompletedDAAPHistoryResponse =
  KeyedTableReponse<CompletedDAAPColumns>;
