import {
  type ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

export default (theme: ThemeContent): StyleSheet => ({
  fieldContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  labelContainer: {
    flex: 1,
  },
  placeholder: {
    color: theme.colors.coolGrey,
  },
  label: {
    fontWeight: 'bold',
  },
  relative: {
    position: 'relative',
    flex: 2,
  },
  dropdownButton: {
    width: '100%',
    position: 'relative',
    borderRadius: 5,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.colors.coolGrey,
    paddingLeft: 5,
    backgroundColor: theme.colors.white,
    height: 40,
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputError: {
    borderColor: theme.colors.red,
    borderWidth: '2px',
  },
  chevronIcon: {
    height: '1rem',
    width: '1rem',
    marginLeft: '0.25rem',
  },
  menuItems: {
    position: 'absolute',
    zIndex: 10,
    marginTop: '0.5rem',
    minWidth: '9rem',
    transformOrigin: 'top left',
    borderRadius: '0.375rem',
    backgroundColor: theme.colors.white,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.25)',
    borderWidth: '1px',
    borderColor: 'rgba(0, 0, 0, 0.05)',
    outline: 'none',
    overflowY: 'auto',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    width: '100%',
  },
  menuItem: {
    padding: '0.5rem 1rem',
    fontSize: '0.875rem',
    fontWeight: '500',
    textDecoration: 'none',
    cursor: 'pointer',
    display: 'block',
  },
  activeMenuItem: {
    backgroundColor: theme.colors.gray,
  },
});
