import {
  type StyleSheet,
  useThemedComponent,
  ThemeContent,
} from '../../../providers/ThemeProvider';
import i18n from '../../../translations';
import { useLoading } from '../../../providers/LoadingProvider';
import { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { PORTAL_RELATIVE_URL } from '../../../router';
import { useLogging } from '../../../providers/LoggingProvider';

export const SEARCH_PARAM_FROM_PATH = 'fromPath';
export const SEARCH_PARAM_TIMESTAMP = 'timestamp';

export default function TooManyRequests() {
  const { styles } = useThemedComponent([TooManyRequestsStyles]);
  const { setLoading } = useLoading();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const logger = useLogging(TooManyRequests.name);

  const timestamp = useMemo(() => {
    return searchParams.get(SEARCH_PARAM_TIMESTAMP);
  }, [searchParams]);

  const fromPath = useMemo(() => {
    return searchParams.get(SEARCH_PARAM_FROM_PATH);
  }, [searchParams]);

  useEffect(() => {
    setLoading(false);
    if (!timestamp || !fromPath || isNaN(Number(timestamp))) {
      return;
    }
    const then = DateTime.fromMillis(Number(timestamp));
    const now = DateTime.now();
    if (now.diff(then, 'minutes').minutes >= 1 && fromPath) {
      logger.log(
        'Back to normal page',
        fromPath.replace(new RegExp('^' + PORTAL_RELATIVE_URL), ''),
      );
      navigate(fromPath.replace(new RegExp('^' + PORTAL_RELATIVE_URL), '/'));
    }
  }, [fromPath, logger, navigate, setLoading, timestamp]);

  return (
    <div style={{ ...styles.pageContainer, ...styles.container }}>
      <h1 style={styles.titleText}>{i18n.t('429error')}</h1>
      <h1 style={styles.subtitleText}>{i18n.t('serverError')}</h1>
      <hr style={styles.divider} />
      <label>{i18n.t('pleaseWait')}</label>
    </div>
  );
}

const TooManyRequestsStyles = (theme: ThemeContent): StyleSheet => ({
  container: {
    padding: 30,
    gap: 10,
  },
  titleText: {
    fontSize: 36,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  subtitleText: {
    fontSize: '1.5rem',
  },
  divider: {
    borderTopWidth: '1px',
    borderTopColor: theme.colors.coolGrey,
    width: '100%',
  },
});
