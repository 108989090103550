import React from 'react';

import SensorIconStyles from './SensorIconStyles';
import { ReactComponent as SensorOutline } from '../../../assets/SensorOutline.svg';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import i18n from '../../../translations';

export enum SensorType {
  LEFT,
  RIGHT,
}

export interface SensorIconProps {
  /**
   * Type of sensor to display, inside the outline
   */
  type: SensorType;
  /**
   * Width and height of the outline SVG. If not provided, default values will be used.
   */
  outlineWidth?: number | string | undefined;
  /**
   * Width and height of the outline SVG. If not provided, default values will be used.
   */
  outlineHeight?: number | string | undefined;

  style?: React.CSSProperties;
}

const DEFAULT_SVG_WIDTH = 39;
const DEFAULT_SVG_HEIGHT = 40;

const SensorIcon = (props: SensorIconProps) => {
  const { styles } = useThemedComponent([SensorIconStyles]);

  return (
    <div {...props} style={{ ...styles.container, ...props.style }}>
      {(() => {
        switch (props.type) {
          case SensorType.LEFT:
            return (
              <p style={{ ...styles.textType, ...styles.blue }}>
                {i18n.t('globals.leftNarrow')}
              </p>
            );
          case SensorType.RIGHT:
            return (
              <p style={{ ...styles.textType, ...styles.green }}>
                {i18n.t('globals.rightNarrow')}
              </p>
            );
          default:
            console.warn('Invalid SensorType');
            return <></>;
        }
      })()}
      <SensorOutline
        width={props.outlineWidth ? props.outlineWidth : DEFAULT_SVG_WIDTH}
        height={props.outlineHeight ? props.outlineHeight : DEFAULT_SVG_HEIGHT}
      />
    </div>
  );
};

export default SensorIcon;
