import { Sensor } from './Sensor';
import Parse from '../parse';
import { OwnableObject } from './interfaces/OwnableObject';
import { Profile } from './Profile';
import { useMemo } from 'react';

/**
 * Records any updates to a given sensor
 * connected
 * discovered
 * disconnected
 * data read
 * data write
 * etc.
 */
export interface SensorEventAttributes extends OwnableObject {
  sensor: Sensor;
  time: Date;
  event: string;
  meta?: Parse.JSONBaseAttributes | Parse.RawJSONOptions;
}

/**
 * List of all the events that can be sent to the sensor
 */
export enum SensorRequestEvent {
  GET_PERIODIC_RECORDING,
  REQUEST_USAGE,
  REQUEST_TIME,
  REQUEST_UPDATE_TIME,
  REQUEST_SIDE,
  REQUEST_UPDATE_SIDE,
  REQUEST_RECORDING_STATE,
  REQUEST_LED_BLINK,
  REQUEST_HAPTIC_PULSE,
  REQUEST_HAPTIC_AND_LED_PULSE,
  REQUEST_FW_VERSION,
  REQUEST_FW_INFO,
  REQUEST_GDM_LED_STATE,
  REQUEST_UPDATE_GDM_LED_STATE,
}

/**
 * List of all the events that can be received from the sensor
 */
export enum SensorResponseEvent {
  RESPONSE_PERIODIC_RECORDING,
  ALERT_BATTERY,
  ALERT_DATA_COLLECTION,
  RESPONSE_USAGE,
  RESPONSE_RECORDING_STATE,
  REPONSE_DEVICE_TIME,
  REPONSE_SIDE,
  REPONSE_FW_VERSION,
  REPONSE_FW_INFO,
  REPONSE_GDM_LED_STATE,
}

export const classname = 'SensorEvent';

export class SensorEvent extends Parse.Object<SensorEventAttributes> {
  constructor(attributes: SensorEventAttributes) {
    super(classname, attributes);
  }
}

Parse.Object.registerSubclass(classname, SensorEvent);

export function useSensorEventService() {
  const getMostRecentSync = async (profile: Profile, sensor: Sensor) => {
    const query = new Parse.Query(SensorEvent);
    query.equalTo('owner', profile);
    query.equalTo('sensor', sensor);
    query.descending('time');
    query.limit(1);
    query.equalTo(
      'event',
      SensorResponseEvent[SensorResponseEvent.RESPONSE_PERIODIC_RECORDING],
    );
    const result = await query.first();
    return result;
  };

  const getMostRecentUsage = async (profile: Profile, sensor: Sensor) => {
    const query = new Parse.Query(SensorEvent);
    query.equalTo('owner', profile);
    query.equalTo('sensor', sensor);
    query.descending('time');
    query.limit(1);
    query.equalTo(
      'event',
      SensorResponseEvent[SensorResponseEvent.RESPONSE_USAGE],
    );
    const result = await query.first();
    return result;
  };

  return useMemo(
    () => ({
      getMostRecentSync,
      getMostRecentUsage,
    }),
    [],
  );
}
