import { useEffect } from 'react';
import { useTableColumns } from '../../../hooks/useTableColumns';
import {
  CompletedDAAPColumns,
  CurrentDAAPGoalColumns,
  SortOrder,
} from '../../../types/tables';
import { COMPLETED_DAAP_COLUMNS } from '../../../pages/authorized/DAAPs/helpers';
import Table from '../Table/Table';
import {
  StyleSheet,
  useThemedComponent,
} from '../../../providers/ThemeProvider';
import { commonTableStyles } from '../Table/tableUtil';
import React from 'react';

export interface CompletedDAAPProps {
  name: string;
  data: CompletedDAAPColumns[];
  key: React.Key;
  style?: React.CSSProperties;
}

const CompletedDAAP = ({ name, data, key, ...props }: CompletedDAAPProps) => {
  const { styles } = useThemedComponent([
    commonTableStyles,
    CompletedDAAPStyles,
  ]);
  const { columnHelper, tableData, tableName, setTableData } =
    useTableColumns<CurrentDAAPGoalColumns>({
      defaultSorting: [{ colKey: 'date', index: 1, order: SortOrder.DESC }],
      tableName: name,
    });

  useEffect(() => {
    setTableData(data);
  }, [data, setTableData]);

  return (
    <div key={key} style={{ ...props.style }}>
      <div
        style={{
          marginBottom: '3%',
        }}>
        <h3 style={styles.h3}>{tableName}</h3>
      </div>
      <div style={styles.fixedHeightTable}>
        <Table
          columnHelper={columnHelper}
          columns={COMPLETED_DAAP_COLUMNS}
          data={tableData}
          style={{
            ...styles.table,
            marginBottom: '3%',
          }}
        />
      </div>
    </div>
  );
};

const CompletedDAAPStyles = (): StyleSheet => ({
  marginBottom: {
    marginBottom: '3%',
  },
});

export default React.memo(CompletedDAAP);
