import { set } from 'lodash';
import tinycolor from 'tinycolor2';

type ColorByPath = Record<string, string | undefined>;

/**
 * Recolor your lottie json. Use
 * https://colorize-react-native-lottie.netlify.app/ to find the paths
 *
 * @param json The lottie json to colorize
 * @param colorByPath the paths to colorize and their colors
 * @returns a new json with the colors applied
 */
export function colorizeLottie(
  json: Record<string, unknown>,
  colorByPath: ColorByPath,
): Record<string, unknown> {
  const nextJson = JSON.parse(JSON.stringify(json));

  Object.entries(colorByPath).forEach(([path, color]) => {
    if (!color) return;

    const rgbPercentages = tinycolor(color).toPercentageRgb();
    // These are string types, not sure why ts is complaining
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const rFraction = parseInt(rgbPercentages.r, 10) / 100;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const gFraction = parseInt(rgbPercentages.g, 10) / 100;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const bFraction = parseInt(rgbPercentages.b, 10) / 100;

    const pathParts = path.split('.');
    set(nextJson, [...pathParts, 0], rFraction);
    set(nextJson, [...pathParts, 1], gFraction);
    set(nextJson, [...pathParts, 2], bFraction);
  });

  return nextJson;
}
