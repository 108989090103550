import { Sensor, SensorPosition } from './Sensor';
import Parse from '../parse';
import { OwnableObject } from './interfaces/OwnableObject';
import { largeParseQuery } from '../utils/network';
import { Profile } from './Profile';
import useErrorHandling from '../hooks/useErrorHandling';
import { useMemo } from 'react';
import { DateTime } from 'luxon';

/**
 * Data of 1, 15-minute interval from the sensor
 * Has a Sensor reference so it knows where that data came from
 * Stores both MOVEMENT_TYPE and SENSOR_POSITION enums for historical accuracy if a Sensor is swapped.
 */
export enum MovementType {
  UNAFFECTED,
  AFFECTED,
}

export interface MovementAttributes extends OwnableObject {
  sensors: Sensor[] | null[] | null;
  startTime: Date;
  endTime: Date;
  type: MovementType;
  position: SensorPosition;
  count: number;
  meta: JSON;
}

export const classname = 'Movement';

export class Movement extends Parse.Object<MovementAttributes> {
  constructor(attributes: MovementAttributes) {
    super(classname, attributes);
  }
}

Parse.Object.registerSubclass(classname, Movement);

export function useMovementService() {
  const getTimezone = useErrorHandling(async (profile: Profile) => {
    const lastMovement = await getLastMovement(profile);
    if (lastMovement != undefined) {
      return lastMovement.attributes.timezone;
    }
    return profile.attributes.timezone;
  }, []);

  const getMovementsBetween = useErrorHandling(
    async (profile: Profile, start: DateTime, end: DateTime) => {
      const timezone = await getTimezone(profile);
      let startInSubjectTimezone: Date;
      let endInSubjectTimezone: Date;
      if (start.zoneName === timezone) {
        startInSubjectTimezone = start.toJSDate();
        endInSubjectTimezone = end.toJSDate();
      } else {
        startInSubjectTimezone = start
          .setZone(timezone, { keepLocalTime: true })
          .toJSDate();
        endInSubjectTimezone = end
          .setZone(timezone, { keepLocalTime: true })
          .toJSDate();
      }
      const movementQuery = new Parse.Query(Movement);
      movementQuery.equalTo('owner', profile);
      movementQuery.greaterThanOrEqualTo('startTime', startInSubjectTimezone);
      movementQuery.lessThanOrEqualTo('startTime', endInSubjectTimezone);

      const count = await movementQuery.count();

      return largeParseQuery(movementQuery, count);
    },
    [],
  );

  const getFirstMovement = useErrorHandling(async (profile: Profile) => {
    const movementQuery = new Parse.Query(Movement);
    movementQuery.equalTo('owner', profile);
    movementQuery.ascending('startTime');
    movementQuery.limit(1);

    return await movementQuery.first();
  }, []);

  const getLastMovement = useErrorHandling(async (profile: Profile) => {
    const movementQuery = new Parse.Query(Movement);
    movementQuery.equalTo('owner', profile);
    movementQuery.descending('startTime');
    movementQuery.limit(1);

    return await movementQuery.first();
  }, []);

  return useMemo(
    () => ({
      getMovementsBetween,
      getFirstMovement,
      getLastMovement,
    }),
    [getFirstMovement, getLastMovement, getMovementsBetween],
  );
}
