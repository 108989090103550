import { transformForExportBase } from '../../../components/templates/Table/tableUtil';
import Parse from '../../../parse';
import i18n from '../../../translations';
import {
  GetNonSubjectUsersResponse,
  InternalUserRoles,
  StrokeWearRoles,
} from '../../../types/cloud';
import {
  type CustomColumnDefintion,
  CustomTableColumns,
  InternalUserRolesColumns,
} from '../../../types/tables';

/**
 * The columns definition for the set user roles page.
 * This is the order of the columns in the table and any customizations
 */
export const ROLE_COLUMNS: CustomColumnDefintion[] = [
  {
    column: 'internalUser',
    headerName: i18n.t('setInternalUserRoles.internalUser'),
  },
  { column: 'dateAdded', headerName: i18n.t('setInternalUserRoles.dateAdded') },
  {
    column: 'role',
    headerName: i18n.t('setInternalUserRoles.role'),
  },
];

// call the cloud function to get
export const formatAllNonSubjectUsers = async (
  users: GetNonSubjectUsersResponse,
) => {
  const userRows: InternalUserRolesColumns[] = users.map(
    ({ user, roles }: { user: Parse.User; roles: StrokeWearRoles[] }) => {
      // only show internal roles
      const internalRoles = roles.filter(r =>
        Object.keys(InternalUserRoles).includes(r),
      );
      return {
        id: user.id, // this is used for row selection
        internalUser: user.get('username'),
        dateAdded: user.get('createdAt'),
        role:
          internalRoles.length === 0
            ? '--'
            : internalRoles
                .map(r => i18n.t(`setInternalUserRoles.roles.${r}`))
                .join(', '),
      } as InternalUserRolesColumns;
    },
  );
  return userRows;
};

export const transformForExport = (data: CustomTableColumns[]) => {
  return transformForExportBase(data, ROLE_COLUMNS);
};
