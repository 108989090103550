import {
  type ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

// eslint-disable-next-line no-unused-vars
export default (theme: ThemeContent): StyleSheet => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pageButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: '500',
    cursor: 'pointer',
    padding: '0.375rem 0.75rem',
    border: 'none',
    borderRadius: '0.375rem',
    backgroundColor: theme.colors.white,
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
    height: '2rem',
  },
  inactivePageButton: {
    backgroundColor: theme.colors.gray,
    color: theme.colors.white,
    cursor: 'not-allowed',
  },
  dateFilter: {
    padding: '0.375rem 0.75rem',
    height: '2rem',
  },
});
