import React from 'react';
import DropDownSelect from '../../../components/atoms/DropDownSelect/DropDownSelect';
import { ReactComponent as RightArrow } from '../../../assets/RightArrow.svg';
import { ReactComponent as LeftArrow } from '../../../assets/LeftArrow.svg';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import DatePaginationAndFilterStyles from './DatePaginationAndFilterStyles';
import i18n from '../../../translations';

interface DatePaginationAndFilterProps {
  labels?: string[];
  options?: unknown[];
  selectedOptionIndex: number | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSelectedOptionIndex: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  pretext?: string;
}

const DatePaginationAndFilter = (props: DatePaginationAndFilterProps) => {
  const { theme, styles } = useThemedComponent([DatePaginationAndFilterStyles]);

  // Get the arrow button for the pagination
  const getPageArrowButton = (direction: 'left' | 'right') => {
    const iconBox = 0.9;
    return (
      <button
        style={{
          ...styles.pageButton,
          // If the button is inactive, make it look inactive
          ...(props.selectedOptionIndex === undefined ||
          (props?.options &&
            // if the selected option is the first and the direction is left
            ((props.selectedOptionIndex === 0 && direction === 'right') ||
              // if the selected option is the last and the direction is right
              (props.selectedOptionIndex >= props?.options.length - 1 &&
                direction === 'left')))
            ? styles.inactivePageButton
            : {}),
        }}
        onClick={() => {
          if (props.selectedOptionIndex === undefined || !props?.options) {
            return;
          }

          // narrow logic by direction
          if (direction === 'right') {
            // if there is nothing to the left, return
            if (props.selectedOptionIndex === 0) {
              return;
            }
            props.setSelectedOptionIndex(props.selectedOptionIndex - 1);
            return;
          }

          if (direction === 'left') {
            // if there is nothing to the right, return
            if (props.selectedOptionIndex >= props?.options?.length - 1) {
              return;
            }
            props.setSelectedOptionIndex(props.selectedOptionIndex + 1);
          }
        }}>
        {direction === 'left' ? (
          <LeftArrow
            color={theme.colors.primary}
            width={`${iconBox}rem`}
            height={`${iconBox}rem`}
          />
        ) : (
          <RightArrow
            color={theme.colors.primary}
            width={`${iconBox}rem`}
            height={`${iconBox}rem`}
          />
        )}
      </button>
    );
  };

  return (
    <div style={styles.container}>
      <p>{props.pretext ?? i18n.t('chooseAWeek')}</p>
      {getPageArrowButton('left')}
      {getPageArrowButton('right')}
      <DropDownSelect
        labels={props.labels}
        options={props.options}
        selectionIndex={props.selectedOptionIndex}
        setSelectionIndex={props.setSelectedOptionIndex}
        maxOptionsBeforeScroll={10}
        menuAlignment="right"
        menuStyle={{ width: '15rem' }}
        style={styles.dateFilter}
      />
    </div>
  );
};

export default DatePaginationAndFilter;
