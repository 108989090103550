import { useMemo } from 'react';
import useErrorHandling from '../hooks/useErrorHandling';
import Parse from '../parse';
import { Profile } from './Profile';
import { OwnableObject } from './interfaces/OwnableObject';
import { changeTimezone } from '../utils/dateTime';

/**
 * Different from Movement
 * Stores the Medbridge Exercise data
 */
export interface ExerciseAttributes extends OwnableObject {
  startTime: Date;
  endTime: Date;
  note?: string;
}

export const classname = 'Exercise';

export class Exercise extends Parse.Object<ExerciseAttributes> {
  constructor(attributes: ExerciseAttributes) {
    super(classname, attributes);
  }
}

Parse.Object.registerSubclass(classname, Exercise);

export function useExerciseService() {
  const getTimezone = useErrorHandling(async (profile: Profile) => {
    const lastExercise = await getLastExercise(profile);
    if (lastExercise != undefined) {
      return lastExercise.attributes.timezone;
    }
    return profile.attributes.timezone;
  }, []);

  const getExercisesBetween = useErrorHandling(
    async (profile: Profile, start: Date, end: Date) => {
      const timezone = await getTimezone(profile);
      const startInSubjectTimezone = changeTimezone(start, timezone);
      const endInSubjectTimezone = changeTimezone(end, timezone);
      const exerciseQuery = new Parse.Query(Exercise);
      exerciseQuery.equalTo('owner', profile);
      exerciseQuery.greaterThanOrEqualTo('startTime', startInSubjectTimezone);
      exerciseQuery.lessThanOrEqualTo('startTime', endInSubjectTimezone);

      return await exerciseQuery.find();
    },
    [],
  );

  const getFirstExercise = useErrorHandling(async (profile: Profile) => {
    const exerciseQuery = new Parse.Query(Exercise);
    exerciseQuery.equalTo('owner', profile);
    exerciseQuery.ascending('startTime');
    exerciseQuery.limit(1);

    return await exerciseQuery.first();
  }, []);

  const getLastExercise = useErrorHandling(async (profile: Profile) => {
    const exerciseQuery = new Parse.Query(Exercise);
    exerciseQuery.equalTo('owner', profile);
    exerciseQuery.descending('startTime');
    exerciseQuery.limit(1);

    return await exerciseQuery.first();
  }, []);

  return useMemo(
    () => ({
      getExercisesBetween,
      getFirstExercise,
      getLastExercise,
    }),
    [getExercisesBetween, getFirstExercise, getLastExercise],
  );
}
