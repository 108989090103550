import {
  useThemedComponent,
  StyleSheet,
  ThemeContent,
} from '../../../providers/ThemeProvider';
import i18n from '../../../translations';
import {
  CreateSubjectFields,
  CreateSubjectForm,
} from '../../../components/organisms/CreateSubjectForm/CreateSubjectForm';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  KNOWN_CLOUD_FUNCTIONS,
  useCloudContext,
} from '../../../providers/CloudProvider';
import { Group } from '../../../schemas/Profile';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { SubjectDataResponse } from '../Subject/Subject';
import { useLoading } from '../../../providers/LoadingProvider';
import Popup from '../../../components/atoms/Popup/Popup';
import { ROUTES } from '../../../router';

export default function UpdateSubjectInfo() {
  const { styles } = useThemedComponent([UpdateSubjectInfoStyles]);
  const { setLoading } = useLoading();
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const subject = useLoaderData() as SubjectDataResponse;
  const { cloudService, profileService, sensorHistoryService } =
    useCloudContext();
  const [initialValues, setInitialValues] = useState<CreateSubjectFields>();
  const localCreatedAt = useRef<Date>();
  const [newSubjectId, setNewSubjectId] = useState(subject.subjectId);

  useEffect(() => {
    const dataFetcher = async () => {
      setLoading(true);
      try {
        const profile = await profileService.getProfileBySubjectId(
          subject.subjectId,
        );
        if (!profile) {
          throw new Error();
        }
        const [affectedSide, { left, right }] = await Promise.all([
          sensorHistoryService.getProfileAffectedSide(profile),
          sensorHistoryService.getSubjectsCurrentSensors(profile),
        ]);

        if (!left || !right) {
          throw new Error('No sensors found for the given profile');
        }

        const values: CreateSubjectFields = {
          subjectId: profile.attributes.subjectId,
          redcapId: profile.attributes.redcapId,
          affectedSide,
          leftSensor: left.id,
          rightSensor: right.id,
          therapist: profile.attributes.therapist.id,
          studyCoordinator: profile.attributes.studyCoordinator.id,
          group: Group[profile.attributes.group] as unknown as Group,
          accessCode: profile.attributes.accessCode ?? '',
        };

        localCreatedAt.current = profile.attributes.localCreatedAt;
        setInitialValues(values);
      } catch (e) {
        alert('Failed to get subject info');
      } finally {
        setLoading(false);
      }
    };

    dataFetcher();
  }, [profileService, sensorHistoryService, setLoading, subject.subjectId]);

  const backToSubjectPage = useCallback(() => {
    setShowPopup(false);
    navigate(ROUTES.SUBJECT.buildPath({ subjectId: newSubjectId }));
  }, [navigate, newSubjectId]);

  const onSubmit = useCallback(
    async (values: CreateSubjectFields) => {
      try {
        setLoading(true);
        const params = JSON.parse(
          JSON.stringify({
            existingSubjectId: initialValues?.subjectId, // UPDATE the existing profile
            subjectId: values.subjectId,
            redcapId: values.redcapId,
            affectedSide: values.affectedSide,
            therapistId: values.therapist,
            studyCoordinatorId: values.studyCoordinator,
            group: Group[values.group as Group],
            accessCode: values.accessCode,
          }),
        );

        const updateSubjectResult = await cloudService.run(
          KNOWN_CLOUD_FUNCTIONS.UPDATE_PROFILE,
          params,
        );

        if (updateSubjectResult) {
          setNewSubjectId(values.subjectId);
          setShowPopup(true);
        } else {
          throw new Error();
        }
      } catch (e) {
        alert(`Failed to update subject ${e}`);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, initialValues?.subjectId, cloudService],
  );

  return (
    <div style={styles.pageContainer}>
      <div style={styles.pageHeader}>
        <h1 style={styles.h1}>{i18n.t('subject.updateSubject')}</h1>
      </div>
      <div style={styles.topContainer}>
        {initialValues && (
          <CreateSubjectForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            onCancel={backToSubjectPage}
            isUpdateForm={true}
          />
        )}
      </div>
      {showPopup && (
        <Popup
          open={showPopup}
          onClose={backToSubjectPage}
          title={i18n.t('subject.subjectUpdated')}
          buttons={[
            {
              title: `${i18n.t('globals.dismiss')}`,
              onClick: backToSubjectPage,
              style: styles.primaryButton,
            },
          ]}>
          <div style={styles.popupBody}>
            <p style={styles.p}>
              {i18n.t('subject.infoHasBeenUpdated', {
                subjectId: newSubjectId,
              })}
            </p>
          </div>
        </Popup>
      )}
    </div>
  );
}

const UpdateSubjectInfoStyles = (theme: ThemeContent): StyleSheet => ({
  topContainer: {
    marginTop: '2vmin',
    width: '45%',
  },
  primaryButton: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    borderRadius: 5,
    width: '100%',
    height: '5vh',
    borderStyle: 'none',
  },
  popupBody: {
    padding: '1vmin',
    marginTop: '2vmin',
    marginBottom: '2vmin',
  },
  p: {
    lineHeight: 1.5,
    textAlign: 'center',
  },
});
