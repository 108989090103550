import React from 'react';
import {
  type StyleSheet,
  useThemedComponent,
  ThemeContent,
} from '../../../providers/ThemeProvider';

export interface HomeTileProps {
  label: string;
  underTileLabel: string;
  count: number;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconStyle?: React.CSSProperties;
}

export const HomeTile = ({
  label,
  underTileLabel,
  count,
  Icon,
  iconStyle,
}: HomeTileProps) => {
  const { styles } = useThemedComponent(HomeTileStyles);

  return (
    <div style={styles.container}>
      <div style={{ ...styles.whiteContainer }}>
        <p style={{ ...styles.label }}>{label}</p>
        <div style={styles.row}>
          <Icon style={{ ...styles.icon, ...iconStyle }} />
          <p style={styles.p}>{count}</p>
        </div>
      </div>
      <div style={styles.grayContainer}>
        <p style={{ ...styles.underLabel }}>{underTileLabel}</p>
      </div>
    </div>
  );
};

const HomeTileStyles = (theme: ThemeContent): StyleSheet => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '32%',
    height: '20vh',
    backgroundColor: theme.colors.white,
    borderRadius: '0.7rem',
    boxShadow: '0px 2px 6px 0px #00000026',
  },
  label: {
    fontSize: '1.2rem',
  },
  underLabel: {
    fontSize: '0.8rem',
    textAlign: 'center',
  },
  icon: {
    height: '2rem',
    width: '2rem',
  },
  whiteContainer: {
    flex: 3,
    padding: '3%',
    paddingTop: '8%',
    paddingLeft: '10%',
  },
  grayContainer: {
    flex: 1,
    backgroundColor: theme.colors.greyWhite,
    borderRadius: '0 0 0.7rem 0.7rem',
    display: 'flex',
    alignItems: 'center',
    padding: '2%',
    paddingLeft: '10%',
  },
  row: {
    marginTop: '4%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5%',
  },
  p: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
});
