import {
  type ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

export default (theme: ThemeContent): StyleSheet => ({
  betweenInput: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 20,
  },
  underTitleContainer: {
    marginTop: '2vmin',
    width: '45%',
  },
  buttonContainer: {
    display: 'flex',
    gap: 10,
    marginTop: 20,
    width: '100%',
  },
  blueText: {
    color: theme.colors.blue,
  },
  greenText: {
    color: theme.colors.green,
  },
  label: {
    flex: 1,
  },
  value: {
    flex: 2,
  },
  boldText: {
    fontWeight: 'bold',
  },
  subjectIdContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    marginBottom: 20,
  },
});
