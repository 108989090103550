import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Group, Profile } from '../schemas/Profile';
import Parse from '../parse';
import { useCloudContext } from './CloudProvider';
import { useParams } from 'react-router-dom';
import { useLogging } from './LoggingProvider';

export interface SubjectContent {
  /**
   * Current Subject's attributes
   */
  group: Group | undefined;
  isActivated: boolean | undefined;
  deactivatedAt: Date | undefined;
  therapistId: string | undefined;
  secondaryTherapistId: string | undefined;
  timezone: string | undefined;
}

const SubjectContext = React.createContext<SubjectContent>(undefined!);

const SubjectProvider = ({ children }: { children: ReactNode }) => {
  const profileSubscription = useRef<Parse.LiveQuerySubscription>();
  const { profileService } = useCloudContext();
  const logger = useLogging(SubjectProvider.name);

  // Current subject's info
  const { subjectId } = useParams();
  const [group, setGroup] = useState<Group>();
  const [isActivated, setIsActivated] = useState<boolean>();
  const [deactivatedAt, setDeactivatedAt] = useState<Date>();
  const [therapistId, setTherapistId] = useState<string>();
  const [secondaryTherapistId, setSecondaryTherapistId] = useState<string>();
  const [timezone, setTimezone] = useState<string>();

  const updateCurrentStates = useCallback(
    (profile: Profile) => {
      if (profile.attributes.subjectId === subjectId) {
        setGroup(profile.attributes.group);
        setIsActivated(profile.attributes.activatedAt !== undefined);
        setDeactivatedAt(profile.attributes.deactivatedAt);
        setTherapistId(profile.attributes.therapist.id);
        setSecondaryTherapistId(profile.attributes.secondaryTherapist?.id);
        setTimezone(profile.attributes.timezone);
      }
    },
    [subjectId],
  );

  const subscribeProfiles = useCallback(async () => {
    const profileQuery = new Parse.Query('Profile');
    profileSubscription.current = await profileQuery.subscribe();

    profileSubscription.current.on('create', value => {
      const profile = value as Profile;
      updateCurrentStates(profile);
    });

    profileSubscription.current.on('update', value => {
      const profile = value as Profile;
      updateCurrentStates(profile);
    });
  }, [updateCurrentStates]);

  useEffect(() => {
    if (subjectId !== undefined) {
      profileService
        .getProfileBySubjectId(subjectId)
        .then((profile: Profile | undefined) => {
          if (profile) {
            updateCurrentStates(profile);
          } else {
            alert(`Profile ${subjectId} not found`);
          }
        });
    }
  }, [profileService, subjectId, updateCurrentStates]);

  useEffect(() => {
    subscribeProfiles();

    return () => {
      if (profileSubscription.current) {
        profileSubscription.current.unsubscribe();
        Parse.Object.unPinAllObjects().catch(e => {
          logger.error('Error unpin all objects from LDS', e);
        });
      }
    };
  }, [logger, subscribeProfiles]);

  return (
    <SubjectContext.Provider
      value={{
        group,
        isActivated,
        deactivatedAt,
        therapistId,
        secondaryTherapistId,
        timezone,
      }}>
      {children}
    </SubjectContext.Provider>
  );
};

const useSubjectContext = () => React.useContext(SubjectContext);

export { useSubjectContext, SubjectProvider };
