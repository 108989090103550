import {
  type ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

export default (theme: ThemeContent): StyleSheet => ({
  menuStyles: {
    position: 'relative',
    display: 'inline-block',
    textAlign: 'left',
  },
  menuWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  menuButton: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.875rem',
    fontWeight: '500',
    cursor: 'pointer',
    padding: '0.375rem 0.75rem',
    border: 'none',
    borderRadius: '0.375rem',
    backgroundColor: theme.colors.gray,
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
  },
  chevronIcon: {
    height: '1rem',
    width: '1rem',
    marginLeft: '0.25rem',
  },
  menuItems: {
    position: 'absolute',
    zIndex: 10,
    marginTop: '0.5rem',
    minWidth: '9rem',
    transformOrigin: 'top left',
    borderRadius: '0.375rem',
    backgroundColor: theme.colors.white,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.25)',
    borderWidth: '1px',
    borderColor: 'rgba(0, 0, 0, 0.05)',
    outline: 'none',
    overflowY: 'auto',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  menuItem: {
    padding: '0.5rem 1rem',
    fontSize: '0.875rem',
    fontWeight: '500',
    textDecoration: 'none',
    cursor: 'pointer',
    display: 'block',
  },
  activeMenuItem: {
    backgroundColor: theme.colors.gray,
  },
  checkableMenuItem: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  checkbox: {
    verticalAlign: 'middle',
    position: 'relative',
    bottom: '0.16em',
    marginRight: '0.5rem',
  },
});
