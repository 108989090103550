import { DateTime } from 'luxon';
import i18n from '../translations';

export type ISOString = string;

export const DEFAULT_TIMEZONE = 'America/New_York';

/**
 * Check if the given dates are on same day
 */
export const datesAreOnSameDay = (first: Date, second: Date): boolean =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

// Setters mutate Date objects so use deep copy instead
export const cloneDate = (d: Date) => new Date(d.getTime());

/**
 * Find the number of minutes ago from a given date. (from now)
 * @param date the date to compare to from now
 * @returns the number of minutes ago from the given date
 */
export const minutesAgoFromDate = (date: Date): number => {
  const now = new Date();
  const differenceInMilliseconds = now.getTime() - date.getTime();
  const minutesAgo = Math.floor(differenceInMilliseconds / (1000 * 60));
  return minutesAgo;
};

/**
 * Based on @minutesAgoFromDate from util/dateTime.ts
 * Changes the date to a string representing how long ago it was but
 * in a more compact format
 * @param date The date to convert
 * @returns A string representing how long ago the date was
 */
export const getTimeAgo = (date: Date): string => {
  const minutesAgo = minutesAgoFromDate(date);

  if (minutesAgo < 1) {
    return `${i18n.t('subject.sensorTime.minutesNarrow', { minutes: '<1' })}`;
  }

  // if the date is more than 24 hours ago, return the days
  if (minutesAgo >= 1440) {
    return `${i18n.t('subject.sensorTime.daysNarrow', { days: Math.floor(minutesAgo / 1440) })}`;
  }

  // if the date is more than 60 minutes ago, return the hours
  if (minutesAgo >= 60) {
    return `${i18n.t('subject.sensorTime.hoursNarrow', { hours: Math.floor(minutesAgo / 60) })}`;
  }

  // otherwise, return the minutes
  return `${i18n.t('subject.sensorTime.minutesNarrow', { minutes: minutesAgo })}`;
};

/**
 * Convert a Date object to another Date object that represents the same period of *time*
 * (same year, month, day, hour, minute) but in a different time zone
 * Example: 6/8/2024 9:00 AM GMT-4 to 6/8/2024 9:00 AM UTC
 * @param date JS date objet
 * @param timezone must be a valid IANA time zone string
 */
export const changeTimezone = (date: Date, timeZone: string): Date => {
  const dateTimeConverted = DateTime.fromObject(
    {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
      hour: date.getHours(),
      minute: date.getMinutes(),
      millisecond: date.getMilliseconds(),
    },
    { zone: timeZone },
  );
  return dateTimeConverted.toJSDate();
};

/**
 * Type guard for ISOString
 * @param isoString The string to check
 * @returns true if the string is a valid ISO string
 */
export const isISOString = (isoString: unknown): isoString is ISOString => {
  if (typeof isoString !== 'string') {
    return false;
  }
  return DateTime.fromISO(isoString).isValid;
};

/**
 * Converts a js date object to an ISO string
 * @param date The date to convert
 * @param timeZone The time zone to convert to
 * @returns The ISO string
 */
export const dateToISOString = (
  date: Date,
  timeZone: string = DEFAULT_TIMEZONE,
): ISOString => {
  const iso = changeTimezone(date, timeZone).toISOString();
  if (isISOString(iso)) {
    return iso;
  } else {
    throw new Error('Invalid ISO');
  }
};
