import {
  CustomColumnDefintion,
  CustomTableColumns,
  HomeTableColumns,
  TableColumnStatus,
} from '../../../types/tables';
import { ReactSVG } from 'react-svg';
import { Group } from '../../../schemas/Profile';
import CheckCircle from '../../../assets/CheckCircle.svg';
import ExclamationCircle from '../../../assets/ExclamationCircle.svg';
import Neutral from '../../../assets/Neutral.svg';
import i18n from '../../../translations';
import { useTheme } from '../../../providers/ThemeProvider';

export const useHomeHelpers = () => {
  const { theme } = useTheme();

  const transformForExport = (data: CustomTableColumns[]) => {
    const exportData = data as HomeTableColumns[];
    return exportData.map(row => {
      // remove the profile ID from the export
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { profileID, ...exportRow } = row;
      // adjust data to be more human readable-- a list of [columnKey, columnValue]
      const newRowValues = Object.entries({
        ...exportRow,
        group: getGroup(exportRow.group as Group),
        appLastOpened: transformStatus(exportRow.appLastOpened),
        sensorStatus: transformStatus(exportRow.sensorStatus),
        sensorUse: transformStatus(exportRow.sensorUse),
        gdmGoalSet: transformStatus(exportRow.gdmGoalSet),
        gdmGoalMet: transformStatus(exportRow.gdmGoalMet),
        daapGoalSet: transformStatus(exportRow.daapGoalSet),
        daapGoalMet: transformStatus(exportRow.daapGoalMet),
        hepStatus: transformStatus(exportRow.hepStatus),
      });

      // create the new object
      const finalExport: Record<string, string> = {};

      HOME_COLUMNS.forEach(({ column, headerName }) => {
        const columnValue = newRowValues
          .find(([columnKey]) => columnKey === column)
          ?.at(1);
        finalExport[headerName] = columnValue?.toString() || '';
      });

      return finalExport;
    });
  };

  const transformStatus = (status: TableColumnStatus) => {
    if (status === TableColumnStatus.GOOD) {
      return i18n.t('globals.status.good');
    } else if (status === TableColumnStatus.WARNING) {
      return i18n.t('globals.status.warning');
    } else if (status === TableColumnStatus.ALERT) {
      return i18n.t('globals.status.alert');
    } else if (status === TableColumnStatus.NEUTRAL) {
      return i18n.t('globals.status.na');
    } else {
      return i18n.t('globals.status.unknown');
    }
  };

  /**
   * Helper to set the color of the table cell based on the value
   * @param data The table value
   * @returns The color of the cell
   */
  const getTableStatusColor = (data: unknown) => {
    if (TableColumnStatus.ALERT === data) {
      return theme.colors.red;
    } else {
      return theme.colors.primary;
    }
  };

  /**
   * Transform the data in the table to an icon
   * @param data the data to transform in the table
   * @returns the icon to display
   */
  const getTableStausIcon = (data: TableColumnStatus) => {
    if (data === TableColumnStatus.NEUTRAL) {
      return (
        <ReactSVG
          src={Neutral}
          beforeInjection={svg => {
            svg.setAttribute('width', '1rem');
            svg.setAttribute('height', '1rem');
            return svg;
          }}
        />
      );
    }
    if (data === TableColumnStatus.GOOD) {
      return (
        <ReactSVG
          src={CheckCircle}
          beforeInjection={svg => {
            svg.setAttribute('color', theme.colors.green);
            svg.setAttribute('width', '1rem');
            svg.setAttribute('height', '1rem');
            return svg;
          }}
        />
      );
    }

    if (
      data === TableColumnStatus.ALERT ||
      data === TableColumnStatus.WARNING
    ) {
      return (
        <ReactSVG
          src={ExclamationCircle}
          beforeInjection={svg => {
            svg.setAttribute(
              'color',
              data === TableColumnStatus.ALERT
                ? theme.colors.red
                : theme.colors.yellow,
            );
            svg.setAttribute('width', '1rem');
            svg.setAttribute('height', '1rem');
            return svg;
          }}
        />
      );
    }
  };
  // count how many alert/ warning a subject has
  const statusCount = (
    subject: HomeTableColumns,
    status: TableColumnStatus,
  ) => {
    const count = (subjectStatus: TableColumnStatus) =>
      subjectStatus === status ? 1 : 0;
    return (
      count(subject.appLastOpened) +
      count(subject.sensorStatus) +
      count(subject.sensorUse) +
      count(subject.gdmGoalSet) +
      count(subject.gdmGoalMet) +
      count(subject.daapGoalMet) +
      count(subject.daapGoalSet) +
      count(subject.hepStatus)
    );
  };

  const getGroup = (data: Group) => {
    return i18n.t(`createSubject.group.${data}`);
  };

  /**
   * The style for the centered cells
   */
  const centeredCellStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '0.5em',
    marginRight: '0.5em',
  };

  const homeTableGroupPrefix = 'tables.home.columns';

  /**
   * The columns definiations for the home page.
   * This is the order of the columns in the table and any customizations
   */
  const HOME_COLUMNS: CustomColumnDefintion[] = [
    {
      column: 'subjectID',
      headerName: i18n.t(`${homeTableGroupPrefix}.subjectId`),
      cellLink: 'SUBJECT',
      headerStyle: centeredCellStyle,
      cellStyle: centeredCellStyle,
    },
    {
      column: 'redcapID',
      headerName: i18n.t(`${homeTableGroupPrefix}.redcapId`),
      headerStyle: centeredCellStyle,
      cellStyle: centeredCellStyle,
    },
    {
      column: 'primaryTherapist',
      headerName: i18n.t(`${homeTableGroupPrefix}.primaryTherapist`),
      headerStyle: centeredCellStyle,
      cellStyle: centeredCellStyle,
    },
    {
      column: 'secondaryTherapist',
      headerName: i18n.t(`${homeTableGroupPrefix}.secondaryTherapist`),
      headerStyle: centeredCellStyle,
      cellStyle: centeredCellStyle,
    },
    {
      column: 'group',
      headerName: i18n.t(`${homeTableGroupPrefix}.group`),
      headerStyle: centeredCellStyle,
      cellTransform: getGroup,
      cellStyle: centeredCellStyle,
    },
    {
      column: 'weekNumber',
      headerName: i18n.t(`${homeTableGroupPrefix}.weekNumber`),
      headerStyle: centeredCellStyle,
      cellStyle: centeredCellStyle,
    },
    {
      column: 'appLastOpened',
      headerName: i18n.t(`${homeTableGroupPrefix}.appLastOpened`),
      headerStyle: centeredCellStyle,
      cellTextColor: getTableStatusColor,
      cellTransform: getTableStausIcon,
      cellStyle: centeredCellStyle,
    },
    {
      column: 'sensorStatus',
      headerName: i18n.t(`${homeTableGroupPrefix}.sensorStatus`),
      headerStyle: centeredCellStyle,
      cellTextColor: getTableStatusColor,
      cellTransform: getTableStausIcon,
      cellStyle: centeredCellStyle,
    },
    {
      column: 'sensorUse',
      headerName: i18n.t(`${homeTableGroupPrefix}.sensorUse`),
      headerStyle: centeredCellStyle,
      cellTextColor: getTableStatusColor,
      cellTransform: getTableStausIcon,
      cellStyle: centeredCellStyle,
    },
    {
      column: 'gdmGoalSet',
      headerName: i18n.t(`${homeTableGroupPrefix}.gdmGoalSet`),
      headerStyle: centeredCellStyle,
      cellTextColor: getTableStatusColor,
      cellTransform: getTableStausIcon,
      cellStyle: centeredCellStyle,
    },
    {
      column: 'gdmGoalMet',
      headerName: i18n.t(`${homeTableGroupPrefix}.gdmGoalMet`),
      headerStyle: centeredCellStyle,
      cellTextColor: getTableStatusColor,
      cellTransform: getTableStausIcon,
      cellStyle: centeredCellStyle,
    },
    {
      column: 'daapGoalSet',
      headerStyle: centeredCellStyle,
      headerName: i18n.t(`${homeTableGroupPrefix}.daapGoalSet`),
      cellTransform: getTableStausIcon,
      cellStyle: centeredCellStyle,
    },
    {
      column: 'daapGoalMet',
      headerName: i18n.t(`${homeTableGroupPrefix}.daapGoalMet`),
      headerStyle: centeredCellStyle,
      cellTextColor: getTableStatusColor,
      cellTransform: getTableStausIcon,
      cellStyle: centeredCellStyle,
    },
    {
      column: 'hepStatus',
      headerName: i18n.t(`${homeTableGroupPrefix}.hepStatus`),
      headerStyle: centeredCellStyle,
      cellTextColor: getTableStatusColor,
      cellTransform: getTableStausIcon,
      cellStyle: centeredCellStyle,
    },
  ];

  return {
    HOME_COLUMNS,
    transformForExport,
    statusCount,
  };
};
