import {
  ThemeContent,
  useThemedComponent,
  StyleSheet,
} from '../../../providers/ThemeProvider';
import i18n from '../../../translations';

interface GDMGraphToggleProps {
  gdmGraphSelection: number;
  setGdmGraphSelection: (val: number) => void;
}

const GDMGraphToggle = ({
  gdmGraphSelection,
  setGdmGraphSelection,
}: GDMGraphToggleProps) => {
  const { styles } = useThemedComponent(GDMGraphToggleStyles);
  return (
    <div style={styles.gdmButtonContainer}>
      <button
        style={{
          ...styles.gdmButton,
          ...(gdmGraphSelection === 0 ? styles.gdmButtonSelected : {}),
        }}
        onClick={() => setGdmGraphSelection(0)}>
        {i18n.t('subject.bothButton')}
      </button>
      <button
        style={{
          ...styles.gdmButton,
          ...(gdmGraphSelection === 1 ? styles.gdmButtonSelected : {}),
        }}
        onClick={() => setGdmGraphSelection(1)}>
        {' '}
        {i18n.t('subject.affectedButton')}{' '}
      </button>
    </div>
  );
};

const GDMGraphToggleStyles = (theme: ThemeContent): StyleSheet => ({
  gdmButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0.08rem',
    backgroundColor: theme.colors.transparentGrey,
    borderRadius: 5,
    marginLeft: '-10%',
    marginRight: '10%',
  },
  gdmButton: {
    backgroundColor: 'transparent',
    borderStyle: 'none',
    padding: '0.5rem 1rem',
    borderRadius: 5,
  },
  gdmButtonSelected: {
    backgroundColor: theme.colors.white,
  },
});

export default GDMGraphToggle;
