import React, { ReactNode, forwardRef } from 'react';
import {
  useThemedComponent,
  StyleSheet,
} from '../../../providers/ThemeProvider';
import Expand from '../../../assets/Expand.svg';
import { ReactSVG } from 'react-svg';
import Tile from '../Tile/Tile';

interface ExpansionTileProps {
  onExpand: () => void | Promise<void>;
  title: string;
  titleStyle: React.CSSProperties;
  children: ReactNode;
  style?: React.CSSProperties;
  rightOfTitleItems?: ReactNode;
  leftOfExpandItems?: ReactNode;
}
/**
 * Generic Tile that can be expanded, see examples in the Subject page
 * @param props ExpansionTileProps
 * @returns The tile template
 */
const ExpansionTile = forwardRef((props: ExpansionTileProps, ref) => {
  const defaultTitleFontSize = '1em';

  const getStyleDemension = (raw: number | string | undefined): string => {
    if (raw === undefined) {
      return defaultTitleFontSize;
    } else if (typeof raw === 'number') {
      return `${raw}px`;
    } else {
      return raw;
    }
  };

  const { styles, theme } = useThemedComponent([ExpansionTileStyles]);
  return (
    <Tile
      style={{ ...props.style }}
      ref={ref as React.RefObject<HTMLElement> | undefined}>
      <section style={styles.header}>
        <div style={styles.row}>
          <h1 style={{ ...props.titleStyle }}>{props.title}</h1>
          {props.rightOfTitleItems}
        </div>
        <div style={styles.row}>
          {props.leftOfExpandItems}
          <button onClick={props.onExpand} style={styles.expandButton}>
            <ReactSVG
              src={`${Expand}`}
              beforeInjection={svg => {
                svg.setAttribute('stroke', theme.colors.primary);
                svg.setAttribute(
                  'width',
                  getStyleDemension(props.titleStyle.fontSize),
                );
                svg.setAttribute(
                  'height',
                  getStyleDemension(props.titleStyle.fontSize),
                );
                return svg;
              }}
            />
          </button>
        </div>
      </section>
      {props.children}
    </Tile>
  );
});

ExpansionTile.displayName = 'ExpansionTile'; // Add display name to the component

export default ExpansionTile;

const ExpansionTileStyles = (): StyleSheet => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  expandButton: {
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    padding: '0',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
