import {
  type ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

export default (theme: ThemeContent): StyleSheet => ({
  searchBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0.5em 1em',
    backgroundColor: theme.colors.gray,
    borderRadius: '0.5em',
  },
  input: {
    marginLeft: '0.5em',
    border: 'none',
    backgroundColor: 'transparent',
    color: theme.colors.primary,
  },
});
