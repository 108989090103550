import React, { createContext, useContext, useEffect } from 'react';
import { useNavigation } from 'react-router-dom';
import { useLoading } from './LoadingProvider';
import { router } from '../router';

// based on react-router-dom type NavigationStates
type NavigationStates = 'loading' | 'idle' | 'submitting';

/**
 * Wether or not the app is loading. Based on react-router-dom state
 */
interface RouterLoadingContextContent {
  navigationState: NavigationStates;
}

const RouterLoadingContext = createContext<RouterLoadingContextContent>(
  undefined!,
);

/**
 * This provider wraps the children with the RouterLoadingContext
 * and sets the loading state based on the react-router-dom state
 * It pushes the loading state to the LoadingProvider
 *
 * @param param0 children to wrap
 * @returns children wrapped with RouterLoadingContext
 */
const RouterLoadingProvider = ({ children }: { children: React.ReactNode }) => {
  const { state } = useNavigation();
  const { setLoading } = useLoading();

  useEffect(() => {
    // If the router is initialized, set the loading state based on the router state
    // If the router is not initialized, set the loading state to false (we are refreshing the page)
    setLoading(
      router.state.initialized
        ? state === 'loading' || state === 'submitting'
        : false,
    );
  }, [state, setLoading]);

  return (
    <RouterLoadingContext.Provider value={{ navigationState: state }}>
      {children}
    </RouterLoadingContext.Provider>
  );
};
const useRouterLoading = () => {
  return useContext(RouterLoadingContext);
};

export { useRouterLoading, RouterLoadingProvider };
