import {
  type ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: ThemeContent): StyleSheet => ({
  weeklyContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 15,
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: '1rem',
  },
  h1: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  complianceGraphTile: {
    width: '100%',
    paddingLeft: '3%',
    borderRadius: '2%',
    height: '100%',
  },
});
