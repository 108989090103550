import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { useTableColumns } from '../../../hooks/useTableColumns';
import {
  DateRangeOption,
  SensorWearHoursDailyColumns,
  SensorWearHoursWeeklyColumns,
  SortOrder,
  SubjectSummaryTableColumns,
} from '../../../types/tables';
import { useLoading } from '../../../providers/LoadingProvider';
import i18n from '../../../translations';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import CompliancePageStyles from './CompliancePageStyles';
import Table from '../../../components/templates/Table/Table';
import DatePaginationAndFilter from '../../../components/organisms/DatePaginationAndFilter/DatePaginationAndFilter';
import { makeDateRangeOptions } from '../../../utils/tables';
import {
  DAILY_COMPLIANCE_COLUMNS,
  SUBJECT_SUMMARY_COLUMNS,
  WEEKLY_COMPLIANCE_COLUMNS,
  transformDailyComplianceDataForExport,
  transformWeeklyComplianceDataForExport,
} from './helpers';
import { commonTableStyles } from '../../../components/templates/Table/tableUtil';
import { useCloudContext } from '../../../providers/CloudProvider';
import { DEFAULT_TIMEZONE, dateToISOString } from '../../../utils/dateTime';
import ComplianceBarGraph from '../../../components/organisms/ComplianceBarGraph/ComplianceBarGraph';
import ExpansionTile from '../../../components/templates/ExpansionTile/ExpansionTile';
import { ROUTES } from '../../../router';
import { useSubjectHelpers } from '../Subject/useSubjectHelpers';
import { DateTime } from 'luxon';
import { GDMInfo } from '../../../schemas/GDMInfo';
import { useLogging } from '../../../providers/LoggingProvider';
export interface SubjectParams {
  subjectId: string;
}
interface SubjectDataResponse {
  subjectId: string;
}

export const loader = async ({
  params,
}: LoaderFunctionArgs<SubjectParams>): Promise<SubjectDataResponse | Error> => {
  try {
    if (!params.subjectId) {
      return new Error('Subject ID is required');
    }
    return {
      subjectId: params.subjectId,
    };
  } catch (e) {
    console.error('Error fetching Compliance page data', e);
    return new Error('Error fetching Compliance page data');
  }
};

export default function Compliance() {
  const pageData = useLoaderData() as SubjectDataResponse;
  const { setLoading } = useLoading();
  const { styles } = useThemedComponent([
    commonTableStyles,
    CompliancePageStyles,
  ]);
  const { cloudService, profileService, movementService } = useCloudContext();
  const navigate = useNavigate();
  const logger = useLogging(Compliance.name);

  const [
    complianceTablesDateRangeOptions,
    setComplianceTablesDateRangeOptions,
  ] = useState<DateRangeOption[]>();

  const [timezone, setTimezone] = useState<string>(DEFAULT_TIMEZONE);
  const [selectedWeeklyDateRangeIndex, setSelectedWeeklyDateRangeIndex] =
    useState<number | undefined>();

  const [dailyComplianceDateRangeOptions, setDailyComplianceDateRangeOptions] =
    useState<Date[]>();
  const [selectedDailyWeekDateRangeIndex, setSelectedDailyWeekDateRangeIndex] =
    useState<number | undefined>();
  const [selectedDailyDateRangeIndex, setSelectedDailyDateRangeIndex] =
    useState<number | undefined>(0);

  const cloudContext = useCloudContext();
  const complianceRef = React.useRef();
  const [gdmInfoData, setGdmInfoData] = useState<GDMInfo[]>([]);
  const { gdmInfoBasedGraphQuery } = useSubjectHelpers({
    cloudContext,
    subjectId: pageData.subjectId,
  });

  // table stuff
  const {
    columnHelper: subjectSummarySolumnHelper,
    tableData: subjectSummaryTableData,
    setTableData: setSubjectSummaryTableData,
    sorting: subjectSummarySorting,
  } = useTableColumns<SubjectSummaryTableColumns>();

  const {
    columnHelper: weeklyComplianceColumnHelper,
    tableData: weeklyComplianceTableData,
    setTableData: setWeeklyComplianceTableData,
    ExportDropDown: WeeklyComplianceExportDropDown,
    sorting: weeklyComplianceSorting,
    setSorting: setWeeklyComplianceSorting,
  } = useTableColumns<SensorWearHoursWeeklyColumns>({
    defaultSorting: [{ colKey: 'date', index: 1, order: SortOrder.DESC }],
    tableName: i18n.t('tables.sensorWearHoursWeekly.title'),
    transformExportData: transformWeeklyComplianceDataForExport,
  });

  const {
    columnHelper: dailyComplianceColumnHelper,
    tableData: dailyComplianceTableData,
    setTableData: setDailyComplianceTableData,
    ExportDropDown: DailyComplianceExportDropDown,
    sorting: dailyComplianceSorting,
    setSorting: setDailyComplianceSorting,
  } = useTableColumns<SensorWearHoursWeeklyColumns>({
    defaultSorting: [{ colKey: 'date', index: 1, order: SortOrder.DESC }],
    tableName: i18n.t('tables.sensorWearHoursDaily.title'),
    transformExportData: transformDailyComplianceDataForExport,
  });

  const startDate: DateTime = useMemo(() => {
    let date: DateTime;
    if (
      complianceTablesDateRangeOptions !== undefined &&
      selectedWeeklyDateRangeIndex !== undefined
    ) {
      const dateJS =
        complianceTablesDateRangeOptions[selectedWeeklyDateRangeIndex]
          .startDate;
      date = DateTime.fromJSDate(dateJS).setZone(timezone, {
        keepLocalTime: true,
      });
    } else {
      date = DateTime.now().setZone(timezone);
    }
    return date;
  }, [
    timezone,
    complianceTablesDateRangeOptions,
    selectedWeeklyDateRangeIndex,
  ]);

  const subjectSummaryFetcher = useCallback(async () => {
    // get the subject summary data
    const subjectSummaryRes = await cloudService.requestSubjectSummary({
      subjectId: pageData.subjectId,
      sort: subjectSummarySorting,
    });
    const newSubjectSummaryData: SubjectSummaryTableColumns[] =
      subjectSummaryRes.data;
    setSubjectSummaryTableData(newSubjectSummaryData);
  }, [
    cloudService,
    pageData.subjectId,
    setSubjectSummaryTableData,
    subjectSummarySorting,
  ]);

  const weeklyDataFetcher = useCallback(async () => {
    try {
      // get the wear information data if we have a date range
      if (
        selectedWeeklyDateRangeIndex !== undefined &&
        complianceTablesDateRangeOptions
      ) {
        const { startDate, endDate } =
          complianceTablesDateRangeOptions[selectedWeeklyDateRangeIndex];
        const startDateISO = dateToISOString(startDate, timezone);
        const endDateISO = dateToISOString(endDate, timezone);
        const start = DateTime.fromJSDate(startDate);
        const end = DateTime.fromJSDate(endDate);
        const gdmInfoData = await gdmInfoBasedGraphQuery(start, end);
        setGdmInfoData(gdmInfoData);

        const weeklyWearRes = await cloudService.requestSensorWearWeeklyTable({
          subjectId: pageData.subjectId,
          startDateISO,
          endDateISO,
          sort: weeklyComplianceSorting,
        });

        const newWeeklyComplianceData: SensorWearHoursWeeklyColumns[] =
          weeklyWearRes.data;
        setWeeklyComplianceTableData(newWeeklyComplianceData);
      }
    } catch (e) {
      logger.error('Error fetching weekly wear information tables!', e);
    } finally {
      setLoading(false);
    }
  }, [
    selectedWeeklyDateRangeIndex,
    complianceTablesDateRangeOptions,
    timezone,
    gdmInfoBasedGraphQuery,
    cloudService,
    pageData.subjectId,
    weeklyComplianceSorting,
    setWeeklyComplianceTableData,
    logger,
    setLoading,
  ]);

  // Set the selectedDailyDateRangeIndex to 0 to start
  useEffect(() => {
    if (
      complianceTablesDateRangeOptions &&
      complianceTablesDateRangeOptions.length > 0 &&
      selectedDailyWeekDateRangeIndex === undefined
    ) {
      setSelectedDailyWeekDateRangeIndex(0);
      return;
    }
  }, [selectedDailyWeekDateRangeIndex, complianceTablesDateRangeOptions]);

  // Once selectedDailyWeekDateRangeIndex is set
  // generate the daily date range options to display
  useEffect(() => {
    if (
      complianceTablesDateRangeOptions &&
      complianceTablesDateRangeOptions.length > 0 &&
      selectedDailyWeekDateRangeIndex !== undefined
    ) {
      // make the daily date range options
      const { startDate } =
        complianceTablesDateRangeOptions[selectedDailyWeekDateRangeIndex];
      // get each date between the start and end date
      const dailyOptions = Array.from({ length: 7 }, (_, i) => {
        const day = new Date(startDate);
        day.setDate(day.getDate() + i);
        return day;
      }).filter(
        // filter out dates in the future
        d =>
          DateTime.fromJSDate(d).startOf('day') <=
          DateTime.now().startOf('day'),
      );
      setDailyComplianceDateRangeOptions(dailyOptions.reverse());
      return;
    }
  }, [
    selectedDailyDateRangeIndex,
    selectedDailyWeekDateRangeIndex,
    complianceTablesDateRangeOptions,
  ]);

  const dailyDataFetcher = useCallback(async () => {
    try {
      setLoading(true);
      // get the wear information data if we have a date range
      if (
        selectedDailyDateRangeIndex !== undefined &&
        dailyComplianceDateRangeOptions
      ) {
        const date =
          dailyComplianceDateRangeOptions[selectedDailyDateRangeIndex];
        const dateISO = dateToISOString(date, timezone);
        const dailyWearRes = await cloudService.requestSensorWearDailyTable({
          subjectId: pageData.subjectId,
          dateISO,
          sort: dailyComplianceSorting,
        });
        const newDailyComplianceData: SensorWearHoursDailyColumns[] =
          dailyWearRes.data;
        setDailyComplianceTableData(newDailyComplianceData);
      }
    } catch (e) {
      logger.error('Error fetching daily compliance data', e);
    } finally {
      setLoading(false);
    }
  }, [
    setLoading,
    selectedDailyDateRangeIndex,
    dailyComplianceDateRangeOptions,
    timezone,
    cloudService,
    pageData.subjectId,
    dailyComplianceSorting,
    setDailyComplianceTableData,
    logger,
  ]);

  // If selectedDailyDateRangeIndex is undefined, set it to 0
  // Else fetch the daily data
  useEffect(() => {
    if (
      dailyComplianceDateRangeOptions &&
      dailyComplianceDateRangeOptions.length > 0 &&
      selectedDailyDateRangeIndex === undefined
    ) {
      setSelectedDailyDateRangeIndex(0);
      return;
    }
    dailyDataFetcher().catch(e => {
      logger.error(e);
    });
  }, [
    selectedDailyDateRangeIndex,
    dailyComplianceDateRangeOptions,
    dailyComplianceSorting,
    logger,
    dailyDataFetcher,
  ]);

  // didMount: get the date range options and fetch data for summary table
  useEffect(() => {
    const generateDateRangeOptions = async () => {
      const profile = await profileService.getProfileBySubjectId(
        pageData.subjectId,
      );
      if (!profile) {
        throw new Error('Profile not found');
      }
      setTimezone(profile.attributes.timezone ?? timezone);
      const firstMovement = await movementService.getFirstMovement(profile);

      if (!firstMovement) {
        logger.warn('No movements found for subject');
        return;
      }

      const options = makeDateRangeOptions(
        firstMovement.get('startTime'),
        profile.get('deactivatedAt') ?? new Date(),
      );
      setComplianceTablesDateRangeOptions(options.reverse());
    };
    try {
      setLoading(true);
      subjectSummaryFetcher();
      generateDateRangeOptions();
    } catch (e) {
      logger.error(
        'Error generating date range options or fetching summary table',
        e,
      );
    } finally {
      setLoading(false);
    }
  }, [
    logger,
    movementService,
    pageData.subjectId,
    profileService,
    setLoading,
    subjectSummaryFetcher,
    timezone,
  ]);

  // fetch the data when the selected date range changes for weekly compliance
  useEffect(() => {
    // if no date range options, select the first one and return
    // this will retrigger the useEffect
    if (
      complianceTablesDateRangeOptions &&
      complianceTablesDateRangeOptions.length > 0 &&
      selectedWeeklyDateRangeIndex === undefined
    ) {
      setSelectedWeeklyDateRangeIndex(0);
      return;
    }
    weeklyDataFetcher().catch(e => {
      logger.error(e);
    });
  }, [
    complianceTablesDateRangeOptions,
    selectedDailyDateRangeIndex,
    selectedWeeklyDateRangeIndex,
    weeklyComplianceSorting,
    logger,
    weeklyDataFetcher,
  ]);

  return (
    <div style={styles.pageContainer}>
      <div style={styles.pageHeader}>
        <h1 style={styles.h1}>
          {i18n.t('subject.title', { subjectId: pageData.subjectId })}
        </h1>
      </div>
      <div style={styles.tableSectionContainer}>
        <div style={{ ...styles.rowContainer, ...styles.tableTopRow }}>
          <h2 style={styles.h2}>{i18n.t('tables.subjectSummary.title')}</h2>
        </div>
        <Table
          columnHelper={subjectSummarySolumnHelper}
          columns={SUBJECT_SUMMARY_COLUMNS}
          data={subjectSummaryTableData}
        />
      </div>
      <div style={{ ...styles.rowContainer, ...styles.tableSectionContainer }}>
        <div style={{ ...styles.rowContainer, ...styles.tableTopRow }}>
          <h2 style={styles.h2}>
            {i18n.t('tables.sensorWearHoursWeekly.title')}
          </h2>
          <div style={styles.btnGroup}>
            <DatePaginationAndFilter
              labels={complianceTablesDateRangeOptions?.map(
                option => option.asString,
              )}
              options={complianceTablesDateRangeOptions}
              selectedOptionIndex={selectedWeeklyDateRangeIndex}
              setSelectedOptionIndex={setSelectedWeeklyDateRangeIndex}
            />
            <WeeklyComplianceExportDropDown
              style={{ ...styles.rightButton, ...styles.exportButton }}
            />
          </div>
        </div>
        <div style={{ ...styles.weeklyContainer }}>
          <ExpansionTile
            onExpand={() =>
              navigate(
                ROUTES.COMPLIANCE.buildPath({ subjectId: pageData.subjectId }),
              )
            }
            title={''}
            titleStyle={styles.graphTitle}
            style={styles.complianceGraphTile}
            ref={complianceRef}>
            <ComplianceBarGraph
              gdmInfoData={gdmInfoData}
              containerRef={
                complianceRef as unknown as MutableRefObject<HTMLDivElement | null>
              }
              startDate={startDate}
              subjectId={pageData.subjectId}
            />
          </ExpansionTile>
          <Table
            sorting={weeklyComplianceSorting}
            setSorting={setWeeklyComplianceSorting}
            columnHelper={weeklyComplianceColumnHelper}
            columns={WEEKLY_COMPLIANCE_COLUMNS}
            data={weeklyComplianceTableData}
          />
        </div>
      </div>
      <div
        style={{
          ...styles.rowContainer,
          ...styles.tableSectionContainer,
          ...styles.lastRow,
        }}>
        <div style={{ ...styles.rowContainer, ...styles.tableTopRow }}>
          <h2 style={styles.h2}>
            {i18n.t('tables.sensorWearHoursDaily.title')}
          </h2>
          <div style={styles.btnGroup}>
            <DatePaginationAndFilter
              labels={complianceTablesDateRangeOptions?.map(
                option => option.asString,
              )}
              options={complianceTablesDateRangeOptions}
              selectedOptionIndex={selectedDailyWeekDateRangeIndex}
              setSelectedOptionIndex={setSelectedDailyWeekDateRangeIndex}
            />
            <DatePaginationAndFilter
              labels={dailyComplianceDateRangeOptions?.map(option =>
                option.toLocaleDateString(),
              )}
              options={dailyComplianceDateRangeOptions}
              selectedOptionIndex={selectedDailyDateRangeIndex}
              setSelectedOptionIndex={setSelectedDailyDateRangeIndex}
              pretext={i18n.t('chooseADay')}
            />
            <DailyComplianceExportDropDown
              style={{ ...styles.rightButton, ...styles.exportButton }}
            />
          </div>
        </div>
        <Table
          sorting={dailyComplianceSorting}
          setSorting={setDailyComplianceSorting}
          columnHelper={dailyComplianceColumnHelper}
          columns={DAILY_COMPLIANCE_COLUMNS}
          data={dailyComplianceTableData}
        />
      </div>
    </div>
  );
}
