import {
  ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

export default (theme: ThemeContent): StyleSheet => ({
  topContainer: {
    marginTop: '2vmin',
    width: '45%',
  },
  btn: {
    justifyContent: 'center',
    borderRadius: 5,
    borderStyle: 'none',
    padding: '2%',
    height: '100%',
    width: '100%',
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
  },
});
