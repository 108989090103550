import React from 'react';
import Parse from 'parse';
import Logo from '../../../assets/Logo.svg';
import LoginStyles from './LoginStyles';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import { ReactSVG } from 'react-svg';
import Google__G__Logo from '../../../assets/Google__G__Logo.svg';
import MS_symbollockup_mssymbol_19 from '../../../assets/ms-symbollockup_mssymbol_19.svg';
import '../../../pseudoElementsOverride.css';
import { useAuth } from '../../../providers/AuthProvider';
import i18n from '../../../translations';
import { useLogging } from '../../../providers/LoggingProvider';

export default function Login() {
  const { devLogin, getRedirectUri } = useAuth();
  const { styles, theme } = useThemedComponent([LoginStyles]);

  const [formLogin] = React.useState(process.env.REACT_APP_DEV_LOGIN ?? false);

  const [email, setEmail] = React.useState(
    process.env.REACT_APP_USERNAME ?? '',
  );
  const [password, setPassword] = React.useState(
    process.env.REACT_APP_PASSWORD ?? '',
  );

  const logger = useLogging(Login.name);

  return (
    <main style={{ ...styles.main }}>
      <ReactSVG
        src={`${Logo}`}
        beforeInjection={svg => {
          svg.setAttribute('fill', theme.colors.primary);
          svg.setAttribute('width', '249px');
          svg.setAttribute('height', '88px');
          return svg;
        }}
        style={styles.logo}
      />
      {formLogin && (
        <>
          <form style={styles.form} data-cy="login-form">
            <fieldset style={styles.loginSet}>
              <legend className="visuallyHidden">Login Fields</legend>
              <label
                htmlFor="email"
                data-cy="login-email-label"
                className="visuallyHidden">
                Email address
              </label>
              <input
                id="email"
                data-cy="login-email-input"
                name="email"
                type="email"
                autoComplete="email"
                required
                style={{
                  ...styles.input,
                  ...styles.inputTop,
                }}
                placeholder={'Email'}
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                }}
              />

              <label
                htmlFor="password"
                data-cy="login-password-label"
                className="visuallyHidden">
                Password
              </label>
              <input
                id="password"
                data-cy="login-password-input"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                style={{ ...styles.input, ...styles.inputBottom }}
                placeholder={'Password'}
                value={password}
                onChange={e => {
                  setPassword(e.target.value);
                }}
              />
            </fieldset>

            {/* TODO: Rememeber me  */}

            {/* TODO: Forgot Password  */}

            <button
              style={{ ...styles.loginButton }}
              data-cy="login-signin-btn"
              type="button"
              onClick={async () => {
                await devLogin(email, password);
              }}>
              Log in
            </button>
          </form>

          <div style={styles.dividerContainer}>
            <div style={styles.divider}></div>
            <span>Or continue with</span>
            <div style={styles.divider}></div>
          </div>
        </>
      )}

      <div style={styles.ssoContainer}>
        <button
          style={styles.ssoButton}
          onClick={async () => {
            // run cloud function to get the google auth url
            const url = await Parse.Cloud.run('GoogleOAuthRequestURL', {
              // eslint-disable-next-line max-len
              redirectUri: getRedirectUri('google'),
            });
            if (!url) {
              logger.error('Error getting Google OAuth URL');
              return;
            }
            // open the google auth url
            window.open((url as string) || '', '_self');
          }}>
          <img
            src={Google__G__Logo}
            data-linktype="relative-path"
            alt="Google Logo"
            style={styles.ssoIcon}
          />
          <span>
            {i18n.t('signInWithProvider', {
              provider: 'Google',
            })}
          </span>
        </button>
        <button
          style={styles.ssoButton}
          onClick={async () => {
            // run cloud function to get the microsoft auth url
            const url = await Parse.Cloud.run('MicrosoftOAuthRequestURL', {
              redirectUri: getRedirectUri('microsoft'),
            });
            if (!url) {
              logger.error('Error getting Microsoft OAuth URL');
              return;
            }
            // open the microsoft auth url
            window.open((url as string) || '', '_self');
          }}>
          <img
            src={MS_symbollockup_mssymbol_19}
            data-linktype="relative-path"
            alt="Microsoft Logo"
            style={styles.ssoIcon}
          />
          <span>
            {i18n.t('signInWithProvider', {
              provider: 'Microsoft',
            })}
          </span>
        </button>
      </div>
    </main>
  );
}
