import { useCallback } from 'react';
import Parse from '../parse';

import { useAuth } from '../providers/AuthProvider';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { PORTAL_RELATIVE_URL, ROUTES } from '../router';
import {
  SEARCH_PARAM_FROM_PATH,
  SEARCH_PARAM_TIMESTAMP,
} from '../pages/unauthorized/TooManyRequests/TooManyRequests';
import { useLogging } from '../providers/LoggingProvider';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useErrorHandling<T extends (...args: any[]) => unknown>(
  callback: T,
  deps: unknown[],
): T {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const logger = useLogging(`${useErrorHandling.name}-${callback.name}`);

  const memoizedCallback = useCallback(
    async (...args: Parameters<T>) => {
      try {
        return await callback(...args);
      } catch (e) {
        if (
          e instanceof Parse.Error &&
          (e.code === Parse.Error.INVALID_SESSION_TOKEN ||
            e.code === Parse.Error.INVALID_LINKED_SESSION)
        ) {
          await logout();
          return await callback(...args);
        }
        if (
          e instanceof Parse.Error &&
          e.code === Parse.Error.CONNECTION_FAILED &&
          window.location.pathname !==
            PORTAL_RELATIVE_URL + ROUTES.ERROR_TOO_MANY_REQUESTS.path
        ) {
          logger.log('Too many requests error: navigating to 429 page');
          return navigate({
            pathname: ROUTES.ERROR_TOO_MANY_REQUESTS.path,
            search: createSearchParams([
              [SEARCH_PARAM_FROM_PATH, window.location.pathname],
              [SEARCH_PARAM_TIMESTAMP, Date.now().toString()],
            ]).toString(),
          });
        }
        throw e;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logout, ...deps],
  );

  return memoizedCallback as T;
}
