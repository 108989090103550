import {
  type ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

const inputBorderRandius = '0.5em';
const inputPadding = 0.8;
const formWidth = '28%';

export default (theme: ThemeContent): StyleSheet => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: theme.colors.background,
  },
  logo: {
    margin: '0 0 2%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: formWidth,
  },
  input: {
    padding: `${inputPadding}em`,
    border: `1px solid ${theme.colors.coolGrey}`,
    color: theme.colors.primary,
    width: `calc(100% - ${inputPadding * 2}em)`,
  },
  inputTop: {
    borderTopRightRadius: inputBorderRandius,
    borderTopLeftRadius: inputBorderRandius,
  },
  inputBottom: {
    borderBottomRightRadius: inputBorderRandius,
    borderBottomLeftRadius: inputBorderRandius,
  },
  loginSet: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 0 2.5%',
    width: '100%',
  },
  loginButton: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    borderRadius: inputBorderRandius,
    border: 'none',
    padding: `${inputPadding}em`,
    width: '100%',
    fontWeight: 500,
  },
  dividerContainer: {
    width: formWidth,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.colors.softBlue,
    margin: '2.5% 0',
  },
  divider: {
    width: 'auto',
    minWidth: '30%',
    height: '1px',
    backgroundColor: theme.colors.softBlue,
  },
  ssoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: formWidth,
  },
  ssoButton: {
    backgroundColor: theme.colors.white,
    color: theme.colors.primary,
    fontWeight: 500,
    borderRadius: inputBorderRandius,
    border: `1px solid ${theme.colors.coolGrey}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: `${inputPadding}em`,
    margin: '1.5% 0',
  },
  ssoIcon: {
    marginRight: '0.5em',
    width: '1rem',
    height: '1rem',
  },
});
