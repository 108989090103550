import { useState } from 'react';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import GDMCountsGraphStyles from './GDMCountsGraphStyles';
import { DateTime } from 'luxon';
interface GDMCountsGraphProps {
  data: {
    date: DateTime;
    count: number;
  }[];
}

const GDMCountsGraph = (props: GDMCountsGraphProps) => {
  const { styles } = useThemedComponent([GDMCountsGraphStyles]);
  const [data] = useState(props.data);

  return (
    <div style={styles.container}>
      <div style={styles.entry}>
        {data.map(({ date }, i) => (
          <div key={i} style={styles.dateContainer}>
            <p style={styles.pDay}>
              {date.toLocaleString({ weekday: 'short' })}
            </p>
            <p style={styles.pDate}>
              {date.toLocaleString({ month: 'short', day: '2-digit' })}
            </p>
          </div>
        ))}
      </div>
      <div style={{ ...styles.entry, ...styles.linearGradient }}>
        {data.map(({ count }, i) => (
          <div
            key={i}
            style={{
              ...styles.countBox,
              ...(i > 0 && i < 4 ? styles.green : {}),
            }}>
            <p style={styles.pCount}>{count}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GDMCountsGraph;
