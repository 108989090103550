import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { CustomTableColumns, DateRangeOption } from '../types/tables';
import { TableLink } from '../types/tables';
/**
 * Download the table as a csv or xlsx file
 * @param xlsxFormart true for xlsx, false for csv
 */
export const getCsv = async (
  data: unknown[],
  tableTitle: string,
  xlsxFormat = false,
) => {
  let blob;
  if (xlsxFormat) {
    const wb = XLSX.utils.book_new();
    wb.SheetNames.push('Sheet1');
    wb.Sheets.Sheet1 = XLSX.utils.json_to_sheet(data);
    blob = new Blob([XLSX.write(wb, { bookType: 'xlsx', type: 'array' })], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
  } else {
    const csv = Papa.unparse(data);
    blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  }
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', tableTitle + (xlsxFormat ? '.xlsx' : '.csv'));
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

/**
 *
 * @param row The row of data to generate the date range for
 * @param dateColumn the column that contains the date
 * @returns
 */
export const generateDateRange = <T>(
  row: CustomTableColumns,
  dateColumn: keyof T,
): DateRangeOption | undefined => {
  const date = row[dateColumn as keyof CustomTableColumns];
  if (date === undefined) {
    return;
  }
  // get the date of the row
  const startDate = new Date(date.toString() as string);
  // set the date to the start of the week
  startDate.setDate(startDate.getDate() - startDate.getDay());
  // set the end date to the end of the week
  const endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + 6);

  // return the in the format like Feb 11, 2024 - Feb 17, 2024
  return {
    asString: `${startDate.toLocaleString('default', {
      month: 'short',
    })} ${startDate.getDate()}, ${startDate.getFullYear()} - ${endDate.toLocaleString(
      'default',
      {
        month: 'short',
      },
    )} ${endDate.getDate()}, ${endDate.getFullYear()}`,
    startDate,
    endDate,
  };
};

export const generateDateFilterOptions = <T extends CustomTableColumns>(
  data: CustomTableColumns[],
  dateColumn: keyof T,
) => {
  return Array.from(
    new Set(
      data
        .map(row => row[dateColumn as keyof CustomTableColumns] as string)
        .filter(row => row !== undefined),
    ), // already filter out undefined data
  ).sort((a, b) => {
    const dateA = new Date(a.split(' - ')[0]);
    const dateB = new Date(b.split(' - ')[0]);
    return dateA > dateB ? -1 : 1;
  });
};

export const generateDateFilterRangeOptions = <T extends CustomTableColumns>(
  data: CustomTableColumns[],
  dateColumn: keyof T,
) => {
  return Array.from(
    new Set(
      data
        .map(row => generateDateRange(row, dateColumn))
        .filter(
          (
            dateRange: DateRangeOption | undefined,
          ): dateRange is DateRangeOption => dateRange !== undefined,
        ),
    ),
  ).sort((a, b) => (a.startDate > b.startDate ? -1 : 1));
};

/**
 * Make date range options. It will make weeks (sunday - saturday) from the start date to the end date.
 * The order of the options is from the start date to the end date (ascending)
 * @param startDate the start date
 * @param endDate the end date
 */
export const makeDateRangeOptions = (
  startDate: Date,
  endDate: Date,
): DateRangeOption[] => {
  const options: DateRangeOption[] = [];
  // set the current date to the start date
  const currentDate = new Date(startDate);
  // Add remaining days to the end of the week, so the last week is a full week
  const adjustedEndDate = new Date(endDate);
  adjustedEndDate.setDate(adjustedEndDate.getDate() + (6 - endDate.getDay()));
  // loop through the dates until the end date
  while (currentDate <= adjustedEndDate) {
    // set the start date to the start of the week
    const startDate = new Date(currentDate);
    startDate.setDate(startDate.getDate() - startDate.getDay());
    startDate.setHours(0, 0, 0, 0);
    // set the end date to the end of the week
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);
    endDate.setHours(23, 59, 59, 999);
    options.push({
      asString: `${startDate.toLocaleString('default', {
        month: 'short',
      })} ${startDate.getDate()}, ${startDate.getFullYear()} - ${endDate.toLocaleString(
        'default',
        {
          month: 'short',
        },
      )} ${endDate.getDate()}, ${endDate.getFullYear()}`,
      startDate,
      endDate,
    });
    // move to the next week
    currentDate.setDate(currentDate.getDate() + 7);
  }
  return options;
};

const isTableLink = (cell: unknown): cell is TableLink<unknown> => {
  return (
    (cell as TableLink<unknown>).params !== undefined &&
    (cell as TableLink<unknown>).value !== undefined
  );
};

/**
 * Converts an unknown typed value to string
 */
const convertToString = (value: unknown): string => {
  const valType = typeof value;

  if (valType === 'string') {
    return value as string;
  }

  if (valType === 'number' || valType === 'boolean') {
    return (value as number | boolean).toString();
  }

  if (value instanceof Date) {
    return value.toLocaleString();
  }
  if (valType === 'object' || Array.isArray(value)) {
    // if value is a table link
    if (isTableLink(value)) {
      // recursive
      return convertToString(value.value);
    }
    // ...put more object type here if needed (eg. table has a non-primitive typed column)
    return JSON.stringify(value);
  }

  return '';
};

/**
 * Quick helper function to convert a row of data to a string array for exporting
 * @param row A row of data to convert to a string array
 * @returns All the values of the row as strings
 */
export const rowToExportString = <T extends CustomTableColumns>(
  row: T,
): string[] => {
  return Object.values(row).map((value: unknown) => {
    return convertToString(value);
  });
};
