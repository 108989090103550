import React, { useCallback, useEffect, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import i18n from '../../../translations';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import UpdateSensorsStyles from './UpdateSensorsStyles';
import { FormDropdown } from '../../../components/atoms/FormDropdown/FormDropdown';
import { ButtonRow } from '../../../components/atoms/ButtonRow/ButtonRow';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { Sensor, SensorPosition } from '../../../schemas/Sensor';
import { ROUTES } from '../../../router';
import { SubjectDataResponse } from '../Subject/Subject';
import {
  KNOWN_CLOUD_FUNCTIONS,
  useCloudContext,
} from '../../../providers/CloudProvider';
import { Side } from '../../../types/cloud';

export interface UpdateSensorsFields {
  leftSensor: string | undefined;
  rightSensor: string | undefined;
}

const initialValues: UpdateSensorsFields = {
  leftSensor: undefined,
  rightSensor: undefined,
};

export default function UpdateSensors() {
  const subject = useLoaderData() as SubjectDataResponse;
  const { styles } = useThemedComponent([UpdateSensorsStyles]);
  const [sensors, setSensors] = useState<Sensor[]>([]);
  const [currentLeft, setCurrentLeft] = useState<Sensor>();
  const [currentRight, setCurrentRight] = useState<Sensor>();
  const navigate = useNavigate();
  const { profileService, sensorHistoryService, sensorService, cloudService } =
    useCloudContext();

  const dataFetcher = useCallback(async () => {
    try {
      const availableSensors = await sensorService.getAvailableSensors();
      const profile = await profileService.getProfileBySubjectId(
        subject.subjectId,
      );
      if (!profile) {
        throw new Error('No profile found for the given subject');
      }
      const sensorPair =
        await sensorHistoryService.getSubjectsCurrentSensors(profile);

      if (!sensorPair.left || !sensorPair.right) {
        throw new Error('No sensors found for the given profile');
      }

      if (sensorPair) {
        setCurrentLeft(sensorPair.left);
        setCurrentRight(sensorPair.right);

        // also add current left/ right sensor to the dropdown values
        availableSensors.unshift(sensorPair.right);
        availableSensors.unshift(sensorPair.left);
      }
      setSensors(availableSensors);
    } catch (e) {
      alert('Failed to get the list of available sensors\n\n' + e);
    }
  }, [profileService, sensorHistoryService, sensorService, subject.subjectId]);

  useEffect(() => {
    dataFetcher();
  }, [dataFetcher]);

  /**
   * Filter out selected sensor and also the current sensor from the list
   * of available sensors
   */
  const getSensorsForDropdown = useCallback(
    (excludingSensors: (string | undefined)[]) => {
      const availableSensors = sensors
        .filter(sensor => !excludingSensors.includes(sensor.id))
        .map(s => ({
          value: s.id,
          label: s.attributes.mac,
        }));

      return availableSensors;
    },
    [sensors],
  );

  const onSubmit = useCallback(
    async (
      values: UpdateSensorsFields,
      action: FormikHelpers<UpdateSensorsFields>,
    ) => {
      try {
        if (!values.leftSensor && !values.rightSensor) {
          return;
        }
        if (values.leftSensor) {
          cloudService.run(KNOWN_CLOUD_FUNCTIONS.SWAP_SENSOR, {
            subjectId: subject.subjectId,
            sensorId: values.leftSensor,
            position: SensorPosition.LEFT_WRIST,
            localCreatedAt: new Date().toISOString(),
          } as unknown as JSON);
        }
        if (values.rightSensor) {
          cloudService.run(KNOWN_CLOUD_FUNCTIONS.SWAP_SENSOR, {
            subjectId: subject.subjectId,
            sensorId: values.rightSensor,
            position: SensorPosition.RIGHT_WRIST,
            localCreatedAt: new Date().toISOString(),
          } as unknown as JSON);
        }
      } catch (e) {
        alert(`Failed to update sensors: ${e}`);
      } finally {
        await dataFetcher();
        action.resetForm();
      }
    },
    [cloudService, dataFetcher, subject.subjectId],
  );
  return (
    <div style={styles.pageContainer}>
      <div style={styles.pageHeader}>
        <h1 style={styles.h1}>{i18n.t('updateSensors.title')}</h1>
      </div>
      <div style={styles.underTitleContainer}>
        <div style={styles.subjectIdContainer}>
          <label style={{ ...styles.label, ...styles.boldText }}>
            {i18n.t('updateSensors.subjectId')}
          </label>
          <label style={{ ...styles.value, ...styles.boldText }}>
            {subject.subjectId}
          </label>
        </div>
        <div style={styles.subjectIdContainer}>
          <label style={{ ...styles.label, ...styles.boldText }}>
            {i18n.t('updateSensors.currentLeft')}
          </label>
          <label
            style={
              styles.value
            }>{`${currentLeft?.attributes.mac ?? 'unknown'}`}</label>
        </div>
        <div style={styles.subjectIdContainer}>
          <label style={{ ...styles.label, ...styles.boldText }}>
            {i18n.t('updateSensors.currentRight')}
          </label>
          <label
            style={
              styles.value
            }>{`${currentRight?.attributes.mac ?? 'unknown'}`}</label>
        </div>
        <div style={styles.formContainer}>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ handleSubmit, resetForm, values }) => (
              <Form onSubmit={handleSubmit} style={styles.betweenInput}>
                <FormDropdown
                  name="leftSensor"
                  options={getSensorsForDropdown([
                    values.rightSensor,
                    currentLeft?.id,
                  ])}
                  label={i18n.t('createSubject.sensor.label', {
                    position: Side.LEFT,
                  })}
                  labelStyle={styles.blueText}
                  placeholder={i18n.t('createSubject.sensor.placeholder', {
                    position: Side.LEFT,
                  })}
                />
                <FormDropdown
                  name="rightSensor"
                  options={getSensorsForDropdown([
                    values.leftSensor,
                    currentRight?.id,
                  ])}
                  label={i18n.t('createSubject.sensor.label', {
                    position: Side.RIGHT,
                  })}
                  labelStyle={styles.greenText}
                  placeholder={i18n.t('createSubject.sensor.placeholder', {
                    position: Side.RIGHT,
                  })}
                />
                <ButtonRow
                  buttons={[
                    {
                      title: i18n.t('globals.cancel'),
                      type: 'button',
                      style: styles.cancelButton,
                      onClick: () => {
                        resetForm();
                        navigate(
                          ROUTES.SUBJECT.buildPath({
                            subjectId: subject.subjectId,
                          }),
                        );
                      },
                    },
                    {
                      title: i18n.t('globals.save'),
                      type: 'submit',
                      style: styles.saveButton,
                    },
                  ]}
                  style={styles.buttonContainer}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
