import { useMemo } from 'react';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import GDMTrendStyles from './GDMTrendStyles';

export interface GDMTrendProps {
  title: string;
  description: string;
  previousGoalCount?: number;
  currentGoalCount?: number;
  style?: React.CSSProperties;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconStyle?: React.CSSProperties;
}

const DEFAULT_CHANGE_PERCENTAGE = '0';
const POSITIVE_CHANGE = '+';
const NEGATIVE_CHANGE = '-';

const GDMTrend = (props: GDMTrendProps) => {
  const { styles } = useThemedComponent([GDMTrendStyles]);

  // Calculate the change in data
  const changeData = useMemo(() => {
    if (
      props.previousGoalCount === undefined ||
      props.currentGoalCount === undefined
    ) {
      return {
        isPostiveChange: true,
        changePercentage: DEFAULT_CHANGE_PERCENTAGE,
      };
    }
    const changePercentage =
      ((props.currentGoalCount - props.previousGoalCount) /
        props.previousGoalCount) *
      100;
    return {
      isPostiveChange: changePercentage >= 0,
      changePercentage: Math.abs(changePercentage),
    };
  }, [props.currentGoalCount, props.previousGoalCount]);

  return (
    <div style={styles.container}>
      <div style={styles.rightSection}>
        <h1 style={styles.h1}>{props.description}</h1>
        <div style={styles.flexRow}>
          <props.Icon style={{ ...styles.icon, ...props.iconStyle }} />
          <div style={styles.p}>{props.title}</div>
          <div
            style={{
              ...styles.p,
              ...styles.moveToEnd,
              ...(changeData.isPostiveChange === undefined
                ? styles.greenText
                : changeData.isPostiveChange
                  ? styles.greenText
                  : styles.redText),
            }}>
            {changeData.isPostiveChange === undefined
              ? POSITIVE_CHANGE
              : changeData.isPostiveChange
                ? POSITIVE_CHANGE
                : NEGATIVE_CHANGE}
            {changeData.changePercentage === undefined
              ? DEFAULT_CHANGE_PERCENTAGE
              : changeData.changePercentage.toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                })}
            %
          </div>
        </div>
      </div>
    </div>
  );
};

export default GDMTrend;
