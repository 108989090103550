import React from 'react';
import { ReactSVG } from 'react-svg';
import SeachIcon from '../../../assets/SearchIcon.svg';
import SearchBarStyles from './SearchBarStyles';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import './ExtraSearchBarStyles.css';

export interface SearchBarProps {
  search: string;
  setSearch: (search: string) => void;
  id: string;
}

const SearchBar = (props: SearchBarProps) => {
  const { styles, theme } = useThemedComponent([SearchBarStyles]);
  return (
    <div style={{ ...styles.searchBarContainer }}>
      <ReactSVG
        src={SeachIcon}
        beforeInjection={svg => {
          svg.setAttribute('color', theme.colors.transparentBlack);
          return svg;
        }}
      />
      <input
        id={props.id}
        type="search"
        style={{ ...styles.input }}
        placeholder="Search"
        onChange={e => {
          props.setSearch(e.target.value);
        }}
        value={props.search}
      />
    </div>
  );
};

export default SearchBar;
