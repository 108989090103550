import {
  type ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

export default (theme: ThemeContent): StyleSheet => ({
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textType: {
    fontSize: 20,
    fontWeight: 'bold',
    position: 'absolute',
    left: 2,
    right: 0,
    textAlign: 'center',
  },
  blue: {
    color: theme.colors.blue,
  },
  green: {
    color: theme.colors.green,
  },
});
