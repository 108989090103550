import React, { useCallback, useEffect, useState } from 'react';
import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { useTableColumns } from '../../../hooks/useTableColumns';
import {
  DateRangeOption,
  GDMHourlyColumns,
  GDMInformationColumns,
  SortOrder,
  SubjectSummaryTableColumns,
} from '../../../types/tables';
import { useLoading } from '../../../providers/LoadingProvider';
import i18n from '../../../translations';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import GDMPageStyles from './GDMPageStyles';
import Table from '../../../components/templates/Table/Table';
import DatePaginationAndFilter from '../../../components/organisms/DatePaginationAndFilter/DatePaginationAndFilter';
import { makeDateRangeOptions } from '../../../utils/tables';
import { DEFAULT_TIMEZONE, dateToISOString } from '../../../utils/dateTime';
import { commonTableStyles } from '../../../components/templates/Table/tableUtil';
import {
  GDM_HOURLY_COLUMNS,
  GDM_INFORMATION_COLUMNS,
  SUBJECT_SUMMARY_COLUMNS,
  transformGdmHourlyForExport,
  transformGdmInfoForExport,
} from './helpers';
import { useCloudContext } from '../../../providers/CloudProvider';
import { ROUTES } from '../../../router';
import { Group } from '../../../schemas/Profile';
import { useSubjectContext } from '../../../providers/SubjectContext';
import { useLogging } from '../../../providers/LoggingProvider';
import { DateTime } from 'luxon';

export interface GDMPageParams {
  subjectId: string;
}
interface GDMPageDataResponse {
  subjectId: string;
}

export const loader = async ({
  params,
}: LoaderFunctionArgs<GDMPageParams>): Promise<GDMPageDataResponse | Error> => {
  try {
    if (!params.subjectId) {
      return new Error('Subject ID is required');
    }
    return {
      subjectId: params.subjectId,
    };
  } catch (e) {
    return new Error('Error fetching GDM page data');
  }
};

export default function GDMPage() {
  const pageData = useLoaderData() as GDMPageDataResponse;
  const { setLoading } = useLoading();
  const { styles } = useThemedComponent([commonTableStyles, GDMPageStyles]);
  const [gdmWeekOptions, setGdmWeekOptions] = useState<DateRangeOption[]>();
  const [selectedGDMInfoDateRangeIndex, setSelectedGDMInfoDateRangeIndex] =
    useState<number | undefined>();

  const [gdmDateOptions, setGdmDateOptions] = useState<Date[]>();
  const [selectedGDMHourlyDateRangeIndex, setSelectedGDMHourlyDateRangeIndex] =
    useState<number | undefined>();
  const [selectedGDMHourlyDateIndex, setSelectedGDMHourlyDateIndex] = useState<
    number | undefined
  >(0);

  const [timezone, setTimezone] = useState<string>(DEFAULT_TIMEZONE);
  const [group, setGroup] = useState<Group>();
  const { cloudService, profileService, movementService } = useCloudContext();
  const { deactivatedAt } = useSubjectContext();
  const navigate = useNavigate();

  const {
    columnHelper: subjectSummarySolumnHelper,
    tableData: subjectSummaryTableData,
    setTableData: setSubjectSummaryTableData,
    sorting: subjectSummarySorting,
  } = useTableColumns<SubjectSummaryTableColumns>();

  const {
    columnHelper: gdmInformationColumnHelper,
    tableData: gdmInformationTableData,
    setTableData: setGdmInformationTableData,
    ExportDropDown: GDMInfoExportDropDown,
    sorting: gdmInformationSorting,
    setSorting: setGdmInformationSorting,
  } = useTableColumns<GDMInformationColumns>({
    defaultSorting: [{ colKey: 'date', index: 1, order: SortOrder.DESC }],
    tableName: i18n.t('tables.gdmInformation.title'),
    transformExportData: transformGdmInfoForExport,
  });

  const {
    columnHelper: gdmHourlyColumnHelper,
    tableData: gdmHourlyTableData,
    setTableData: setGdmHourlyTableData,
    ExportDropDown: GDMHourlyExportDropDown,
    sorting: gdmHourlySorting,
    setSorting: setGdmHourlySorting,
  } = useTableColumns<GDMHourlyColumns>({
    defaultSorting: [{ colKey: 'date', index: 1, order: SortOrder.DESC }],
    tableName: i18n.t('tables.gdmInformation.title'),
    transformExportData: transformGdmHourlyForExport,
  });

  const logger = useLogging(GDMPage.name);

  // get the weekly date range options
  useEffect(() => {
    const generateDateRangeOptions = async () => {
      const profile = await profileService.getProfileBySubjectId(
        pageData.subjectId,
      );
      if (!profile) {
        throw new Error('Profile not found');
      }
      setTimezone(profile.attributes.timezone ?? timezone);
      setGroup(profile.attributes.group);
      const firstMovement = await movementService.getFirstMovement(profile);

      if (!firstMovement) {
        logger.warn('No movements found for date range options');
        return [];
      }

      return makeDateRangeOptions(
        firstMovement.get('startTime'),
        profile.get('deactivatedAt') ?? new Date(),
      );
    };
    // make our api calls
    setLoading(true);

    generateDateRangeOptions()
      .then(dateRangeOptions => {
        // options must be in descending order-- most recent first
        setGdmWeekOptions(dateRangeOptions.reverse());
      })
      .catch(e => {
        logger.error('Error generating date range options', e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    logger,
    movementService,
    pageData.subjectId,
    profileService,
    setLoading,
    timezone,
  ]);

  // generate the daily date range options
  useEffect(() => {
    if (
      gdmWeekOptions &&
      gdmWeekOptions.length > 0 &&
      selectedGDMHourlyDateRangeIndex !== undefined
    ) {
      // make the daily date range options
      const { startDate } = gdmWeekOptions[selectedGDMHourlyDateRangeIndex];
      // get each date between the start and end date
      const dailyOptions = Array.from({ length: 7 }, (_, i) => {
        const day = new Date(startDate);
        day.setDate(day.getDate() + i);
        return day;
      }).filter(
        // filter out dates in the future
        d =>
          DateTime.fromJSDate(d).startOf('day') <=
          DateTime.now().startOf('day'),
      );
      setGdmDateOptions(dailyOptions.reverse());
      return;
    }
  }, [gdmWeekOptions, selectedGDMHourlyDateRangeIndex]);

  // Set the selectedDailyDateRangeIndex to 0 to start
  useEffect(() => {
    if (
      gdmWeekOptions &&
      gdmWeekOptions.length > 0 &&
      selectedGDMHourlyDateRangeIndex === undefined
    ) {
      setSelectedGDMHourlyDateRangeIndex(0);
      return;
    }
  }, [gdmWeekOptions, selectedGDMHourlyDateRangeIndex]);

  const dataFetcher = useCallback(async () => {
    try {
      // make our api calls
      setLoading(true);

      // get the subject summary data
      const subjectSummaryRes = await cloudService.requestSubjectSummary({
        subjectId: pageData.subjectId,
        sort: subjectSummarySorting,
      });
      const newSubjectSummaryData: SubjectSummaryTableColumns[] =
        subjectSummaryRes.data;

      // set the data for the component to use
      setSubjectSummaryTableData(newSubjectSummaryData);

      // get the gdm information data if we have a date range
      if (selectedGDMInfoDateRangeIndex !== undefined && gdmWeekOptions) {
        const { startDate, endDate } =
          gdmWeekOptions[selectedGDMInfoDateRangeIndex];
        const startDateISO = dateToISOString(startDate, timezone);
        const endDateISO = dateToISOString(endDate, timezone);
        const gdmInfoRes = await cloudService.requestGDMInfo({
          subjectId: pageData.subjectId,
          sort: gdmInformationSorting,
          startDateISO,
          endDateISO,
        });

        setGdmInformationTableData(gdmInfoRes.data);
      }

      if (
        gdmDateOptions &&
        selectedGDMHourlyDateRangeIndex !== undefined &&
        selectedGDMHourlyDateIndex !== undefined
      ) {
        const date = gdmDateOptions[selectedGDMHourlyDateIndex];
        const gdmHourlyRes = await cloudService.requestGDMHourly({
          subjectId: pageData.subjectId,
          dateISO: dateToISOString(date, timezone),
          sort: gdmHourlySorting,
        });

        setGdmHourlyTableData(gdmHourlyRes.data);
      }
    } catch (e) {
      logger.error(
        'Error fetching subject summary or gdm information tables!',
        e,
      );
    } finally {
      setLoading(false);
    }
  }, [
    setLoading,
    cloudService,
    pageData.subjectId,
    subjectSummarySorting,
    setSubjectSummaryTableData,
    selectedGDMInfoDateRangeIndex,
    gdmWeekOptions,
    gdmDateOptions,
    selectedGDMHourlyDateRangeIndex,
    selectedGDMHourlyDateIndex,
    timezone,
    gdmInformationSorting,
    setGdmInformationTableData,
    gdmHourlySorting,
    setGdmHourlyTableData,
    logger,
  ]);

  // fetch the data when the selected date range changes and on sorting
  useEffect(() => {
    // if no date range options, select the first one and return
    // this will retrigger the useEffect
    if (
      gdmWeekOptions &&
      gdmWeekOptions.length > 0 &&
      selectedGDMInfoDateRangeIndex === undefined
    ) {
      setSelectedGDMInfoDateRangeIndex(0);
      return;
    }
    dataFetcher().catch(e => {
      logger.error(e);
    });
  }, [
    selectedGDMInfoDateRangeIndex,
    gdmWeekOptions,
    gdmInformationSorting,
    logger,
    dataFetcher,
  ]);

  return (
    <div style={styles.pageContainer}>
      <div style={styles.pageHeader}>
        <h1 style={styles.h1}>
          {i18n.t('subject.title', { subjectId: pageData.subjectId })}
        </h1>
        <div style={styles.btnGroup}>
          {group === Group.INTERVENTION && !deactivatedAt && (
            <button
              style={styles.primaryBtn}
              onClick={() => {
                navigate(
                  ROUTES.NEW_GDM.buildPath({ subjectId: pageData.subjectId }),
                );
              }}>
              {i18n.t('subject.setGoal')}
            </button>
          )}
        </div>
      </div>
      <div style={styles.tableSectionContainer}>
        <div style={{ ...styles.rowContainer, ...styles.tableTopRow }}>
          <h2 style={styles.h2}>{i18n.t('tables.subjectSummary.title')}</h2>
        </div>
        <Table
          columnHelper={subjectSummarySolumnHelper}
          columns={SUBJECT_SUMMARY_COLUMNS}
          data={subjectSummaryTableData}
        />
      </div>
      <div
        style={{
          ...styles.rowContainer,
          ...styles.tableSectionContainer,
          ...styles.lastRow,
        }}>
        <div style={{ ...styles.rowContainer, ...styles.tableTopRow }}>
          <h2 style={styles.h2}>{i18n.t('tables.gdmInformation.title')}</h2>
          <div style={styles.btnGroup}>
            <DatePaginationAndFilter
              labels={gdmWeekOptions?.map(option => option.asString)}
              options={gdmWeekOptions}
              selectedOptionIndex={selectedGDMInfoDateRangeIndex}
              setSelectedOptionIndex={setSelectedGDMInfoDateRangeIndex}
            />
            <GDMInfoExportDropDown
              style={{ ...styles.rightButton, ...styles.exportButton }}
            />
          </div>
        </div>
        <Table
          columnHelper={gdmInformationColumnHelper}
          columns={GDM_INFORMATION_COLUMNS}
          data={gdmInformationTableData}
          sorting={gdmInformationSorting}
          setSorting={setGdmInformationSorting}
        />
      </div>
      <div style={{ ...styles.rowContainer, ...styles.tableSectionContainer }}>
        <div style={{ ...styles.rowContainer, ...styles.tableTopRow }}>
          <h2 style={styles.h2}>{i18n.t('tables.gdmHourly.title')}</h2>
          <div style={styles.btnGroup}>
            <DatePaginationAndFilter
              labels={gdmWeekOptions?.map(option => option.asString)}
              options={gdmWeekOptions}
              selectedOptionIndex={selectedGDMHourlyDateRangeIndex}
              setSelectedOptionIndex={setSelectedGDMHourlyDateRangeIndex}
            />
            <DatePaginationAndFilter
              labels={gdmDateOptions?.map(option =>
                option.toLocaleDateString(),
              )}
              options={gdmDateOptions}
              selectedOptionIndex={selectedGDMHourlyDateIndex}
              setSelectedOptionIndex={setSelectedGDMHourlyDateIndex}
              pretext={i18n.t('chooseADay')}
            />
            <GDMHourlyExportDropDown
              style={{ ...styles.rightButton, ...styles.exportButton }}
            />
          </div>
        </div>
        <Table
          columnHelper={gdmHourlyColumnHelper}
          columns={GDM_HOURLY_COLUMNS}
          data={gdmHourlyTableData}
          sorting={gdmHourlySorting}
          setSorting={setGdmHourlySorting}
          tableLayout="fixed"
        />
      </div>
    </div>
  );
}
