import {
  type ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

export default (theme: ThemeContent): StyleSheet => ({
  nav: {
    backgroundColor: theme.colors.primary,
  },
  ul: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10%',
  },
  li: {
    marginBottom: '5%',
    color: theme.colors.white,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  btn: {
    cursor: 'pointer',
    marginTop: '5vh',
  },
});
