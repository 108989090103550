import React, { useCallback, useEffect, useState } from 'react';
import {
  useThemedComponent,
  StyleSheet,
} from '../../../providers/ThemeProvider';
import i18n from '../../../translations';
import { Form, useLoaderData, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useLoading } from '../../../providers/LoadingProvider';
import { Formik, FormikHelpers } from 'formik';
import { ButtonRow } from '../../../components/atoms/ButtonRow/ButtonRow';
import { FormInput } from '../../../components/atoms/FormInput/FormInput';
import { ReactComponent as AwardIcon } from '../../../assets/AwardIcon.svg';
import { ActionPlanAttributes } from '../../../schemas/ActionPlan';
import { useCloudContext } from '../../../providers/CloudProvider';
import { SubjectDataResponse } from '../Subject/Subject';
import Popup from '../../../components/atoms/Popup/Popup';
import { ROUTES } from '../../../router';
import { Group } from '../../../schemas/Profile';
import { useLogging } from '../../../providers/LoggingProvider';

export interface NewDAAPFields {
  name: string;
  actionPlan: string;
  confidence: string;
  difficulty: string;
  when: string;
  where: string;
  how: string;
  who: string;
}

const initialValues: NewDAAPFields = {
  name: '',
  actionPlan: '',
  confidence: '',
  difficulty: '',
  when: '',
  where: '',
  how: '',
  who: '',
};

// If needed, modify error messages in the translation json file
const NewDAAPSchema = Yup.object<NewDAAPFields>().shape({
  name: Yup.string()
    .max(30, i18n.t('newDAAPGoal.name.limit'))
    .required(i18n.t('newDAAPGoal.name.required')),
  actionPlan: Yup.string().required(i18n.t('newDAAPGoal.actionPlan.required')),
  confidence: Yup.number()
    .integer()
    .typeError(i18n.t('newDAAPGoal.confidence.number'))
    .moreThan(0, i18n.t('newDAAPGoal.confidence.limit'))
    .lessThan(11, i18n.t('newDAAPGoal.confidence.limit'))
    .required(i18n.t('newDAAPGoal.confidence.required')),
  difficulty: Yup.number()
    .integer()
    .typeError(i18n.t('newDAAPGoal.difficulty.number'))
    .moreThan(0, i18n.t('newDAAPGoal.difficulty.limit'))
    .lessThan(11, i18n.t('newDAAPGoal.difficulty.limit'))
    .required(i18n.t('newDAAPGoal.difficulty.required')),
  when: Yup.string().required(i18n.t('newDAAPGoal.when.required')),
  where: Yup.string().required(i18n.t('newDAAPGoal.where.required')),
  how: Yup.string().required(i18n.t('newDAAPGoal.how.required')),
  who: Yup.string().required(i18n.t('newDAAPGoal.who.required')),
});

const NewDAAPPage: React.FC = () => {
  const subject = useLoaderData() as SubjectDataResponse;
  const { styles } = useThemedComponent(NewDAAPPageStyles);
  const { setLoading } = useLoading();
  const { profileService, actionPlanService } = useCloudContext();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const logger = useLogging(NewDAAPPage.name);

  const dataFetcher = useCallback(async () => {
    profileService.getProfileBySubjectId(subject.subjectId).then(profile => {
      if (!profile || profile?.get('group') === Group.CONTROL) {
        logger.warn('Profile not found or is in control group');
        navigate(-1); // go back to previous page
      }
    });
  }, [logger, navigate, profileService, subject.subjectId]);

  useEffect(() => {
    dataFetcher().catch(e => {
      logger.error(e);
      alert(`Failed to retrieve data ${e}`);
    });
  }, [dataFetcher, logger]);

  const onClosePopup = useCallback(() => {
    setShowPopup(false);
    navigate(ROUTES.DAAPS.buildPath({ subjectId: subject.subjectId }));
  }, [navigate, subject]);

  const onSubmit = useCallback(
    async (values: NewDAAPFields, action: FormikHelpers<NewDAAPFields>) => {
      try {
        setLoading(true);
        const profile = await profileService.getProfileBySubjectId(
          subject.subjectId,
        );
        if (!profile) {
          alert(
            'Failed to find associate profile for new DAAP:\n\n' +
              JSON.stringify(values),
          );
          return;
        }
        const last3 =
          await actionPlanService.getCurrentActionPlanLatestEdits(profile);

        if (last3.length === 3) {
          alert(i18n.t('newDAAPGoal.maxGoalReached'));
          return;
        }

        const {
          name,
          actionPlan,
          confidence,
          difficulty,
          when,
          where,
          who,
          how,
        } = values;

        const timezone = await actionPlanService.getTimezone(profile);

        const newDaap: ActionPlanAttributes = {
          name: name,
          owner: profile,
          actionPlan,
          confidence: parseInt(confidence!), // was validated before submit
          difficulty: parseInt(difficulty!), // was validated before submit
          when,
          where,
          who,
          how,
          planID: name.trim().replace(/\s/g, '') + '-' + Date.now(),
          completeToday: undefined,
          closed: undefined,
          previousRecord: null,
          localCreatedAt: new Date(),
          timezone,
        };

        await actionPlanService.addNewDAAP(newDaap);
        action.resetForm({
          values: initialValues,
        });
        setShowPopup(true);
      } catch (e) {
        alert('Failed to create new DAAP:\n\n' + e + JSON.stringify(values));
      } finally {
        setLoading(false);
      }
    },
    [setLoading, profileService, subject.subjectId, actionPlanService],
  );

  return (
    <div style={styles.pageContainer}>
      <div style={{ ...styles.pageHeader, ...styles.labelContainer }}>
        <AwardIcon />
        <label style={styles.h1}>{i18n.t('newDAAPGoal.title')}</label>
      </div>
      <div style={styles.formContainer}>
        <Formik
          initialValues={initialValues}
          validationSchema={NewDAAPSchema}
          onSubmit={onSubmit}>
          {({ isSubmitting, handleSubmit, resetForm }) => (
            <Form style={styles.betweenInput} onSubmit={handleSubmit}>
              <FormInput
                type="text"
                name="name"
                label={i18n.t('newDAAPGoal.name.label')}
                placeholder={i18n.t('newDAAPGoal.name.placeholder')}
              />
              <FormInput
                type="text"
                name="actionPlan"
                label={i18n.t('newDAAPGoal.actionPlan.label')}
                placeholder={i18n.t('newDAAPGoal.actionPlan.placeholder')}
              />
              <FormInput
                type="number"
                name="confidence"
                label={i18n.t('newDAAPGoal.confidence.label')}
                placeholder={i18n.t('newDAAPGoal.confidence.placeholder')}
              />
              <FormInput
                type="number"
                name="difficulty"
                label={i18n.t('newDAAPGoal.difficulty.label')}
                placeholder={i18n.t('newDAAPGoal.difficulty.placeholder')}
              />
              <FormInput
                type="text"
                name="when"
                label={i18n.t('newDAAPGoal.when.label')}
                placeholder={i18n.t('newDAAPGoal.when.placeholder')}
              />
              <FormInput
                type="text"
                name="where"
                label={i18n.t('newDAAPGoal.where.label')}
                placeholder={i18n.t('newDAAPGoal.where.placeholder')}
              />
              <FormInput
                type="text"
                name="how"
                label={i18n.t('newDAAPGoal.how.label')}
                placeholder={i18n.t('newDAAPGoal.how.placeholder')}
              />
              <FormInput
                type="text"
                name="who"
                label={i18n.t('newDAAPGoal.who.label')}
                placeholder={i18n.t('newDAAPGoal.who.placeholder')}
              />
              <ButtonRow
                buttons={[
                  {
                    title: i18n.t('globals.cancel'),
                    type: 'button',
                    disabled: isSubmitting,
                    style: styles.cancelButton,
                    onClick: () => {
                      resetForm();
                      navigate(-1); // go back to previous page
                    },
                  },
                  {
                    title: i18n.t('globals.save'),
                    type: 'submit',
                    disabled: isSubmitting,
                    style: styles.saveButton,
                  },
                ]}
                style={styles.buttonContainer}
              />
            </Form>
          )}
        </Formik>
      </div>
      {showPopup && (
        <Popup
          open={showPopup}
          onClose={onClosePopup}
          title={i18n.t('newDAAPGoal.goalCreated')}
          buttons={[
            {
              title: `${i18n.t('globals.dismiss')}`,
              onClick: onClosePopup,
              style: styles.saveButton,
            },
          ]}>
          <div style={styles.popupBody}>
            <p style={styles.p}>
              {i18n.t('newDAAPGoal.aNewDaapGoal', {
                subjectId: subject.subjectId,
              })}
            </p>
          </div>
        </Popup>
      )}
    </div>
  );
};

const NewDAAPPageStyles = (): StyleSheet => ({
  formContainer: {
    width: '45%',
  },
  betweenInput: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 10,
    marginTop: '2vmin',
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
  },
  buttonContainer: {
    display: 'flex',
    gap: 10,
    marginTop: 20,
  },
  popupBody: {
    padding: 10,
    marginTop: 20,
    marginBottom: 20,
  },
  p: {
    lineHeight: 1.5,
    textAlign: 'center',
  },
});

export default NewDAAPPage;
