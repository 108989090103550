import { useThemedComponent } from '../../../providers/ThemeProvider';
import SensorIcon, { SensorType } from '../../atoms/SensorIcon/SensorIcon';
import SensorStatusStyles from './SensorStatusStyles';
import { ReactComponent as StackIcon } from '../../../assets/StackIcon.svg';
import { ReactComponent as Cross } from '../../../assets/Cross.svg';
import { ReactComponent as CheckLine } from '../../../assets/CheckLine.svg';
import { ReactComponent as Sync } from '../../../assets/Sync.svg';
import i18n from '../../../translations';
import BatteryIcon from '../../atoms/BatteryIcon/BatteryIcon';
import { getTimeAgo } from '../../../utils/dateTime';

export enum LABEL_OPTION {
  BATTERY_PERCENTAGE,
  FLASH_PERCENTAGE,
  CONNECTION_STATUS,
  LAST_SYNC,
}

export interface SensorStatusProps {
  type: SensorType;
  title: string;
  batteryPercentage?: number;
  flashPercentage?: number;
  connectionStatus?: boolean;
  lastSync?: Date;
  style?: React.CSSProperties;
}

const SensorStatus = (props: SensorStatusProps) => {
  const { styles, theme } = useThemedComponent([SensorStatusStyles]);

  const noValueString = i18n.t('globals.noValue');

  const svgIconProps = {
    width: '1rem',
    height: '1rem',
  };

  return (
    <div style={{ ...styles.container, ...props.style }}>
      <div style={styles.leftSection}>
        <SensorIcon type={props.type} />
      </div>
      <div style={styles.rightSection}>
        <h1 style={styles.h1}>{props.title}</h1>
        <div style={styles.labelsContainer}>
          <div style={styles.defaultLabel}>
            <BatteryIcon battery={props.batteryPercentage} />
            <p>
              {props.batteryPercentage
                ? i18n.t('globals.percentValue', {
                    value: props.batteryPercentage,
                  })
                : noValueString}
            </p>
          </div>
          <div style={styles.defaultLabel}>
            <StackIcon color={theme.colors.primary} {...svgIconProps} />
            <p>
              {props.flashPercentage
                ? i18n.t('subject.percentFullValue', {
                    value: props.flashPercentage,
                  })
                : noValueString}
            </p>
          </div>
          <div
            style={{
              ...styles.defaultLabel,
              ...styles.whiteText,
              ...(props.connectionStatus
                ? { backgroundColor: theme.colors.green }
                : { backgroundColor: theme.colors.red }),
            }}>
            {props.connectionStatus ? (
              <CheckLine {...svgIconProps} />
            ) : (
              <Cross {...svgIconProps} />
            )}
            <p>
              {props.connectionStatus
                ? i18n.t('subject.connected')
                : i18n.t('subject.notConnected')}
            </p>
          </div>
          <div style={styles.defaultLabel}>
            <Sync {...svgIconProps} />
            <p>
              {props.lastSync
                ? i18n.t('subject.sensorTime.ago', {
                    time: getTimeAgo(props.lastSync),
                  })
                : noValueString}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SensorStatus;
