import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  type ThemeContent,
  type StyleSheet,
  useThemedComponent,
} from '../../../providers/ThemeProvider';
import QRCode from 'react-qr-code';
import type Parse from '../../../parse';
import { UserTemporaryAuthToken } from '../../../types/cloud';

const formatTime = (time: number) => {
  return time < 10 ? `0${time}` : time;
};

const getTimeRemaining = (endTime: Date) => {
  const total =
    Date.parse(endTime.toString()) - Date.parse(new Date().toString());
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  };
};

export const QRCodeDisplay: React.FC<{
  token: Parse.Object<UserTemporaryAuthToken>;
  instruction: string;
}> = ({
  token,
  instruction,
}: {
  token: Parse.Object<UserTemporaryAuthToken>;
  instruction: string;
}) => {
  const { styles } = useThemedComponent(QRCodeDisplayStyles);
  const endTimeRef = useRef<Date>(token.attributes.expiresAt);
  const animationFrameId = useRef<number>();
  const [timeRemaining, setTimeRemaining] = useState(
    getTimeRemaining(endTimeRef.current),
  );

  const tick = useCallback(() => {
    if (animationFrameId.current) {
      setTimeRemaining(prev => {
        const newVal = getTimeRemaining(endTimeRef.current);
        if (Math.floor(newVal.total / 1000) < 0) {
          if (animationFrameId.current) {
            cancelAnimationFrame(animationFrameId.current);
            animationFrameId.current = undefined;
          }
          return prev;
        } else {
          return newVal;
        }
      });
      if (timeRemaining.total > 0) {
        animationFrameId.current = requestAnimationFrame(tick);
      }
    }
  }, [timeRemaining]);

  useEffect(() => {
    animationFrameId.current = requestAnimationFrame(tick);
    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, [tick]);

  return (
    <div style={styles.container}>
      <label style={styles.instructionText}>{instruction}</label>
      <QRCode value={token.attributes.token} style={{ width: '50%' }} />
      <label style={styles.boldText}>
        {`${formatTime(timeRemaining.hours)}:${formatTime(timeRemaining.minutes)}:${formatTime(timeRemaining.seconds)}`}
      </label>
    </div>
  );
};

const QRCodeDisplayStyles = (theme: ThemeContent): StyleSheet => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
    gap: 20,
    marginTop: 15,
    width: 500,
  },
  instructionText: {
    lineHeight: 1.5,
    textAlign: 'center',
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  button: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    height: 40,
    width: 300,
    borderRadius: 10,
  },
});
