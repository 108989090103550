import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ThemeContent,
  useThemedComponent,
  StyleSheet,
} from '../../../providers/ThemeProvider';
import i18n from '../../../translations';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { ReactComponent as AwardIcon } from '../../../assets/AwardIcon.svg';
import { SubjectDataResponse } from '../Subject/Subject';
import Popup from '../../../components/atoms/Popup/Popup';
import { ROUTES } from '../../../router';
import { ButtonRow } from '../../../components/atoms/ButtonRow/ButtonRow';
import GDMCountsGraph from '../../../components/organisms/GDMCountsGraph/GDMCountsGraph';
import { useLoading } from '../../../providers/LoadingProvider';
import MovementBarGraph from '../../../components/organisms/MovementBarGraph/MovementBarGraph';
import ExpansionTile from '../../../components/templates/ExpansionTile/ExpansionTile';
import GDMGraphToggle from '../../../components/atoms/GDMGraphToggle/GDMGraphToggle';
import { useCloudContext } from '../../../providers/CloudProvider';
import { useSubjectHelpers } from '../Subject/useSubjectHelpers';
import {
  movementsToPercent,
  percentToMovements,
  FIXED_PERCENTAGE,
  approximatelyEqual,
} from '../../../utils/goalCalculations';
import { DateTime } from 'luxon';
import { Group } from '../../../schemas/Profile';
import { GDMInfo } from '../../../schemas/GDMInfo';
import { useLogging } from '../../../providers/LoggingProvider';

const NewGDMPage: React.FC = () => {
  // common stuff
  const subject = useLoaderData() as SubjectDataResponse;
  const { styles } = useThemedComponent(NewGDMPageStyles);
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const { setLoading } = useLoading();
  const cloudContext = useCloudContext();
  const { gdmInfoBasedGraphQuery } = useSubjectHelpers({
    cloudContext,
    subjectId: subject.subjectId,
  });
  const { goalService, profileService } = cloudContext;

  // graph stuff
  const [gdmInfoData, setGdmInfoData] = useState<GDMInfo[]>([]);
  const [gdmGraphSelection, setGdmGraphSelection] = useState(0);
  const gdmRef = React.useRef();
  const [ascAffectedMovements, setAscAffectedMovements] =
    useState<{ date: DateTime; count: number }[]>();
  const startDateRef = useRef<DateTime>();
  // input stuff
  const [movementGoal, setMovementGoal] = useState<number>(0);
  const prevMovementGoal = useRef<number>();
  const [percentGoal, setPercentGoal] = useState<number>(0);
  const prevPercentGoal = useRef<number>();
  const logger = useLogging(NewGDMPage.name);

  const editMovementGoal = useCallback(
    (goal: string) => {
      if (ascAffectedMovements === undefined) {
        return;
      }
      const actualMovementCounts = ascAffectedMovements.map(
        ({ count }) => count,
      );
      // adding 0 movements to the front of the array
      actualMovementCounts.unshift(0);
      const movement = Number(goal);
      // allow integer only
      if (isNaN(movement) || Math.round(movement) !== movement) {
        return;
      }
      setMovementGoal(movement);
      prevMovementGoal.current = movement;

      // now let's see if we should also update percentage value
      const newPercent = movementsToPercent(
        movement,
        FIXED_PERCENTAGE,
        actualMovementCounts,
      );
      if (
        prevPercentGoal.current !== undefined &&
        !approximatelyEqual(prevPercentGoal.current, newPercent, 1)
      ) {
        if (newPercent >= Number.MAX_SAFE_INTEGER) {
          setPercentGoal(100);
          prevPercentGoal.current = 100;
        } else {
          setPercentGoal(Math.round(newPercent));
          prevPercentGoal.current = newPercent;
        }
      }
    },
    [ascAffectedMovements],
  );

  const editPercentGoal = useCallback(
    (goal: string) => {
      if (ascAffectedMovements === undefined) {
        return;
      }
      const actualMovementCounts = ascAffectedMovements.map(
        ({ count }) => count,
      );
      // adding 0 movements to the front of the array
      actualMovementCounts.unshift(0);
      const percent = Number(goal);
      // allow integer only
      if (isNaN(percent) || Math.round(percent) !== percent) {
        return;
      }
      setPercentGoal(percent);
      prevPercentGoal.current = percent;

      // now let's see if we should also update movement value
      const newMovement = percentToMovements(
        percent,
        FIXED_PERCENTAGE,
        actualMovementCounts,
      );
      if (
        prevMovementGoal.current !== undefined &&
        !approximatelyEqual(prevMovementGoal.current, newMovement, 1)
      ) {
        setMovementGoal(Math.round(newMovement));
        prevMovementGoal.current = newMovement;
      }
    },
    [ascAffectedMovements],
  );

  const dataFetcher = useCallback(async () => {
    try {
      // first  make out api call
      setLoading(true);
      const profile = await profileService.getProfileBySubjectId(
        subject.subjectId,
      );
      if (!profile) {
        throw new Error('Profile not found');
      }

      if (!profile || profile?.get('group') === Group.CONTROL) {
        logger.warn('Profile not found or is in control group');
        navigate(-1); // go back to previous page
      }

      // end date should be one day ago
      const endDate = DateTime.now()
        .setZone(profile.attributes.timezone)
        .minus({ days: 1 })
        .endOf('day');

      // start date is 7 days in the past
      const startDate = endDate.minus({ days: 6 }).startOf('day');

      startDateRef.current = startDate;
      const gdmInfos = await gdmInfoBasedGraphQuery(startDate, endDate);
      setGdmInfoData(gdmInfos);
      const affectedData: { date: DateTime; count: number }[] = [
        ...Array(7).keys(),
      ].map(idx => {
        const date = startDate.plus({ days: idx });
        const gdmInfo = gdmInfos.find(
          gdm =>
            DateTime.fromJSDate(gdm.attributes.date, {
              zone: profile.attributes.timezone,
            }).toISODate() === date.toISODate(),
        );
        return {
          date,
          count: gdmInfo?.attributes.affectedSideTotal ?? 0,
        };
      });

      // sort in ascending order of counts
      affectedData.sort((prev, curr) => prev.count - curr.count);
      setAscAffectedMovements(affectedData);

      // init input values
      setPercentGoal(FIXED_PERCENTAGE[6]);
      prevPercentGoal.current = FIXED_PERCENTAGE[6];
      setMovementGoal(affectedData[5].count);
      prevMovementGoal.current = affectedData[5].count;
    } catch (e) {
      logger.error(e);
    } finally {
      setLoading(false);
    }
  }, [
    setLoading,
    profileService,
    subject.subjectId,
    gdmInfoBasedGraphQuery,
    logger,
    navigate,
  ]);

  useEffect(() => {
    dataFetcher().catch(e => {
      logger.error(e);
      alert(`Failed to retrieve data ${e}`);
    });
  }, [dataFetcher, logger]);

  const onClosePopup = useCallback(() => {
    setShowPopup(false);
    navigate(-1);
  }, [navigate]);

  const onSubmit = useCallback(async () => {
    try {
      if (!movementGoal) {
        throw new Error('No goal found');
      }
      setLoading(true);
      // starting from the next day
      const profile = await profileService.getProfileBySubjectId(
        subject.subjectId,
      );

      if (!profile) {
        throw new Error('Profile not found');
      }

      let goalStartDate = DateTime.now()
        .setZone(profile.attributes.timezone)
        .startOf('day');

      if (profile.attributes.activatedAt) {
        // goal starts the next day
        goalStartDate = goalStartDate.plus({ day: 1 });
      } else {
        // start study
        await profileService.activate(profile.attributes.subjectId, new Date());
      }
      await goalService.setGoal(
        profile,
        movementGoal,
        goalStartDate.toJSDate(),
      );
      setShowPopup(true);
    } catch (e) {
      alert('Failed to create new DAAP:\n\n' + e + JSON.stringify(percentGoal));
    } finally {
      setLoading(false);
    }
  }, [
    goalService,
    movementGoal,
    percentGoal,
    profileService,
    setLoading,
    subject.subjectId,
  ]);

  return (
    <div style={styles.pageContainer}>
      <div style={{ ...styles.pageHeader, ...styles.labelContainer }}>
        <AwardIcon />
        <label style={styles.h1}>{i18n.t('newGdmGoal.title')}</label>
      </div>
      <div style={styles.body}>
        <div style={styles.leftContainer}>
          <div style={styles.goalContainer}>
            <input
              value={percentGoal}
              name={'percentage'}
              style={styles.inputBox}
              onChange={event => editPercentGoal(event.currentTarget.value)}
            />
            <p style={styles.percent}>%</p>
            <input
              value={movementGoal}
              name={'movements'}
              style={styles.inputBox}
              onChange={event => editMovementGoal(event.currentTarget.value)}
            />
            <p style={{ ...styles.p, ...styles.boldText }}>
              {i18n.t('newGdmGoal.movementsDaily')}
            </p>
          </div>
          {startDateRef.current && (
            <ExpansionTile
              onExpand={() =>
                navigate(
                  ROUTES.GDMS.buildPath({ subjectId: subject.subjectId }),
                )
              }
              title={i18n.t('subject.movementGraphTitle')}
              titleStyle={styles.graphTitle}
              style={styles.graphTile}
              leftOfExpandItems={
                <GDMGraphToggle
                  gdmGraphSelection={gdmGraphSelection}
                  setGdmGraphSelection={setGdmGraphSelection}
                />
              }
              ref={gdmRef}>
              <MovementBarGraph
                gdmInfoData={gdmInfoData}
                containerRef={
                  gdmRef as unknown as MutableRefObject<HTMLDivElement | null>
                }
                gdmGraphSelection={gdmGraphSelection}
                startDate={startDateRef.current}
                subjectId={subject.subjectId}
              />
            </ExpansionTile>
          )}
          <ButtonRow
            buttons={[
              {
                title: i18n.t('globals.cancel'),
                type: 'button',
                style: styles.cancelButton,
                onClick: () => {
                  navigate(-1); // go back to previous page
                },
              },
              {
                title: i18n.t('globals.save'),
                type: 'submit',
                style: styles.saveButton,
                onClick: onSubmit,
              },
            ]}
            style={styles.buttonContainer}
          />
        </div>
        <div>
          {ascAffectedMovements && (
            <GDMCountsGraph data={[...ascAffectedMovements].reverse()} />
          )}
        </div>
      </div>
      {showPopup && (
        <Popup
          open={showPopup}
          onClose={onClosePopup}
          title={i18n.t('newDAAPGoal.goalCreated')}
          buttons={[
            {
              title: `${i18n.t('globals.dismiss')}`,
              onClick: onClosePopup,
              style: styles.saveButton,
            },
          ]}>
          <div style={styles.popupBody}>
            <p style={styles.p}>
              {i18n.t('newGdmGoal.aNewGdmGoal', {
                subjectId: subject.subjectId,
              })}
            </p>
          </div>
        </Popup>
      )}
    </div>
  );
};

const NewGDMPageStyles = (theme: ThemeContent): StyleSheet => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '2%',
    marginTop: '2%',
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '1%',
    width: '100%',
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    gap: '3%',
    width: '100%',
    marginTop: '3vmin',
  },
  leftContainer: {
    display: 'flex',
    gap: '3%',
    alignItems: 'flex-start',
    justifyContent: 'start',
    flexDirection: 'column',
  },
  inputBox: {
    backgroundColor: theme.colors.white,
    boxShadow: '#00000026 0px 2px 2px 2px',
    border: 'none',
    width: '18%',
    height: '5vh',
    borderRadius: 5,
    fontSize: '1.2rem',
    paddingLeft: '2%',
    fontWeight: 'bold',
  },
  goalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '1vw',
  },
  errorContainer: {
    display: 'flex',
    gap: '1vw',
    width: '100%',
    marginTop: '1vh',
    color: theme.colors.red,
  },
  buttonContainer: {
    display: 'flex',
    gap: '1vw',
    marginTop: '3vh',
    width: '98%',
  },
  popupBody: {
    padding: '1rem',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  p: {
    lineHeight: 1.5,
    textAlign: 'center',
    left: 0,
  },
  boldText: {
    fontWeight: 'bold',
  },
  percent: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  relative: {
    position: 'relative',
    height: '5vh',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  graphTile: {
    width: '90%',
    padding: '4%',
    borderRadius: '1.5%',
    marginTop: '4%',
    marginBottom: '4%',
  },
  graph: {
    boxShadow: '#00000026 2px 2px 2px 2px',
    justifyContent: 'space-evenly',
    padding: '2% 5% 5% 5%',
    alignItems: 'flex-start',
    backgroundColor: theme.colors.white,
    borderRadius: 10,
    marginTop: '3%',
  },
  graphTitle: {
    fontWeight: 'bold',
    fontSize: 20,
  },
});

export default NewGDMPage;
