/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import DropDownSelectStyles from './DropDownSelectStyles';

export interface DropDownSelectProps {
  selectionIndex?: number | undefined;
  setSelectionIndex: (index: number | undefined) => void;
  options?: any[];
  maxOptionsBeforeScroll?: number;
  menuAlignment?: 'left' | 'right';
  style?: React.CSSProperties;
  menuStyle?: React.CSSProperties;
  placeholder?: string;
  // optional, used when option label is different from option value
  // constrain: must have the same length as options
  labels?: string[];
  extraChildren?: React.JSX.Element;
}

const DropDownSelect = (props: DropDownSelectProps) => {
  const { styles } = useThemedComponent([DropDownSelectStyles]);

  // check if labels are provided and have the same length as options
  if (props.labels && props.labels.length !== props?.options?.length) {
    throw new Error('Labels must have the same length as options');
  }

  return (
    <div>
      {/* Based of https://tailwindui.com/components/ecommerce/components/category-filters#component-cb75aacd7c636865835476c98f606a38 */}
      <Menu as="div" style={styles.menuStyles}>
        <Menu.Button style={{ ...styles.menuButton, ...props.style }}>
          <span>
            {props.selectionIndex === undefined && props.placeholder
              ? props.placeholder
              : props.selectionIndex && props.labels
                ? props.labels[props.selectionIndex]
                : props.labels
                  ? props.labels[0]
                  : ''}
          </span>
          <ChevronDownIcon aria-hidden="true" style={styles.chevronIcon} />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items
            style={{
              ...styles.menuItems,
              ...(props.maxOptionsBeforeScroll
                ? { maxHeight: `${props.maxOptionsBeforeScroll * 1.875}rem` }
                : {}),
              ...(props.menuAlignment === 'right' ? { right: 0 } : { left: 0 }),
              ...props.menuStyle,
            }}>
            {props?.options?.map((option, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <span
                    style={
                      active || props.selectionIndex === index
                        ? { ...styles.menuItem, ...styles.activeMenuItem }
                        : styles.menuItem
                    }
                    onClick={() => {
                      props.setSelectionIndex(index);
                    }}>
                    {props.labels &&
                    props.labels.length === props?.options?.length
                      ? props.labels[index]
                      : option}
                  </span>
                )}
              </Menu.Item>
            ))}
            {props.extraChildren}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default DropDownSelect;
