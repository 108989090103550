import Parse from '../parse';

export const MAX_QUERY_LIMIT = 1000;

/**
 * Waits for a given amount of time before returning a value.
 * Useful for simulating API calls.
 *
 * @param rtrn What to return after the timeMS
 * @returns @rtrn
 */
export const fakeApiCall = async <T>(
  rtrn: T,
  timeMS: number = 1000,
): Promise<T> => {
  return await new Promise<T>(resolve => {
    setTimeout(() => {
      resolve(rtrn);
    }, timeMS);
  });
};

/**
 * Uitlity function to query parse objects with large counts via
 * pagination
 * @param query The query to paginate
 * @param objectCount the total count of the objects in the query
 * @param limit the limit to query at a time
 * @returns the objects in the query
 */
export const largeParseQuery = async <T extends Parse.Object<Parse.Attributes>>(
  query: Parse.Query<T>,
  objectCount: number,
  limit?: number,
) => {
  const objects: T[] = [];
  const queryLimit = limit ?? MAX_QUERY_LIMIT;
  if (objectCount < queryLimit) {
    query.limit(objectCount);
    const queryResult = await query.find();
    objects.push(...queryResult);
  } else {
    let skip = 0;
    while (skip < objectCount) {
      query.limit(queryLimit);
      query.skip(skip);
      const queryResult = await query.find();
      objects.push(...queryResult);
      skip += queryLimit;
    }
  }
  return objects;
};
