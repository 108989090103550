import React, { type HTMLProps } from 'react';

interface IndeterminateCheckboxProps extends HTMLProps<HTMLInputElement> {
  indeterminate?: boolean;
  className?: string;
}

const IndeterminateCheckbox = (props: IndeterminateCheckboxProps) => {
  const { indeterminate, className = '', ...rest } = props;
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + ' cursor-pointer'}
      {...rest}
    />
  );
};

export default IndeterminateCheckbox;
