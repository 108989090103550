import {
  type ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

export default (theme: ThemeContent): StyleSheet => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  rightSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '1em',
    marginLeft: '2%',
    marginRight: '2%',
  },
  h1: {
    flex: 1,
    color: theme.colors.blackGrey,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    width: '100%',
  },
  icon: {
    height: '2rem',
    width: '2rem',
    marginRight: '4%',
  },
  p: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
  },
  moveToEnd: {
    marginLeft: 'auto',
  },
});
