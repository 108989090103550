import { transformForExportBase } from '../../../components/templates/Table/tableUtil';
import i18n from '../../../translations';
import {
  CustomColumnDefintion,
  CustomTableColumns,
} from '../../../types/tables';

/**
 * The style for the cells
 */
export const cellStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '0.5em',
  marginRight: '0.5em',
};

export const subjectSummaryColumnsPrefix = 'tables.subjectSummary.columns';
export const SUBJECT_SUMMARY_COLUMNS: CustomColumnDefintion[] = [
  {
    column: 'subjectID',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.subjectId`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'redcapID',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.redcapId`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'primaryTherapist',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.primaryTherapist`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'secondaryTherapist',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.secondaryTherapist`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'weekNumber',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.weekNumber`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'rightMAC',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.rightMAC`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'leftMAC',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.leftMAC`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'affectedSide',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.affectedSide`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
];

export const hepInformationColumnsPrefix = 'tables.hepInformation.columns';
export const HEP_INFORMATION_COLUMNS: CustomColumnDefintion[] = [
  {
    column: 'subjectID',
    sortable: false,
    headerName: i18n.t(`${hepInformationColumnsPrefix}.subjectId`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'date',
    sortable: false,
    headerName: i18n.t(`${hepInformationColumnsPrefix}.date`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'startTime',
    sortable: false,
    headerName: i18n.t(`${hepInformationColumnsPrefix}.startTime`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'endTime',
    sortable: false,
    headerName: i18n.t(`${hepInformationColumnsPrefix}.endTime`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'totalTime',
    sortable: false,
    headerName: i18n.t(`${hepInformationColumnsPrefix}.totalTime`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'note',
    sortable: false,
    headerName: i18n.t(`${hepInformationColumnsPrefix}.note`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
];

export const transformForExport = (data: CustomTableColumns[]) => {
  return transformForExportBase(data, HEP_INFORMATION_COLUMNS);
};
