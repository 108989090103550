import {
  type ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

export default (theme: ThemeContent): StyleSheet => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: theme.colors.white,
    width: '10vw',
    height: '50vh',
    boxShadow: '#00000026 2px 2px 2px 2px',
    borderRadius: 10,
    padding: '10%',
    paddingRight: '15%',
    gap: '1vw',
    alignItems: 'center',
  },
  entry: {
    display: 'flex',
    flex: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  countBox: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  linearGradient: {
    backgroundAttachment: 'fixed',
    borderRadius: 10,
    background: `linear-gradient(#F7CC3B, #FAF1D4)`,
  },
  dateContainer: {
    display: 'flex',
    width: '100%',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5vh',
  },
  pCount: {
    fontWeight: 'bold',
    fontSize: 18,
  },
  pDay: {
    fontSize: 14,
  },
  pDate: {
    fontSize: 12,
    color: theme.colors.greySecondary,
  },
  green: {
    backgroundColor: theme.colors.green,
  },
});
