import {
  type ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

export default (theme: ThemeContent): StyleSheet => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '98%',
    height: '100%',
    paddingTop: '2%',
  },
  titleText: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    flex: 2,
  },
  qrcodeButton: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    borderStyle: 'none',
    height: '2rem',
    width: '2rem',
    padding: '0.5%',
    borderRadius: '0.375rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  qrcodeIcon: {
    height: '1.5rem',
    width: '1.5rem',
  },
  popup: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2%',
  },
  startButton: {
    backgroundColor: theme.colors.green,
    color: theme.colors.white,
    fontWeight: 'bold',
  },
  endButton: {
    backgroundColor: theme.colors.red,
    color: theme.colors.white,
    fontWeight: 'bold',
  },
  btnGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'stretch',
    flex: 3,
    gap: '1%',
  },
  rightButton: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    height: '2rem',
    minWidth: '7rem',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'none',
    borderRadius: '0.375rem',
    fontSize: '0.8rem',
    margin: 0,
  },
  btn: {
    justifyContent: 'center',
    borderRadius: '0.375rem',
    borderStyle: 'none',
    padding: '2%',
    height: '100%',
    width: '100%',
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
  },
  graphLabelsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2%',
    margin: '5% 0',
  },
  graphLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2%',
  },
  graphLabelColorIndicator: {
    height: '1rem',
    width: '1rem',
    borderRadius: 5,
  },
  graphLabel: {
    fontWeight: 'bold',
  },
  complianceGraphTile: {
    width: '95%',
    padding: '2%',
    borderRadius: '0.5rem',
  },
  complianceGraph: {
    justifyContent: 'flex-start',
  },
  graphTitle: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  complianceXAxis: {
    marginTop: '0.5rem',
  },
  complianceXAxisTextBottom: {
    color: theme.colors.transparentBlack,
    fontSize: '0.8rem',
    marginTop: '0.2rem',
  },
  toolTipText: {
    fontSize: '0.8rem',
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
  },
  leftGraphs: {
    width: '50%',
  },
  rightGraphs: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: 10,
  },
  graphContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  summaryContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  summaryBoxesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
    rowGap: '1rem',
  },
  summaryTile: {
    borderRadius: '0.5rem',
    width: '46.5%',
    padding: '1.5%',
  },
  leftGraph: {
    width: '95%',
    borderRadius: '0.5rem',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '2%',
    marginBottom: '2%',
  },
});
