import React, { useCallback, useEffect, useState } from 'react';
import { Formik, type FormikHelpers } from 'formik';
import { Form } from 'react-router-dom';
import * as Yup from 'yup';
import { FormDropdown } from '../../atoms/FormDropdown/FormDropdown';
import { FormInput } from '../../atoms/FormInput/FormInput';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import { ButtonRow } from '../../atoms/ButtonRow/ButtonRow';
import type Parse from 'parse';
import i18n from '../../../translations';
import CreateSubjectFormStyles from './CreateSubjectFormStyles';
import { Group } from '../../../schemas/Profile';
import { useCloudContext } from '../../../providers/CloudProvider';
import { Side, StrokeWearRoles } from '../../../types/cloud';
import { useLogging } from '../../../providers/LoggingProvider';

export interface CreateSubjectFields {
  subjectId: string;
  redcapId: string;
  affectedSide: Side | undefined;
  leftSensor: string | undefined;
  rightSensor: string | undefined;
  therapist: string | undefined;
  studyCoordinator: string | undefined;
  group: Group | undefined;
  accessCode: string;
}

const initialValues: CreateSubjectFields = {
  subjectId: '',
  redcapId: '',
  affectedSide: undefined,
  leftSensor: undefined,
  rightSensor: undefined,
  therapist: undefined,
  studyCoordinator: undefined,
  group: undefined,
  accessCode: '',
};

// modify error messages in the translation json file
const CreateSubjectSchema = Yup.object<CreateSubjectFields>().shape({
  subjectId: Yup.string().required(i18n.t('createSubject.subjectId.error')),
  redcapId: Yup.string().required(i18n.t('createSubject.redcapId.error')),
  affectedSide: Yup.string().required(
    i18n.t('createSubject.affectedSide.error'),
  ),
  leftSensor: Yup.string().required(i18n.t('createSubject.sensor.error')),
  rightSensor: Yup.string().required(i18n.t('createSubject.sensor.error')),
  therapist: Yup.string().required(i18n.t('createSubject.therapist.error')),
  studyCoordinator: Yup.string().required(
    i18n.t('createSubject.studyCoordinator.error'),
  ),
  group: Yup.mixed<Group>()
    .test(value => Object.values(Group).includes(value as Group))
    .required(i18n.t('createSubject.group.error')),
  accessCode: Yup.string().required(i18n.t('createSubject.accessCode.error')),
});

interface CreateSubjectFormProps {
  onSubmit: (
    values: CreateSubjectFields,
    action: FormikHelpers<CreateSubjectFields>,
  ) => Promise<void>;
  initialValues?: CreateSubjectFields;
  onCancel: () => void;
  isUpdateForm?: boolean; // whether this form is for updating subject info instead of create new
}

export const CreateSubjectForm: React.FC<CreateSubjectFormProps> = ({
  onSubmit,
  onCancel,
  isUpdateForm = false,
  ...props
}: CreateSubjectFormProps) => {
  const { styles } = useThemedComponent(CreateSubjectFormStyles);
  const { sensorService, cloudService } = useCloudContext();
  const [sensors, setSensors] = useState<Parse.Object[]>([]);

  const [therapists, setTherapists] = useState<Parse.Object[]>([]);
  const [studyCoordinators, setStudyCoordinators] = useState<Parse.Object[]>(
    [],
  );

  const logger = useLogging(CreateSubjectForm.name);

  const dataFetcher = useCallback(async () => {
    const [therapistObjects, coordinatorsObjects, sensorsObjects] =
      await Promise.all([
        cloudService.getUsersWithRole(StrokeWearRoles.Therapist),
        cloudService.getUsersWithRole(StrokeWearRoles.StudyCoordinator),
        sensorService.getAvailableSensors(),
      ]);

    setSensors(sensorsObjects);
    setTherapists(therapistObjects);
    setStudyCoordinators(coordinatorsObjects);
  }, [cloudService, sensorService]);

  useEffect(() => {
    dataFetcher().catch(e => {
      logger.error(`Failed to retrieve data ${e}`);
      alert(`Failed to retrieve data ${e}`);
    });
  }, [dataFetcher, logger]);

  const getSensors = useCallback(
    (selectedSensorId: string | undefined) => {
      const availableSensors = sensors
        .filter(sensor => sensor.id !== selectedSensorId)
        .map(s => ({
          value: s.id,
          label: s.attributes.mac,
        }));

      return availableSensors;
    },
    [sensors],
  );

  return (
    <div style={styles.container}>
      <Formik
        initialValues={props.initialValues ?? initialValues}
        validationSchema={CreateSubjectSchema}
        onSubmit={onSubmit}>
        {({ isSubmitting, handleSubmit, values }) => (
          <Form style={styles.container} onSubmit={handleSubmit}>
            <FormInput
              type="text"
              name="subjectId"
              label={i18n.t('createSubject.subjectId.label')}
              placeholder={i18n.t('createSubject.subjectId.placeholder')}
            />
            <FormInput
              type="text"
              name="redcapId"
              label={i18n.t('createSubject.redcapId.label')}
              placeholder={i18n.t('createSubject.redcapId.placeholder')}
            />

            <FormDropdown
              name="affectedSide"
              options={Object.values(Side).map(r => ({
                value: r,
                label: r,
              }))}
              label={i18n.t('createSubject.affectedSide.label')}
              placeholder={i18n.t('createSubject.affectedSide.placeholder')}
            />
            {isUpdateForm ? (
              <></>
            ) : (
              <>
                <FormDropdown
                  name="leftSensor"
                  options={getSensors(values.rightSensor)}
                  label={i18n.t('createSubject.sensor.label', {
                    position: Side.LEFT,
                  })}
                  labelStyle={styles.blueText}
                  placeholder={i18n.t('createSubject.sensor.placeholder', {
                    position: Side.LEFT,
                  })}
                />
                <FormDropdown
                  name="rightSensor"
                  options={getSensors(values.leftSensor)}
                  label={i18n.t('createSubject.sensor.label', {
                    position: Side.RIGHT,
                  })}
                  labelStyle={styles.greenText}
                  placeholder={i18n.t('createSubject.sensor.placeholder', {
                    position: Side.RIGHT,
                  })}
                />
              </>
            )}
            <FormDropdown
              name="therapist"
              options={therapists.map(user => ({
                value: user.id,
                label: user.attributes.username,
              }))}
              label={i18n.t('createSubject.therapist.label')}
              placeholder={i18n.t('createSubject.therapist.placeholder')}
            />
            <FormDropdown
              name="studyCoordinator"
              options={studyCoordinators.map(user => ({
                value: user.id,
                label: user.attributes.username,
              }))}
              label={i18n.t('createSubject.studyCoordinator.label')}
              placeholder={i18n.t('createSubject.studyCoordinator.placeholder')}
            />
            <FormDropdown
              name="group"
              options={Object.values(Group)
                .filter(g => isNaN(Number(g)))
                .map(group => ({
                  value: group,
                  label: i18n.t(`createSubject.group.${group}`),
                }))}
              label={i18n.t('createSubject.group.label')}
              placeholder={i18n.t('createSubject.group.placeholder')}
            />
            <FormInput
              type="text"
              name="accessCode"
              label={i18n.t('createSubject.accessCode.label')}
              placeholder={i18n.t('createSubject.accessCode.placeholder')}
            />

            <ButtonRow
              buttons={[
                {
                  title: i18n.t('globals.cancel'),
                  type: 'button',
                  disabled: isSubmitting,
                  style: styles.cancelButton,
                  onClick: onCancel,
                },
                {
                  title: i18n.t('globals.save'),
                  type: 'submit',
                  disabled: isSubmitting,
                  style: styles.saveButton,
                },
              ]}
              style={styles.buttonContainer}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};
