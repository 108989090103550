import React from 'react';
import { Outlet } from 'react-router-dom';
import SideBar from './components/atoms/SideBar/SideBar';
import NavBar from './components/atoms/NavBar/NavBar';
import {
  type ThemeContent,
  type StyleSheet,
  useThemedComponent,
} from './providers/ThemeProvider';

function App() {
  const { styles } = useThemedComponent([AppStyles]);

  return (
    <div className="App" style={{ ...styles.app }}>
      <NavBar style={{ ...styles.nav }} />
      <SideBar style={styles.sideBar} />
      <main style={{ ...styles.main }}>
        <Outlet />
      </main>
    </div>
  );
}

export default App;

const AppStyles = (theme: ThemeContent): StyleSheet => ({
  app: {
    height: '100%',
    width: '100%',
    backgroundColor: theme.colors.background,
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '92%',
    width: '95%',
    float: 'right',
    overflowY: 'auto',
  },
  nav: {
    backgroundColor: theme.colors.primary,
    width: '100%',
    height: '8%',
  },
  sideBar: {
    flexDirection: 'column',
    width: '5%',
    height: '92%',
    float: 'left',
  },
});
