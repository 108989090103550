import {
  type ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

export default (theme: ThemeContent): StyleSheet => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '95%',
    height: '100%',
    gap: 10,
    marginTop: 15,
  },
  menu: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '30vmin',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    width: '100%',
    marginTop: 15,
    marginBottom: 15,
  },
  btnGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1,
  },
  dropdownButton: {
    flex: 1,
    height: '100%',
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    borderRadius: 5,
  },
  transparentBg: {
    backgroundColor: theme.colors.transparentWhite,
    color: theme.colors.primary,
  },
  itemBtnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: '5%',
  },
  itemBtn: {
    height: '100%',
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    borderRadius: 5,
    padding: 3,
    paddingLeft: 10,
    paddingRight: 10,
    borderStyle: 'solid',
    borderWidth: '1px',
    minWidth: 60,
  },
});
