import { transformForExportBase } from '../../../components/templates/Table/tableUtil';
import i18n from '../../../translations';
import {
  CustomColumnDefintion,
  CustomTableColumns,
} from '../../../types/tables';

/**
 * The style for the cells
 */
const cellStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '0.5em',
  marginRight: '0.5em',
};

const subjectSummaryColumnsPrefix = 'tables.subjectSummary.columns';
export const SUBJECT_SUMMARY_COLUMNS: CustomColumnDefintion[] = [
  {
    column: 'subjectID',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.subjectId`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'redcapID',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.redcapId`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'primaryTherapist',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.primaryTherapist`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'secondaryTherapist',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.secondaryTherapist`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'weekNumber',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.weekNumber`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'rightMAC',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.rightMAC`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'leftMAC',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.leftMAC`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'affectedSide',
    sortable: false,
    headerName: i18n.t(`${subjectSummaryColumnsPrefix}.affectedSide`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
];

const gdmInformationColumnsPrefix = 'tables.gdmInformation.columns';
export const GDM_INFORMATION_COLUMNS: CustomColumnDefintion[] = [
  {
    column: 'subjectID',
    sortable: false,
    headerName: i18n.t(`${gdmInformationColumnsPrefix}.subjectId`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'date',
    sortable: false,
    headerName: i18n.t(`${gdmInformationColumnsPrefix}.date`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
    cellTransform: (value: string) => {
      return Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      }).format(new Date(value));
    },
  },
  {
    column: 'affectedSideTotal',
    sortable: false,
    headerName: i18n.t(`${gdmInformationColumnsPrefix}.affectedSideTotal`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'unaffectedSideTotal',
    sortable: false,
    headerName: i18n.t(`${gdmInformationColumnsPrefix}.unaffectedSideTotal`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'goal',
    sortable: false,
    headerName: i18n.t(`${gdmInformationColumnsPrefix}.goal`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
];

const gdmHourlyColumnsPrefix = 'tables.gdmHourly.columns';
export const GDM_HOURLY_COLUMNS: CustomColumnDefintion[] = [
  {
    column: 'subjectID',
    sortable: false,
    headerName: i18n.t(`${gdmHourlyColumnsPrefix}.subjectId`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'date',
    sortable: false,
    headerName: i18n.t(`${gdmHourlyColumnsPrefix}.date`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
    cellTransform: (value: string) => {
      return Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      }).format(new Date(value));
    },
  },
  {
    column: 'hour',
    sortable: false,
    headerName: i18n.t(`${gdmHourlyColumnsPrefix}.hour`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'affectedCount',
    sortable: false,
    headerName: i18n.t(`${gdmHourlyColumnsPrefix}.affected`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'unaffectedCount',
    sortable: false,
    headerName: i18n.t(`${gdmHourlyColumnsPrefix}.unaffected`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
  {
    column: 'affectedSide',
    sortable: false,
    headerName: i18n.t(`${gdmHourlyColumnsPrefix}.affectedSide`),
    headerStyle: cellStyle,
    cellStyle: cellStyle,
  },
];

export const transformGdmInfoForExport = (data: CustomTableColumns[]) => {
  return transformForExportBase(data, GDM_INFORMATION_COLUMNS);
};

export const transformGdmHourlyForExport = (data: CustomTableColumns[]) => {
  return transformForExportBase(data, GDM_HOURLY_COLUMNS);
};
