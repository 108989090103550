import React, { useCallback, useEffect, useState } from 'react';
import CreateSubjectStyles from './CreateSubjectStyles';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import {
  CreateSubjectFields,
  CreateSubjectForm,
} from '../../../components/organisms/CreateSubjectForm/CreateSubjectForm';
import Parse from '../../../parse';
import Popup from '../../../components/atoms/Popup/Popup';
import i18n from '../../../translations';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../router';
import { QRCodeDisplay } from '../../../components/organisms/QRCodeDisplay/QRCodeDisplay';
import { UserTemporaryAuthToken } from '../../../types/cloud';
import { FormikHelpers } from 'formik';
import {
  KNOWN_CLOUD_FUNCTIONS,
  useCloudContext,
} from '../../../providers/CloudProvider';
import { useLoading } from '../../../providers/LoadingProvider';
import { Group } from '../../../schemas/Profile';

export default function CreateSubject() {
  const { styles } = useThemedComponent([CreateSubjectStyles]);
  const [token, setToken] = useState<Parse.Object<UserTemporaryAuthToken>>();
  const [subjectId, setSubjectId] = useState<string>('');
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { cloudService } = useCloudContext();

  useEffect(() => {
    setShowPopup(token !== undefined && subjectId !== '');
  }, [token, subjectId]);

  const onCancel = useCallback(() => {
    navigate(ROUTES.HOME.path);
  }, [navigate]);

  const onSubmit = useCallback(
    async (
      values: CreateSubjectFields,
      action: FormikHelpers<CreateSubjectFields>,
    ) => {
      try {
        setLoading(true);
        const params = JSON.parse(
          JSON.stringify({
            subjectId: values.subjectId,
            redcapId: values.redcapId,
            affectedSide: values.affectedSide,
            leftSensorId: values.leftSensor,
            rightSensorId: values.rightSensor,
            therapistId: values.therapist,
            studyCoordinatorId: values.studyCoordinator,
            group: Group[values.group as Group],
            localCreatedAt: new Date().toISOString(),
            accessCode: values.accessCode,
          }),
        );

        const createSubjectResult = await cloudService.run(
          KNOWN_CLOUD_FUNCTIONS.CREATE_PROFILE,
          params,
        );

        if (createSubjectResult) {
          const token = await cloudService.getSubjectToken(values.subjectId);
          action.resetForm();
          setSubjectId(values.subjectId);
          if (token) setToken(token);
        }
      } catch (e) {
        alert('Failed to create subject:\n\n' + e + JSON.stringify(values));
      } finally {
        setLoading(false);
      }
    },
    [setLoading, cloudService],
  );

  return (
    <div style={styles.pageContainer}>
      <div style={styles.pageHeader}>
        <h1 style={styles.h1}>{i18n.t('createSubject.title')}</h1>
      </div>
      <div style={styles.topContainer}>
        <CreateSubjectForm onSubmit={onSubmit} onCancel={onCancel} />
      </div>
      {showPopup && (
        <Popup
          open={showPopup}
          onClose={() => setShowPopup(false)}
          title={i18n.t('createSubject.newSubjectCreated', { subjectId })}
          buttons={[
            {
              title: `${i18n.t('createSubject.goToSubjectPage', { subjectId })}`,
              onClick: () =>
                navigate(ROUTES.SUBJECT.buildPath({ subjectId: subjectId })),
              style: styles.btn,
            },
          ]}>
          {/** double-check, but showPopup should have checked this before open the popup */}
          {token && (
            <QRCodeDisplay
              token={token}
              instruction={i18n.t('createSubject.qrcodeInstruction', {
                subjectId,
              })}
            />
          )}
        </Popup>
      )}
    </div>
  );
}
