import React, { useCallback, useEffect, useState } from 'react';
import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { useTableColumns } from '../../../hooks/useTableColumns';
import {
  CompletedDAAPColumns,
  CurrentDAAPGoalColumns,
  CustomColumnDefintion,
  CustomTableColumns,
  DAAPGoalHistoryColumns,
  SortOrder,
  SubjectSummaryTableColumns,
  TableLink,
} from '../../../types/tables';
import { useLoading } from '../../../providers/LoadingProvider';
import i18n from '../../../translations';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import DAAPStyles from './DAAPStyles';
import Table from '../../../components/templates/Table/Table';
import {
  DAAP_INFORMATION_COLUMNS,
  SUBJECT_SUMMARY_COLUMNS,
  cellStyle,
  transformCompletedDaapForExport,
  transformCurrentDaapForExport,
} from './helpers';
import { getCsv, rowToExportString } from '../../../utils/tables';
import DropDownSelect from '../../../components/atoms/DropDownSelect/DropDownSelect';
import { commonTableStyles } from '../../../components/templates/Table/tableUtil';
import { useCloudContext } from '../../../providers/CloudProvider';
import { ROUTES } from '../../../router';
import { Group } from '../../../schemas/Profile';
import { useSubjectContext } from '../../../providers/SubjectContext';
import { useLogging } from '../../../providers/LoggingProvider';
import { fakeApiCall } from '../../../utils/network';
import CompletedDAAP from '../../../components/templates/CompletedDAAP/CompletedDAAP';

export interface SubjectParams {
  subjectId: string;
}
interface SubjectDataResponse {
  subjectId: string;
}

export const loader = async ({
  params,
}: LoaderFunctionArgs<SubjectParams>): Promise<SubjectDataResponse> => {
  const subject = await fakeApiCall({
    subjectId: params.subjectId ?? 'Unknown',
  });

  return subject;
};

const MAX_COMPLETED_DAAP_TABLES = 9;

export default function CurrentDAAPPage() {
  const pageData = useLoaderData() as SubjectDataResponse;
  const { setLoading } = useLoading();
  const { styles } = useThemedComponent([commonTableStyles, DAAPStyles]);
  const { cloudService, profileService } = useCloudContext();
  const navigate = useNavigate();
  const { deactivatedAt } = useSubjectContext();
  const logger = useLogging(CurrentDAAPPage.name);

  const daapGoalHistoryColumnsPrefix = 'tables.daapGoalHistory.columns';

  const transformDAAPHistoryForExport = (data: CustomTableColumns[]) => {
    const exportData = data as DAAPGoalHistoryColumns[];
    return exportData.map(row => {
      const newRowValues = rowToExportString(row);
      // get the keys we want to see
      const exportHeaders = DAAP_GOAL_HISTORY_COLUMNS.map(
        column => column.headerName,
      );

      // create the new object
      const finalExport: Record<string, string> = {};
      for (let i = 0; i < exportHeaders.length; i++) {
        finalExport[exportHeaders[i]] = newRowValues[i].toString();
      }

      return finalExport;
    });
  };

  // subject summary table state
  const {
    columnHelper: subjectSummarySolumnHelper,
    tableData: subjectSummaryTableData,
    setTableData: setSubjectSummaryTableData,
    sorting: subjectSummarySorting,
    exportOptions, // take the export options from the helper for convience (not used in this table)
  } = useTableColumns<SubjectSummaryTableColumns>();

  // current daap table state
  const {
    columnHelper: curentDaapColumnHelper,
    tableData: currentDaapTableData,
    setTableData: setCurrentDaapTableData,
    ExportDropDown: DAAPExportDropDown,
    sorting: currentDaapSorting,
    setSorting: setCurrentDaapSorting,
  } = useTableColumns<CurrentDAAPGoalColumns>({
    defaultSorting: [{ colKey: 'startDate', index: 9, order: SortOrder.DESC }],
    tableName: i18n.t('tables.currentDAAPGoals.title'),
    transformExportData: transformCurrentDaapForExport,
  });

  // completed daap table states
  const [completedDaaps, setCompletedDaaps] = useState<
    {
      name: string;
      data: CompletedDAAPColumns[];
    }[]
  >([]);

  // export selection for the completed daap tables - seperate becuase it represents multiple tables
  const [exportSelectionIndex, setExportSelectionIndex] = useState<
    number | undefined
  >();

  // daap history table state
  const {
    columnHelper: daapHistoryColumnHelper,
    tableData: daapHistoryTableData,
    setTableData: setDaapHistoryTableData,
    sorting: daapHistorySorting,
    setSorting: setDaapHistorySorting,
    ExportDropDown: DAAPHistoryExportDropDown,
  } = useTableColumns<DAAPGoalHistoryColumns>({
    defaultSorting: [{ colKey: 'endDate', index: 10, order: SortOrder.DESC }],
    tableName: i18n.t('tables.daapGoalHistory.title'),
    transformExportData: transformDAAPHistoryForExport,
  });

  // fetch the data, call from a useEffect
  const dataFetcher = useCallback(async () => {
    try {
      // make our api calls
      setLoading(true);

      profileService.getProfileBySubjectId(pageData.subjectId).then(profile => {
        if (!profile || profile?.get('group') === Group.CONTROL) {
          logger.warn('Profile not found or is in control group');
          navigate(-1); // go back to previous page
        }
      });

      // get the subject summary data
      const subjectSummaryRes = await cloudService.requestSubjectSummary({
        subjectId: pageData.subjectId,
        sort: subjectSummarySorting,
      });
      const newSubjectSummaryData: SubjectSummaryTableColumns[] =
        subjectSummaryRes.data;

      setSubjectSummaryTableData(newSubjectSummaryData);

      // get the current daap information data
      const currentDaapRes = await cloudService.requestCurrentDAAPTable({
        subjectId: pageData.subjectId,
        sort: currentDaapSorting,
      });
      const newCurrentDaapData: CurrentDAAPGoalColumns[] = currentDaapRes.data;

      setCurrentDaapTableData(newCurrentDaapData);

      // get the completed daap information data
      const completedDaapRes = await cloudService.requestCompletedDAAPTable({
        subjectId: pageData.subjectId,
        sort: currentDaapSorting,
      });

      // set completed daap data, limit to maximum number of tables
      setCompletedDaaps(() =>
        completedDaapRes.slice(0, MAX_COMPLETED_DAAP_TABLES).map(response => ({
          name: response.name,
          data: response.data,
        })),
      );

      // get the daap history data
      const daapHistoryRes = await cloudService.requestDAAPHistoryTable({
        subjectId: pageData.subjectId,
        sort: daapHistorySorting,
      });

      const newDaapHistoryData: DAAPGoalHistoryColumns[] =
        daapHistoryRes.data.map(row => {
          return {
            ...row,
            name: {
              value: row.name as unknown as string,
              params: { daapId: row.planId },
            } as TableLink<string>,
          };
        });

      setDaapHistoryTableData(newDaapHistoryData);
    } catch (e) {
      logger.error(
        'Error fetching subject summary or daap information tables!',
        e,
      );
    } finally {
      setLoading(false);
    }
  }, [
    setLoading,
    profileService,
    pageData.subjectId,
    cloudService,
    subjectSummarySorting,
    setSubjectSummaryTableData,
    currentDaapSorting,
    setCurrentDaapTableData,
    daapHistorySorting,
    setDaapHistoryTableData,
    logger,
    navigate,
  ]);

  // fetch data when inputs change
  useEffect(() => {
    dataFetcher().catch(e => {
      logger.error(e);
    });
  }, [currentDaapSorting, daapHistorySorting, dataFetcher, logger]);

  // wait for a selection from the completed daap export dropdown
  useEffect(() => {
    if (exportSelectionIndex !== undefined) {
      completedDaaps.forEach(table => {
        if (table.data.length === 0) {
          return;
        }

        if (!table.name) {
          logger.error('Table name is not defined, can not export');
          return;
        }

        getCsv(
          transformCompletedDaapForExport(table.data),
          table.name,
          exportSelectionIndex === 1,
        ).catch(e => {
          logger.error(e);
        });
      });
    }
  }, [completedDaaps, exportSelectionIndex, logger]);

  const DAAP_GOAL_HISTORY_COLUMNS: CustomColumnDefintion[] = [
    {
      column: 'subjectID',
      sortable: false,
      headerName: i18n.t(`${daapGoalHistoryColumnsPrefix}.subjectId`),
      headerStyle: cellStyle,
      cellStyle: cellStyle,
    },
    {
      column: 'name',
      sortable: false,
      headerName: i18n.t(`${daapGoalHistoryColumnsPrefix}.name`),
      headerStyle: cellStyle,
      cellStyle: cellStyle,
      cellLink: 'COMPLETED_DAAP_HISTORY',
      cellParams: {
        subjectId: pageData.subjectId,
      },
    },
    {
      column: 'actionPlan',
      sortable: false,
      headerName: i18n.t(`${daapGoalHistoryColumnsPrefix}.actionPlan`),
      headerStyle: cellStyle,
      cellStyle: cellStyle,
    },
    {
      column: 'confidence',
      sortable: false,
      headerName: i18n.t(`${daapGoalHistoryColumnsPrefix}.confidence`),
      headerStyle: cellStyle,
      cellStyle: cellStyle,
    },
    {
      column: 'difficulty',
      sortable: false,
      headerName: i18n.t(`${daapGoalHistoryColumnsPrefix}.difficulty`),
      headerStyle: cellStyle,
      cellStyle: cellStyle,
    },
    {
      column: 'when',
      sortable: false,
      headerName: i18n.t(`${daapGoalHistoryColumnsPrefix}.when`),
      headerStyle: cellStyle,
      cellStyle: cellStyle,
    },
    {
      column: 'where',
      sortable: false,
      headerName: i18n.t(`${daapGoalHistoryColumnsPrefix}.where`),
      headerStyle: cellStyle,
      cellStyle: cellStyle,
    },
    {
      column: 'how',
      sortable: false,
      headerName: i18n.t(`${daapGoalHistoryColumnsPrefix}.how`),
      headerStyle: cellStyle,
      cellStyle: cellStyle,
    },
    {
      column: 'who',
      sortable: false,
      headerName: i18n.t(`${daapGoalHistoryColumnsPrefix}.who`),
      headerStyle: cellStyle,
      cellStyle: cellStyle,
    },
    {
      column: 'startDate',
      sortable: false,
      headerName: i18n.t(`${daapGoalHistoryColumnsPrefix}.startDate`),
      headerStyle: cellStyle,
      cellStyle: cellStyle,
    },
    {
      column: 'endDate',
      sortable: false,
      headerName: i18n.t(`${daapGoalHistoryColumnsPrefix}.endDate`),
      headerStyle: cellStyle,
      cellStyle: cellStyle,
    },
  ];

  return (
    <div style={styles.pageContainer}>
      <div style={styles.pageHeader}>
        <h1 style={styles.h1}>
          {i18n.t('globals.subject', { subjectId: pageData.subjectId })}
        </h1>
        <div style={styles.btnGroup}>
          {!deactivatedAt && (
            <button
              style={styles.primaryBtn}
              onClick={() => {
                navigate(
                  ROUTES.NEW_DAAP.buildPath({ subjectId: pageData.subjectId }),
                );
              }}>
              {i18n.t('subject.setGoal')}
            </button>
          )}
        </div>
      </div>
      <div style={styles.tableSectionContainer}>
        <div style={{ ...styles.rowContainer, ...styles.tableTopRow }}>
          <h2 style={styles.h2}>{i18n.t('tables.subjectSummary.title')}</h2>
        </div>
        <Table
          columnHelper={subjectSummarySolumnHelper}
          columns={SUBJECT_SUMMARY_COLUMNS}
          data={subjectSummaryTableData}
        />
      </div>
      <div style={{ ...styles.rowContainer, ...styles.tableSectionContainer }}>
        <div style={{ ...styles.rowContainer, ...styles.tableTopRow }}>
          <h2 style={styles.h2}>{i18n.t('tables.currentDAAPGoals.title')}</h2>
          <div style={styles.btnGroup}>
            <DAAPExportDropDown
              style={{ ...styles.rightButton, ...styles.exportButton }}
            />
          </div>
        </div>
        <Table
          sorting={currentDaapSorting}
          setSorting={setCurrentDaapSorting}
          columnHelper={curentDaapColumnHelper}
          columns={DAAP_INFORMATION_COLUMNS}
          data={currentDaapTableData}
        />
      </div>
      <div style={{ ...styles.rowContainer, ...styles.tableSectionContainer }}>
        <div style={{ ...styles.rowContainer, ...styles.tableTopRow }}>
          <h2 style={styles.h2}>{i18n.t('tables.completedDAAP.title')}</h2>
          <div style={styles.btnGroup}>
            <DropDownSelect
              selectionIndex={exportSelectionIndex}
              setSelectionIndex={setExportSelectionIndex}
              options={exportOptions}
              labels={exportOptions}
              placeholder={i18n.t('globals.export')}
              style={{ ...styles.rightButton, ...styles.exportButton }}
              menuAlignment="right"
            />
          </div>
        </div>
        <div style={styles.gridContainer}>
          {completedDaaps.map(({ name, data }, i) => {
            if (data.length === 0) {
              return null;
            }
            return <CompletedDAAP key={i} name={name} data={data} />;
          })}
        </div>
      </div>
      <div
        style={{
          ...styles.rowContainer,
          ...styles.tableSectionContainer,
          ...styles.lastRow,
        }}>
        <div style={{ ...styles.rowContainer, ...styles.tableTopRow }}>
          <h2 style={styles.h2}>{i18n.t('tables.daapGoalHistory.title')}</h2>
          <div style={styles.btnGroup}>
            <DAAPHistoryExportDropDown
              style={{ ...styles.rightButton, ...styles.exportButton }}
            />
          </div>
        </div>
        <Table
          sorting={daapHistorySorting}
          setSorting={setDaapHistorySorting}
          columnHelper={daapHistoryColumnHelper}
          columns={DAAP_GOAL_HISTORY_COLUMNS}
          data={daapHistoryTableData}
        />
      </div>
    </div>
  );
}
