import { useThemedComponent } from '../../../providers/ThemeProvider';
import i18n from '../../../translations';
import { getTimeAgo } from '../../../utils/dateTime';
import MostRecentSyncStyles from './MostRecentSyncStyles';

export interface MostRecentSyncProps {
  title: string;
  description: string;
  mostRecentSync?: Date;
  style?: React.CSSProperties;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconStyle?: React.CSSProperties;
}
const DEFAULT_VALUE = i18n.t('globals.noValue');

const MostRecentSync = (props: MostRecentSyncProps) => {
  const { styles } = useThemedComponent([MostRecentSyncStyles]);
  return (
    <div style={styles.container}>
      <div style={styles.rightSection}>
        <h1 style={styles.h1}>{props.description}</h1>
        <div style={styles.flexRow}>
          <props.Icon style={{ ...styles.icon, ...props.iconStyle }} />
          <div style={styles.p}>{props.title}</div>
          <div
            style={{
              ...styles.p,
              ...styles.moveToEnd,
            }}>
            <p>
              {props.mostRecentSync
                ? i18n.t('subject.sensorTime.ago', {
                    time: getTimeAgo(props.mostRecentSync),
                  })
                : DEFAULT_VALUE}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MostRecentSync;
