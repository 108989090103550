import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { QrCodeIcon } from '@heroicons/react/20/solid';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import SubjectStyles from './SubjectStyles';
import DropDownSelect from '../../../components/atoms/DropDownSelect/DropDownSelect';
import Popup from '../../../components/atoms/Popup/Popup';
import Parse from '../../../parse';
import i18n from '../../../translations';
import { QRCodeDisplay } from '../../../components/organisms/QRCodeDisplay/QRCodeDisplay';
import { ROUTES } from '../../../router';
import Table from '../../../components/templates/Table/Table';
import {
  DateRangeOption,
  HistoricalSensorColumns,
  SortOrder,
  SubjectSummaryTableColumns,
} from '../../../types/tables';
import { useLoading } from '../../../providers/LoadingProvider';
import { useTableColumns } from '../../../hooks/useTableColumns';
import ExpansionTile from '../../../components/templates/ExpansionTile/ExpansionTile';
import { commonTableStyles } from '../../../components/templates/Table/tableUtil';
import {
  GDMTrendData,
  MostRecentSyncData,
  SensorStatusesReponse,
  useSubjectHelpers,
} from './useSubjectHelpers';
import DatePaginationAndFilter from '../../../components/organisms/DatePaginationAndFilter/DatePaginationAndFilter';
import {
  DailyStatus,
  TileWeekGraph,
  WeekTiles,
} from '../../../components/atoms/WeekTiles/WeekTiles';
import { useCloudContext } from '../../../providers/CloudProvider';
import { StrokeWearRoles, UserTemporaryAuthToken } from '../../../types/cloud';
import MovementBarGraph from '../../../components/organisms/MovementBarGraph/MovementBarGraph';
import GDMGraphToggle from '../../../components/atoms/GDMGraphToggle/GDMGraphToggle';
import SensorStatus from '../../../components/organisms/SensorStatus/SensorStatus';
import Tile from '../../../components/templates/Tile/Tile';
import { SensorType } from '../../../components/atoms/SensorIcon/SensorIcon';
import { useAuth } from '../../../providers/AuthProvider';
import { ROUTES_TYPE } from '../../../routes/ProtectedRoute';
import ComplianceBarGraph from '../../../components/organisms/ComplianceBarGraph/ComplianceBarGraph';
import MostRecentSync from '../../../components/organisms/MostRecentSync/MostRecentSync';
import GDMTrend from '../../../components/organisms/GDMTrend/GDMTrend';
import { ReactComponent as SyncPrimary } from '../../../assets/SyncPrimary.svg';
import { ReactComponent as GDMTrendPrimary } from '../../../assets/GDMTrendPrimary.svg';
import { ReactComponent as GDMGoalAward } from '../../../assets/GoalAward.svg';
import { DateTime } from 'luxon';
import { Group } from '../../../schemas/Profile';
import { useSubjectContext } from '../../../providers/SubjectContext';
import GDMGoal from '../../../components/organisms/GDMGoal/GDMGoal';
import { GDMInfo } from '../../../schemas/GDMInfo';
import DropDownMultiSelect from '../../../components/atoms/DropDownSelect/DropDownMultiSelect';
import { useLogging } from '../../../providers/LoggingProvider';
import { SensorHistory } from '../../../schemas/SensorHistory';

export interface SubjectParams {
  subjectId: string;
}

export interface SubjectDataResponse {
  subjectId: string;
}

export const loader = async ({
  params,
}: LoaderFunctionArgs<SubjectParams>): Promise<SubjectDataResponse | Error> => {
  if (!params.subjectId) {
    throw new Error('No subjectId provided');
  }

  return {
    subjectId: params.subjectId ?? 'Unknown',
  };
};

export default function Subject() {
  const { subjectId } = useLoaderData() as SubjectDataResponse;
  const [showPopup, setShowPopup] = useState(true);
  const { styles } = useThemedComponent([commonTableStyles, SubjectStyles]);
  const [token, setToken] = useState<Parse.Object<UserTemporaryAuthToken>>();
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const cloudContext = useCloudContext();
  const subjectContext = useSubjectContext();
  const { group, isActivated, timezone, secondaryTherapistId, deactivatedAt } =
    subjectContext;
  const { roles } = useAuth();
  const logger = useLogging(Subject.name);
  const {
    HISTORICAL_SENSOR_DATA_COLUMNS,
    SUBJECT_SUMMARY_COLUMNS,
    getExerciseWeeklyData,
    getDaapsData,
    transformSensorSummaryForExport,
    getSensorStatuses,
    getMostRecentSync,
    constructedDateRangeOptions,
    getAllTherapists,
    setSecondaryTherapist,
    getGDMTrendData,
    gdmInfoBasedGraphQuery,
  } = useSubjectHelpers({ cloudContext, subjectId, subjectContext });

  const { requestSensorHistory, requestSubjectSummary, getSubjectToken } =
    cloudContext.cloudService;

  // table states
  const {
    columnHelper: subjectSummarySolumnHelper,
    tableData: subjectSummaryTableData,
    setTableData: setSubjectSummaryTableData,
    sorting: subjectSummarySorting,
    filters: subjectSummaryFilters,
    search: subjectSummarySearch,
  } = useTableColumns<SubjectSummaryTableColumns>();

  const {
    columnHelper: sensorHistoryColumnHelper,
    tableData: sensorHistoryTableData,
    setTableData: setSensorHistoryTableData,
    sorting: sensorHistorySorting,
    setSorting: setSensorHistorySorting,
    ExportDropDown,
  } = useTableColumns<HistoricalSensorColumns>({
    defaultSorting: [
      { colKey: 'initialPairDate', index: 4, order: SortOrder.ASC },
    ],
    tableName: i18n.t('tables.historicalSensorData.title'),
    transformExportData: transformSensorSummaryForExport,
  });

  // summary states
  const [sensorStatuses, setSensorStatuses] = useState<SensorStatusesReponse>();

  // GDMTrend states
  const [gdmTrendData, setGdmTrendData] = useState<GDMTrendData>();

  // most recent sync states
  const [mostRecentSync, setMostRecentSync] = useState<MostRecentSyncData>();

  // secondary therapist states
  const [therapists, setTherapists] = useState<Parse.User[]>([]);
  const [selectedTherapistIdx, setSelectedTherapistIdx] = useState<number[]>();

  // graph states
  // generate an array of dates where the week (sun-sat) found from the date in the feched data
  const [graphsDateRangeOptions, setGraphsDateRangeOptions] =
    useState<DateRangeOption[]>();
  const [selectedDateRange, setSelectedDateRange] = useState<
    number | undefined
  >(0);
  const [exerciseGraphData, setExerciseGraphData] = useState<DailyStatus[]>();
  const [daapsGraphData, setDaapsGraphData] = useState<TileWeekGraph[]>();
  const complianceRef = React.useRef();
  const gdmRef = React.useRef();
  const [gdmGraphSelection, setGdmGraphSelection] = useState(0);
  const [gdmInfoData, setGdmInfoData] = useState<GDMInfo[]>([]);
  const [startDate, setStartDate] = useState<DateTime>();

  const [forceUnpairSensors, setForceUnpairSensors] = useState<SensorHistory[]>(
    [],
  );

  /**
   * A generic function to fetch data and handle loading state
   * @param fetcher The function to fetch data
   * @param errorMesssage an optional error message to log
   */
  const genericDataFetch = useCallback(
    async (fetcher: () => Promise<void>, errorMesssage?: string) => {
      try {
        setLoading(true);
        fetcher();
      } catch (e) {
        logger.error(errorMesssage ?? 'Error fetching data!', e);
      } finally {
        setLoading(false);
      }
    },
    [logger, setLoading],
  );

  // get the sensor history data
  const sensorHistoryFetcher = useCallback(async () => {
    const sensorhistoryRes = await requestSensorHistory({
      subjectId,
      sort: sensorHistorySorting,
    });
    const newSensorHistoryData: HistoricalSensorColumns[] =
      sensorhistoryRes.data;

    // set the data for the component to use
    setSensorHistoryTableData(newSensorHistoryData);
  }, [
    requestSensorHistory,
    sensorHistorySorting,
    setSensorHistoryTableData,
    subjectId,
  ]);

  // get the subject summary data
  const subjectSummaryFetcher = useCallback(async () => {
    const subjectSummaryRes = await requestSubjectSummary({
      subjectId,
      sort: subjectSummarySorting,
      filters: subjectSummaryFilters,
      search: subjectSummarySearch,
    });
    const newSubjectSummaryData: SubjectSummaryTableColumns[] =
      subjectSummaryRes.data;

    // set the data for the component to use
    setSubjectSummaryTableData(newSubjectSummaryData);
  }, [
    requestSubjectSummary,
    subjectId,
    subjectSummarySorting,
    subjectSummaryFilters,
    subjectSummarySearch,
    setSubjectSummaryTableData,
  ]);

  const onSecondaryTherapistSelected = useCallback(
    async (indicies: number[]) => {
      try {
        setLoading(true);
        // indicies should always have less than 2 values-- current therapist idx and newly selected one
        if (indicies.length > 2) {
          // this is invalid
          setSelectedTherapistIdx([]);
          return;
        }
        if (indicies.length === 0) {
          // empty selection? must be unassign then
          await setSecondaryTherapist(undefined);
          setSelectedTherapistIdx([]);
        } else {
          // assign new secondary therapist
          // now extract the newly selected index
          const [selectedIdx] = indicies.filter(
            idx => idx !== selectedTherapistIdx?.at(0),
          );
          if (selectedIdx === undefined) {
            return;
          }
          // great, now we can set new 2nd therapist
          await setSecondaryTherapist(therapists[selectedIdx]);
          setSelectedTherapistIdx([selectedIdx]);
        }
        // re-fetch the subject summary data
        await subjectSummaryFetcher();
      } catch (e) {
        logger.error('Failed to set secondary therapist' + e);
      } finally {
        setLoading(false);
      }
    },
    [
      setLoading,
      subjectSummaryFetcher,
      setSecondaryTherapist,
      therapists,
      selectedTherapistIdx,
      logger,
    ],
  );

  const fetchOnLoad = useCallback(async () => {
    const fetcher = async () => {
      await subjectSummaryFetcher();
      await sensorHistoryFetcher();

      const sensorStatuses = await getSensorStatuses();
      setSensorStatuses(sensorStatuses);

      // get the most recent sync
      const mostRecentSync = await getMostRecentSync(sensorStatuses);
      setMostRecentSync(mostRecentSync);

      // get the GDM trend data
      const gdmTrendData = await getGDMTrendData();
      setGdmTrendData(gdmTrendData);

      // get all therapists, date range options, and profile activation
      const [therapists, dateRangeOptions] = await Promise.all([
        getAllTherapists(),
        constructedDateRangeOptions,
      ]);

      setTherapists(therapists);
      setGraphsDateRangeOptions([...dateRangeOptions].reverse());

      const currentTherapistIdx = therapists.findIndex(
        t => t.id === secondaryTherapistId,
      );
      if (currentTherapistIdx !== -1) {
        setSelectedTherapistIdx([currentTherapistIdx]);
      }

      const profile =
        await cloudContext.profileService.getProfileBySubjectId(subjectId);

      if (!profile) {
        throw new Error('No profile found for the given subject');
      }

      // get sensor info for force-unpairing
      const { leftHistory, rightHistory } =
        await cloudContext.sensorHistoryService.getSubjectsCurrentSensorHistory(
          profile,
        );
      const { leftUnpairedHistory, rightUnpairedHistory } =
        await cloudContext.sensorHistoryService.getSubjectsLastUnpairedHistory(
          profile,
        );

      const newForceUnpairSensors: SensorHistory[] = [];
      if (
        leftHistory &&
        leftUnpairedHistory &&
        !leftHistory.get('connected') &&
        !leftUnpairedHistory.get('forceUnpaired')
      ) {
        newForceUnpairSensors.push(leftUnpairedHistory);
      }

      if (
        rightHistory &&
        rightUnpairedHistory &&
        !rightHistory.get('connected') &&
        !rightUnpairedHistory.get('forceUnpaired')
      ) {
        newForceUnpairSensors.push(rightUnpairedHistory);
      }

      setForceUnpairSensors(newForceUnpairSensors);
    };
    await genericDataFetch(fetcher, 'Error fetching table data!');
  }, [
    cloudContext.profileService,
    cloudContext.sensorHistoryService,
    constructedDateRangeOptions,
    genericDataFetch,
    getAllTherapists,
    getGDMTrendData,
    getMostRecentSync,
    getSensorStatuses,
    secondaryTherapistId,
    sensorHistoryFetcher,
    subjectId,
    subjectSummaryFetcher,
  ]);

  const fetchOnDateRangeChange = useCallback(async () => {
    if (
      selectedDateRange === undefined ||
      graphsDateRangeOptions === undefined
    ) {
      return;
    }
    const { startDate, endDate } = graphsDateRangeOptions[selectedDateRange];
    const exercises = await getExerciseWeeklyData(startDate);
    setExerciseGraphData(exercises);
    const daaps = await getDaapsData(startDate);
    setDaapsGraphData(daaps);
    // because we're viewing the data for a fixed period in time, we keep the local time of
    // start and end dates when querying
    const start = DateTime.fromJSDate(startDate).setZone(timezone, {
      keepLocalTime: true,
    });
    const end = DateTime.fromJSDate(endDate).setZone(timezone, {
      keepLocalTime: true,
    });
    const gdmInfos = await gdmInfoBasedGraphQuery(start, end);
    if (!gdmInfos) {
      logger.warn('No GDM infos found');
    }
    setGdmInfoData(gdmInfos);
    setStartDate(start);
  }, [
    selectedDateRange,
    graphsDateRangeOptions,
    getExerciseWeeklyData,
    getDaapsData,
    timezone,
    gdmInfoBasedGraphQuery,
    logger,
  ]);

  const getQRCodeForSubject = useCallback(async () => {
    try {
      const subjectToken = await getSubjectToken(subjectId);
      if (subjectToken) {
        setToken(subjectToken);
        setShowPopup(true);
      }
    } catch (e) {
      logger.error(e);
      alert(`Failed to get subject token ${e}`);
    }
  }, [getSubjectToken, logger, subjectId]);

  const startStudy = useCallback(async () => {
    try {
      setLoading(true);
      const latestGoal = gdmTrendData?.newGoalCount;
      if (group === Group.INTERVENTION && latestGoal === undefined) {
        navigate(ROUTES.NEW_GDM.buildPath({ subjectId }));
      } else {
        await cloudContext.profileService.activate(subjectId, new Date());
      }
    } catch (e) {
      alert('Failed to activate profile' + e);
    } finally {
      setLoading(false);
    }
  }, [
    cloudContext.profileService,
    gdmTrendData?.newGoalCount,
    group,
    navigate,
    setLoading,
    subjectId,
  ]);

  const endStudy = useCallback(async () => {
    try {
      if (
        confirm(
          i18n.translate('subject.confirmEndStudy', { subjectId: subjectId }),
        )
      ) {
        setLoading(true);
        await cloudContext.profileService.deactivate(subjectId, new Date());
      }
    } catch (e) {
      alert('Failed to deactivate profile' + e);
    } finally {
      setLoading(false);
    }
  }, [cloudContext.profileService, setLoading, subjectId]);

  // fetch the statis data on load
  useEffect(() => {
    try {
      setLoading(true);
      fetchOnLoad();
    } catch (e) {
      logger.error('Error on load data!', e);
    } finally {
      setLoading(false);
    }
  }, [fetchOnLoad, logger, setLoading]);

  // listen for changes with the date range and fetch data as needed
  useEffect(() => {
    fetchOnDateRangeChange().catch(e => {
      logger.error(`Error fetching on date change ${e}`);
    });
  }, [
    fetchOnDateRangeChange,
    selectedDateRange,
    graphsDateRangeOptions,
    logger,
  ]);

  const canUpdateInfo = useMemo(() => {
    return ROUTES_TYPE.internal.some(role => roles?.includes(role));
  }, [roles]);

  return isActivated == undefined ? null : (
    <div style={styles.pageContainer}>
      <div style={styles.pageHeader}>
        <h1 style={styles.h1}>{i18n.t('subject.title', { subjectId })}</h1>
        <div style={styles.btnGroup}>
          {!deactivatedAt && (
            <button onClick={getQRCodeForSubject} style={styles.qrcodeButton}>
              <QrCodeIcon style={styles.qrcodeIcon} />
            </button>
          )}
          {deactivatedAt ? null : !isActivated ? (
            <button
              onClick={startStudy}
              style={{ ...styles.rightButton, ...styles.startButton }}>
              {i18n.t('subject.startStudy')}
            </button>
          ) : (
            <>
              {canUpdateInfo && (
                <button
                  style={styles.rightButton}
                  onClick={() =>
                    navigate(ROUTES.UPDATE_INFO.buildPath({ subjectId }))
                  }>
                  {i18n.t('subject.updateInfo')}
                </button>
              )}
              <button
                style={styles.rightButton}
                onClick={() =>
                  navigate(ROUTES.UPDATE_SENSORS.buildPath({ subjectId }))
                }>
                {i18n.t('subject.updateSensors')}
              </button>
              <DropDownMultiSelect
                options={therapists.map(t => t.id)}
                labels={therapists.map(t => t.getUsername() ?? 'Unknown')}
                selectedIndexes={selectedTherapistIdx}
                setSelectedIndexes={onSecondaryTherapistSelected}
                style={styles.rightButton}
                defaultText={i18n.t('subject.setSecondaryTherapist')}
              />
              {group === Group.INTERVENTION && (
                <DropDownSelect
                  options={[
                    i18n.t('subject.setDaapGoal'),
                    i18n.t('subject.setGdmGoal'),
                  ]}
                  setSelectionIndex={index => {
                    if (index === 0) {
                      navigate(ROUTES.NEW_DAAP.buildPath({ subjectId }));
                    } else if (index === 1) {
                      navigate(ROUTES.NEW_GDM.buildPath({ subjectId }));
                    }
                  }}
                  placeholder={i18n.t('subject.setGoal')}
                  style={styles.rightButton}
                  menuAlignment="right"
                />
              )}
              {roles?.includes(StrokeWearRoles.OperationsManager) && (
                <>
                  <DropDownSelect
                    options={forceUnpairSensors.map(s =>
                      s.get('sensor').get('mac'),
                    )}
                    setSelectionIndex={async index => {
                      if (index !== undefined) {
                        const sensor = forceUnpairSensors[index];
                        try {
                          await cloudContext.sensorHistoryService.forceUnpairSensor(
                            sensor,
                          );
                          await fetchOnLoad();
                        } catch (e) {
                          logger.error('Failed to force unpair sensor' + e);
                          alert('Failed to force unpair sensor' + e);
                        }
                      }
                    }}
                    placeholder={i18n.t('subject.sensorSkip')}
                    style={styles.rightButton}
                    menuAlignment="right"
                  />
                  <button
                    onClick={endStudy}
                    style={{ ...styles.rightButton, ...styles.endButton }}>
                    {i18n.t('subject.endStudy')}
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div style={{ ...styles.tableSectionContainer }}>
        <div style={{ ...styles.rowContainer, ...styles.tableTopRow }}>
          <h2 style={styles.h2}>{i18n.t('tables.subjectSummary.title')}</h2>
        </div>
        <Table
          columnHelper={subjectSummarySolumnHelper}
          columns={SUBJECT_SUMMARY_COLUMNS}
          data={subjectSummaryTableData}
        />
      </div>

      <div style={{ ...styles.rowContainer, ...styles.summaryContainer }}>
        <div style={{ ...styles.rowContainer, ...styles.tableTopRow }}>
          <h2 style={styles.h2}>{i18n.t('subject.summaryInformation')}</h2>
        </div>
        <div style={{ ...styles.summaryBoxesContainer }}>
          <Tile style={styles.summaryTile}>
            <SensorStatus
              type={SensorType.LEFT}
              title={i18n.t('subject.leftSensor')}
              batteryPercentage={sensorStatuses?.left.batteryPercentage}
              flashPercentage={sensorStatuses?.left.flashPercentage}
              connectionStatus={sensorStatuses?.left.connectionStatus}
              lastSync={sensorStatuses?.left.lastSync}
            />
          </Tile>
          <Tile style={styles.summaryTile}>
            <SensorStatus
              type={SensorType.RIGHT}
              title={i18n.t('subject.rightSensor')}
              batteryPercentage={sensorStatuses?.right.batteryPercentage}
              flashPercentage={sensorStatuses?.right.flashPercentage}
              connectionStatus={sensorStatuses?.right.connectionStatus}
              lastSync={sensorStatuses?.right.lastSync}
            />
          </Tile>
          {group === Group.INTERVENTION && isActivated && (
            <>
              <Tile style={styles.summaryTile}>
                <GDMGoal
                  title={i18n.t('subject.gdmGoal.title')}
                  description={i18n.t('subject.gdmGoal.description')}
                  goal={gdmTrendData?.newGoalCount ?? 0}
                  Icon={GDMGoalAward}
                />
              </Tile>
              <Tile style={styles.summaryTile}>
                <GDMTrend
                  title={i18n.t('subject.gdmTrend.title')}
                  description={i18n.t('subject.gdmTrend.description')}
                  previousGoalCount={gdmTrendData?.previousGoalCount}
                  currentGoalCount={gdmTrendData?.newGoalCount}
                  Icon={GDMTrendPrimary}
                />
              </Tile>
            </>
          )}
          <Tile style={styles.summaryTile}>
            <MostRecentSync
              title={i18n.t('subject.mostRecentSync.title')}
              description={i18n.t('subject.mostRecentSync.description')}
              mostRecentSync={mostRecentSync?.lastUsage}
              Icon={SyncPrimary}
            />
          </Tile>
        </div>
      </div>

      <div style={{ ...styles.rowContainer, ...styles.tableSectionContainer }}>
        <div style={{ ...styles.rowContainer, ...styles.tableTopRow }}>
          <h2 style={styles.h2}>{i18n.t('subject.studyData')}</h2>
          <DatePaginationAndFilter
            labels={graphsDateRangeOptions?.map(d => d.asString)}
            options={graphsDateRangeOptions}
            selectedOptionIndex={selectedDateRange}
            setSelectedOptionIndex={setSelectedDateRange}
          />
        </div>
        <div style={styles.graphContainer}>
          <div style={styles.leftGraphs}>
            <ExpansionTile
              onExpand={() => navigate(ROUTES.HEPS.buildPath({ subjectId }))}
              title={i18n.t('graphs.exercise.title')}
              titleStyle={styles.graphTitle}
              style={styles.leftGraph}>
              <WeekTiles
                weeklyData={exerciseGraphData ?? []}
                label={i18n.t('graphs.exercise.label')}
              />
            </ExpansionTile>
            {group === Group.INTERVENTION && (
              <ExpansionTile
                onExpand={() => navigate(ROUTES.DAAPS.buildPath({ subjectId }))}
                title={i18n.t('graphs.daap.title')}
                titleStyle={styles.graphTitle}
                style={styles.leftGraph}>
                {daapsGraphData?.map(({ weeklyData, name }, index) => (
                  <WeekTiles key={index} weeklyData={weeklyData} label={name} />
                ))}
              </ExpansionTile>
            )}
          </div>
          <div style={styles.rightGraphs}>
            <ExpansionTile
              onExpand={() =>
                navigate(ROUTES.COMPLIANCE.buildPath({ subjectId }))
              }
              title={i18n.t('subject.complianceGraphTitle')}
              titleStyle={styles.graphTitle}
              style={styles.complianceGraphTile}
              ref={complianceRef}>
              {startDate && (
                <ComplianceBarGraph
                  gdmInfoData={gdmInfoData}
                  containerRef={
                    complianceRef as unknown as MutableRefObject<HTMLDivElement | null>
                  }
                  startDate={startDate}
                  subjectId={subjectId}
                />
              )}
            </ExpansionTile>
            <ExpansionTile
              onExpand={() => navigate(ROUTES.GDMS.buildPath({ subjectId }))}
              title={i18n.t('subject.movementGraphTitle')}
              titleStyle={styles.graphTitle}
              style={styles.complianceGraphTile}
              leftOfExpandItems={
                <GDMGraphToggle
                  gdmGraphSelection={gdmGraphSelection}
                  setGdmGraphSelection={setGdmGraphSelection}
                />
              }
              ref={gdmRef}>
              {startDate && (
                <MovementBarGraph
                  gdmInfoData={gdmInfoData}
                  containerRef={
                    gdmRef as unknown as MutableRefObject<HTMLDivElement | null>
                  }
                  gdmGraphSelection={gdmGraphSelection}
                  startDate={startDate}
                  subjectId={subjectId}
                />
              )}
            </ExpansionTile>
          </div>
        </div>
      </div>
      <div
        style={{
          ...styles.rowContainer,
          ...styles.tableSectionContainer,
          ...styles.lastRow,
        }}>
        <div style={{ ...styles.rowContainer, ...styles.tableTopRow }}>
          <h2 style={styles.h2}>
            {i18n.t('tables.historicalSensorData.title')}
          </h2>
          <div style={styles.btnGroup}>
            <ExportDropDown style={styles.exportButton} />
          </div>
        </div>
        <Table
          columnHelper={sensorHistoryColumnHelper}
          columns={HISTORICAL_SENSOR_DATA_COLUMNS}
          data={sensorHistoryTableData}
          sorting={sensorHistorySorting}
          setSorting={setSensorHistorySorting}
        />
      </div>
      {showPopup && token !== undefined && (
        <Popup
          open={showPopup}
          onClose={() => setShowPopup(false)}
          title={subjectId}
          buttons={[
            {
              title: `${i18n.t('globals.dismiss')}`,
              onClick: () => setShowPopup(false),
              style: styles.btn,
            },
          ]}>
          <QRCodeDisplay
            token={token}
            instruction={i18n.t('subject.qrcodeInstruction', {
              subjectId,
            })}
          />
        </Popup>
      )}
    </div>
  );
}
