/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import DropDownMultiSelectStyles from './DropDownSelectStyles';
import { type DropDownSelectProps } from './DropDownSelect';

// The Omit just removes the selectionIndex and setSelectionIndex from the DropDownSelectProps which we don't need here
export interface DropDownMultiSelectProps
  extends Omit<DropDownSelectProps, 'selectionIndex' | 'setSelectionIndex'> {
  selectedIndexes?: number[];
  setSelectedIndexes: (selectedIndexes: number[]) => void;
  defaultText?: string; // button text, won't change on selection change
}

const DropDownMultiSelect = (props: DropDownMultiSelectProps) => {
  const { styles } = useThemedComponent([DropDownMultiSelectStyles]);

  // check if labels are provided and have the same length as options
  if (props.labels && props.labels.length !== props?.options?.length) {
    throw new Error('Labels must have the same length as options');
  }

  return (
    <div>
      {/* Based of https://tailwindui.com/components/ecommerce/components/category-filters#component-cb75aacd7c636865835476c98f606a38 */}
      <Menu as="div" style={styles.menuStyles}>
        <Menu.Button style={{ ...styles.menuButton, ...props.style }}>
          <span>
            {props.defaultText ??
              (props?.selectedIndexes?.length === 0 && props.placeholder
                ? props.placeholder
                : 'Selected: ' + props?.selectedIndexes?.length)}
          </span>
          <ChevronDownIcon aria-hidden="true" style={styles.chevronIcon} />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items
            style={{
              ...styles.menuItems,
              ...(props.maxOptionsBeforeScroll
                ? {
                    maxHeight: `${props.maxOptionsBeforeScroll * 1.875}rem`,
                  }
                : {}),
              ...(props.menuAlignment === 'right' ? { right: 0 } : { left: 0 }),
              ...props.menuStyle,
            }}>
            {props?.options?.map((option, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <label
                    style={{
                      ...(active || props.selectedIndexes?.includes(index)
                        ? {
                            ...styles.menuItem,
                            ...styles.activeMenuItem,
                          }
                        : styles.menuItem),
                      ...styles.checkableMenuItem,
                    }}
                    onClick={e => {
                      // prevent the menu from closing when clicking on the checkbox
                      e.stopPropagation();
                      e.preventDefault();
                      // check or uncheck the option
                      if (props?.selectedIndexes?.includes(index)) {
                        props.setSelectedIndexes(
                          props?.selectedIndexes?.filter(
                            item => item !== index,
                          ),
                        );
                      } else {
                        props.setSelectedIndexes([
                          ...(props?.selectedIndexes ?? []),
                          index,
                        ]);
                      }
                    }}>
                    <input
                      type="checkbox"
                      style={styles.checkbox}
                      checked={props?.selectedIndexes?.includes(index)}
                      onChange={() => {}} // needed to prevent react warning
                    />
                    {props.labels &&
                    props.labels.length === props?.options?.length
                      ? props.labels[index]
                      : option}
                  </label>
                )}
              </Menu.Item>
            ))}
            {props.extraChildren}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default DropDownMultiSelect;
