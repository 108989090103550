import React, { useCallback, useEffect, useState } from 'react';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import { useTableColumns } from '../../../hooks/useTableColumns';
import {
  DateRangeOption,
  HEPInformationColumns,
  SortOrder,
  SubjectSummaryTableColumns,
} from '../../../types/tables';
import { useLoading } from '../../../providers/LoadingProvider';
import i18n from '../../../translations';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import Table from '../../../components/templates/Table/Table';
import DatePaginationAndFilter from '../../../components/organisms/DatePaginationAndFilter/DatePaginationAndFilter';
import { useCloudContext } from '../../../providers/CloudProvider';
import { makeDateRangeOptions } from '../../../utils/tables';
import { commonTableStyles } from '../../../components/templates/Table/tableUtil';
import {
  HEP_INFORMATION_COLUMNS,
  SUBJECT_SUMMARY_COLUMNS,
  transformForExport,
} from './helpers';
import { DEFAULT_TIMEZONE, dateToISOString } from '../../../utils/dateTime';
import { useLogging } from '../../../providers/LoggingProvider';

export interface HEPPageParams {
  subjectId: string;
}
interface HEPPageDataResponse {
  subjectId: string;
}

export const loader = async ({
  params,
}: LoaderFunctionArgs<HEPPageParams>): Promise<HEPPageDataResponse | Error> => {
  try {
    if (!params.subjectId) {
      return new Error('Subject ID is required');
    }

    return {
      subjectId: params.subjectId,
    };
  } catch (e) {
    console.error('Error fetching GDM page data', e);
    return new Error('Error fetching GDM page data');
  }
};

export default function HEPPage() {
  const pageData = useLoaderData() as HEPPageDataResponse;
  const { setLoading } = useLoading();
  const { styles } = useThemedComponent([commonTableStyles]); // using global styles and common table styles
  const [dateRangeOptions, setDateRangeOptions] = useState<DateRangeOption[]>();
  const [timezone, setTimezone] = useState<string>(DEFAULT_TIMEZONE);

  const { cloudService, profileService, exerciseService } = useCloudContext();

  const [selectedHEPDateRangeIndex, setSelectedHEPDateRangeIndex] = useState<
    number | undefined
  >();

  // table stuff
  const {
    columnHelper: subjectSummarySolumnHelper,
    tableData: subjectSummaryTableData,
    setTableData: setSubjectSummaryTableData,
    sorting: subjectSummarySorting,
  } = useTableColumns<SubjectSummaryTableColumns>();

  const {
    columnHelper: hepInformationColumnHelper,
    tableData: hepInformationTableData,
    setTableData: setHepInformationTableData,
    ExportDropDown: HEPExportDropDown,
    sorting: hepInformationSorting,
    setSorting: setHepInformationSorting,
  } = useTableColumns<HEPInformationColumns>({
    defaultSorting: [{ colKey: 'date', index: 1, order: SortOrder.DESC }],
    tableName: i18n.t('tables.hepInformation.title'),
    transformExportData: transformForExport,
  });

  const logger = useLogging(HEPPage.name);

  // get the date range options
  useEffect(() => {
    const generateDateRangeOptions = async () => {
      const profile = await profileService.getProfileBySubjectId(
        pageData.subjectId,
      );
      if (!profile) {
        throw new Error('Profile not found');
      }
      setTimezone(profile.attributes.timezone ?? timezone);
      const firstMovement = await exerciseService.getFirstExercise(profile);

      if (!firstMovement) {
        logger.warn('No movements found for date range options');
        return [];
      }

      return makeDateRangeOptions(
        firstMovement.get('startTime'),
        profile.get('deactivatedAt') ?? new Date(),
      );
    };
    // make our api calls
    setLoading(true);

    generateDateRangeOptions()
      .then(dateRangeOptions => {
        setDateRangeOptions(dateRangeOptions.reverse()); // most recent week first
      })
      .catch(e => {
        logger.error('Error generating date range options', e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    exerciseService,
    logger,
    pageData.subjectId,
    profileService,
    setLoading,
    timezone,
  ]);

  const dataFetcher = useCallback(async () => {
    try {
      // make our api calls
      setLoading(true);

      // get the subject summary data
      const subjectSummaryRes = await cloudService.requestSubjectSummary({
        subjectId: pageData.subjectId,
        sort: subjectSummarySorting,
      });
      const newSubjectSummaryData: SubjectSummaryTableColumns[] =
        subjectSummaryRes.data;

      // set the data for the component to use
      setSubjectSummaryTableData(newSubjectSummaryData);

      if (selectedHEPDateRangeIndex !== undefined && dateRangeOptions) {
        const { startDate, endDate } =
          dateRangeOptions[selectedHEPDateRangeIndex];
        const startDateISO = dateToISOString(startDate, timezone);
        const endDateISO = dateToISOString(endDate, timezone);
        const hepInfoRed = await cloudService.requestHEPInfo({
          subjectId: pageData.subjectId,
          sort: hepInformationSorting,
          startDateISO,
          endDateISO,
        });
        setHepInformationTableData(hepInfoRed.data);
      }
    } catch (e) {
      logger.error(
        'Error fetching subject summary or hep information tables!',
        e,
      );
    } finally {
      setLoading(false);
    }
  }, [
    cloudService,
    dateRangeOptions,
    hepInformationSorting,
    logger,
    pageData.subjectId,
    selectedHEPDateRangeIndex,
    setHepInformationTableData,
    setLoading,
    setSubjectSummaryTableData,
    subjectSummarySorting,
    timezone,
  ]);

  useEffect(() => {
    // if no date range options, select the first one and return
    // this will retrigger the useEffect
    if (
      dateRangeOptions &&
      dateRangeOptions.length > 0 &&
      selectedHEPDateRangeIndex === undefined
    ) {
      setSelectedHEPDateRangeIndex(0);
    }
    dataFetcher().catch(e => {
      logger.error(e);
    });
  }, [
    selectedHEPDateRangeIndex,
    dateRangeOptions,
    hepInformationSorting,
    logger,
    dataFetcher,
  ]);

  return (
    <div style={styles.pageContainer}>
      <div style={styles.pageHeader}>
        <h1 style={styles.h1}>
          {i18n.t('subject.title', { subjectId: pageData.subjectId })}
        </h1>
      </div>
      <div style={styles.tableSectionContainer}>
        <div style={{ ...styles.rowContainer, ...styles.tableTopRow }}>
          <h2 style={styles.h2}>{i18n.t('tables.subjectSummary.title')}</h2>
        </div>
        <Table
          columnHelper={subjectSummarySolumnHelper}
          columns={SUBJECT_SUMMARY_COLUMNS}
          data={subjectSummaryTableData}
        />
      </div>
      <div style={{ ...styles.rowContainer, ...styles.tableSectionContainer }}>
        <div style={{ ...styles.rowContainer, ...styles.tableTopRow }}>
          <h2 style={styles.h2}>{i18n.t('tables.hepInformation.title')}</h2>
          <div style={styles.btnGroup}>
            <DatePaginationAndFilter
              labels={dateRangeOptions?.map(option => option.asString)}
              options={dateRangeOptions}
              selectedOptionIndex={selectedHEPDateRangeIndex}
              setSelectedOptionIndex={setSelectedHEPDateRangeIndex}
            />
            <HEPExportDropDown
              style={{ ...styles.rightButton, ...styles.exportButton }}
            />
          </div>
        </div>
        <Table
          columnHelper={hepInformationColumnHelper}
          columns={HEP_INFORMATION_COLUMNS}
          data={hepInformationTableData}
          sorting={hepInformationSorting}
          setSorting={setHepInformationSorting}
        />
      </div>
    </div>
  );
}
