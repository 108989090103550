import {
  useThemedComponent,
  StyleSheet,
} from '../../../providers/ThemeProvider';

export type GraphLabel = {
  label: string;
  color: string;
};

export interface GraphLabelsProps {
  labels: GraphLabel[];
}

export const GraphLabels = (props?: GraphLabelsProps) => {
  const { styles } = useThemedComponent([GraphLabelsStyle]);
  return (
    <div style={styles.graphLabelsContainer}>
      {props?.labels.map(({ label, color }, index) => (
        <div key={index} style={styles.graphLabelContainer}>
          <div
            style={{
              ...styles.graphLabelColorIndicator,
              backgroundColor: color,
            }}></div>
          <span style={styles.graphLabel}>{label}</span>
        </div>
      ))}
    </div>
  );
};

const GraphLabelsStyle = (): StyleSheet => ({
  graphLabelsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5%',
    margin: '5% 0',
  },
  graphLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
  graphLabelColorIndicator: {
    height: '1rem',
    width: '1rem',
    borderRadius: 5,
  },
  graphLabel: {
    fontWeight: 'bold',
  },
});
