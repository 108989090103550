import React, { ReactNode, forwardRef } from 'react';
import {
  ThemeContent,
  useThemedComponent,
  StyleSheet,
} from '../../../providers/ThemeProvider';

interface TileProps {
  children: ReactNode;
  style?: React.CSSProperties;
}
/**
 * Generic Tile for displaying content
 * @param props TileProps
 * @returns The tile template
 */
const Tile = forwardRef((props: TileProps, ref) => {
  const { styles } = useThemedComponent([TileStyles]);

  return (
    <section
      style={{ ...styles.container, ...props.style }}
      ref={ref as React.RefObject<HTMLElement> | undefined}>
      {props.children}
    </section>
  );
});

Tile.displayName = 'Tile'; // Add display name to the component

export default Tile;

const TileStyles = (theme: ThemeContent): StyleSheet => ({
  container: {
    backgroundColor: theme.colors.white,
    boxShadow: `0 0 0.6250em 0 ${theme.colors.transparentBlackShadow}`,
  },
});
