import { useMemo } from 'react';
import useErrorHandling from '../hooks/useErrorHandling';
import Parse from '../parse';
import { Profile } from './Profile';
import { OwnableObject } from './interfaces/OwnableObject';

/**
 * A Goal represents the goal that the user sets.
 * It represents the time frame of a day
 * The `startTime` is when the goal was made active, any goal with a later
 * start time (within the same day) is the true goal for that day.
 */
export interface GoalAttributes extends OwnableObject {
  startDate: Date;
  count: number;
}

export const classname = 'Goal';

export class Goal extends Parse.Object<GoalAttributes> {
  constructor(attributes: GoalAttributes) {
    super(classname, attributes);
  }
}

Parse.Object.registerSubclass(classname, Goal);

export function useGoalService() {
  const getTimezone = useErrorHandling(async (profile: Profile) => {
    const query = new Parse.Query(Goal);
    query.equalTo('owner', profile);
    query.descending('localCreatedAt');
    query.limit(1);

    const lastGoal = await query.first();
    if (lastGoal != undefined) {
      return lastGoal.attributes.timezone;
    }
    return profile.attributes.timezone;
  }, []);

  /**
   * Gets the most recent goals for a user in descending order
   * @param profile The profile of the user to query the goals for
   * @param limit Limits the query to this number of goals
   * @param before Filter the query for goals that started before this date
   * @returns A list of the most recent goals in descending order
   */
  const getMostRecentNumberOfGoals = useErrorHandling(
    async (profile: Profile, limit: number, before?: Date): Promise<Goal[]> => {
      const query = new Parse.Query(Goal);
      query.equalTo('owner', profile);
      query.descending('startDate');
      query.addDescending('createdAt');
      query.limit(limit);
      if (before) {
        query.lessThanOrEqualTo('startDate', before);
      }
      return await query.find();
    },
    [],
  );

  const setGoal = useErrorHandling(
    async (profile: Profile, count: number, startDate: Date) => {
      const timezone = await getTimezone(profile);
      const goal = new Goal({
        owner: profile,
        count,
        startDate,
        localCreatedAt: new Date(),
        timezone,
      });

      const acl = profile.getACL();
      if (acl) goal.setACL(acl);

      const result = await goal.save();
      return result;
    },
    [],
  );

  return useMemo(
    () => ({
      setGoal,
      getMostRecentNumberOfGoals,
    }),
    [getMostRecentNumberOfGoals, setGoal],
  );
}
