import {
  ThemeContent,
  type StyleSheet,
} from '../../../providers/ThemeProvider';

export default (theme: ThemeContent): StyleSheet => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 10,
  },
  titleText: {
    fontWeight: 'bold',
    fontSize: 24,
  },
  buttonContainer: {
    display: 'flex',
    gap: 10,
    marginTop: 20,
    width: '100%',
  },
  blueText: {
    color: theme.colors.blue,
  },
  greenText: {
    color: theme.colors.green,
  },
});
