import { useThemedComponent } from '../../../providers/ThemeProvider';
import GDMGoalStyles from './GDMGoalStyles';

export interface GDMGoalProps {
  title: string;
  description: string;
  goal: number;
  style?: React.CSSProperties;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconStyle?: React.CSSProperties;
}

const GDMGoal = ({
  title,
  description,
  goal,
  Icon,
  iconStyle,
}: GDMGoalProps) => {
  const { styles } = useThemedComponent([GDMGoalStyles]);

  return (
    <div style={styles.container}>
      <div style={styles.rightSection}>
        <h1 style={styles.h1}>{description}</h1>
        <div style={styles.flexRow}>
          <Icon style={{ ...styles.icon, ...iconStyle }} />
          <div style={styles.p}>{title}</div>
          <div style={{ ...styles.p, ...styles.moveToEnd }}>{goal}</div>
        </div>
      </div>
    </div>
  );
};

export default GDMGoal;
