import React, { useMemo } from 'react';
import {
  type StyleSheet,
  useThemedComponent,
} from '../../../providers/ThemeProvider';
import { ReactComponent as CheckCircle } from '../../../assets/CheckCircle.svg';
import { CompletionStatus } from '../WeekTiles/WeekTiles';

export interface BoxTileProps {
  status: CompletionStatus;
  label: string;
  underTileLabel: string;
}

export const BoxTile = ({ status, label, underTileLabel }: BoxTileProps) => {
  const { styles, theme } = useThemedComponent(BoxTileStyles);
  const statusStyles = useMemo(() => {
    const statusStyle = {
      box: {},
      label: {},
      underTileLabel: {},
    };
    switch (status) {
      case CompletionStatus.COMPLETE:
        (statusStyle.box = {
          backgroundColor: theme.colors.green,
        }),
          (statusStyle.label = {
            color: theme.colors.white,
          });
        break;
      case CompletionStatus.TODAY:
        statusStyle.box = {
          backgroundColor: theme.colors.navy,
        };
        statusStyle.label = {
          color: theme.colors.white,
        };
        statusStyle.underTileLabel = {
          fontWeight: '500',
        };
        break;
      case CompletionStatus.INCOMPLETE: {
        statusStyle.box = {
          backgroundColor: theme.colors.gray,
        };
        statusStyle.label = {
          color: theme.colors.primary,
        };
        break;
      }
    }
    return statusStyle;
  }, [
    status,
    theme.colors.gray,
    theme.colors.green,
    theme.colors.navy,
    theme.colors.primary,
    theme.colors.white,
  ]);

  return (
    <div style={styles.container}>
      <div style={{ ...styles.box, ...statusStyles.box }}>
        <p style={{ ...styles.label, ...statusStyles.label }}>{label}</p>
        {status === CompletionStatus.COMPLETE && (
          <CheckCircle style={styles.icon} color={theme.colors.green} />
        )}
      </div>
      <p style={{ ...styles.underLabel, ...statusStyles.underTileLabel }}>
        {underTileLabel}
      </p>
    </div>
  );
};

const BoxTileStyles = (): StyleSheet => ({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
    width: '13%',
  },
  box: {
    height: 30,
    borderRadius: '0.3rem',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  label: {
    fontWeight: 'bold',
    position: 'absolute',
    left: '5%',
  },
  underLabel: {
    marginTop: '5%',
    fontSize: '0.8rem',
    textAlign: 'center',
  },
  icon: {
    position: 'absolute',
    height: '1.5rem',
    width: '1.5rem',
    right: '5%',
  },
});
