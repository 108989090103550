import { transformForExportBase } from '../../../components/templates/Table/tableUtil';
import i18n from '../../../translations';
import {
  ExternalUserRoles,
  GetNonSubjectUsersResponse,
} from '../../../types/cloud';
import {
  type ExternalUserRolesColumns,
  type CustomColumnDefintion,
  CustomTableColumns,
} from '../../../types/tables';

/**
 * The columns definition for the set user roles page.
 * This is the order of the columns in the table and any customizations
 */
export const ROLE_COLUMNS: CustomColumnDefintion[] = [
  {
    column: 'externalUser',
    headerName: i18n.t('setExternalUserRoles.externalUser'),
  },
  { column: 'dateAdded', headerName: i18n.t('setExternalUserRoles.dateAdded') },
  {
    column: 'role',
    headerName: i18n.t('setExternalUserRoles.role'),
  },
];

export const formatAllNonSubjectUsers = async (
  users: GetNonSubjectUsersResponse,
) => {
  const userRows: ExternalUserRolesColumns[] = users.map(({ user, roles }) => {
    // only show external roles
    const externalRoles = roles.filter(r =>
      Object.keys(ExternalUserRoles).includes(r),
    );
    return {
      id: user.id, // this is used for row selection
      externalUser: user.get('username'),
      dateAdded: user.createdAt,
      role:
        externalRoles.length === 0
          ? '--'
          : externalRoles
              .map(r => i18n.t(`setExternalUserRoles.roles.${r}`))
              .join(', '),
    } as ExternalUserRolesColumns;
  });
  return userRows;
};

export const transformForExport = (data: CustomTableColumns[]) => {
  return transformForExportBase(data, ROLE_COLUMNS);
};
