/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  type SortedCol,
  type CustomColumnDefintion,
  type CustomTableColumns,
  type TableOptions,
  CustomTableColumnKeys,
  SortOrder,
  TableLink,
} from '../../../types/tables';
import {
  type ColumnHelper,
  useReactTable,
  flexRender,
  getCoreRowModel,
  type Header,
  type RowSelectionState,
} from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../router';

import { useThemedComponent } from '../../../providers/ThemeProvider';
import TableStyles from './TableStyles';
import { ReactSVG } from 'react-svg';
import QuestionMarkCircle from '../../../assets/QuestionMarkCircle.svg';
import UpArrow from '../../../assets/UpArrow.svg';
import DownArrow from '../../../assets/DownArrow.svg';
import { onSortColumn } from './tableUtil';
import IndeterminateCheckbox from '../../atoms/IntermediateCheckbox';

export interface TableProps {
  /**
   * The columns to display in the table.
   */
  columns: CustomColumnDefintion[];
  /**
   * The column helper to use to access the data.
   */
  columnHelper: ColumnHelper<CustomTableColumns>;
  /**
   * The function to fetch the data, when there are changes to the table.
   * For example, when the user sorts the table.
   */
  data: CustomTableColumns[];
  /**
   * The total number of items in the table. Different from the length of the data
   * because the table may be paginated. This is used to calculate the number of
   * pages in the table.
   */
  totalEntries?: number;
  /**
   * The initial options for the table. This is used to set the initial sorting and page.
   */
  initialTableOptions?: TableOptions;
  /**
   * The options for the page size. This is used to set the initial page size and
   */
  pageSizeOptions?: number[];
  /**
   * Filters component. Define the filters to be used in the table.
   */
  filters?: React.ReactNode;
  /**
   * Whether rows are selectable or not
   */
  selectable?: boolean;
  /**
   * The current row selection state
   */
  rowSelection?: RowSelectionState;
  /**
   * Set the row selection state
   */
  setRowSelection?: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  /**
   * The current sorting state
   */
  sorting?: SortedCol[];
  /**
   * Set the sorting state
   */
  setSorting?: React.Dispatch<React.SetStateAction<SortedCol[]>>;
  /**
   * Returns a unique row id for each row
   * The ID would be index of the row if this function is not provided
   */
  getRowId?: (row: CustomTableColumns, index: number) => string;
  /**
   * The style for the table container
   */
  style?: React.CSSProperties;
  /**
   * Height for the table, could be use to add extra white space to the bottom of the table
   */
  height?: string;
  /**
   * Ref for the table container
   */
  tableRef?: React.RefObject<HTMLDivElement>;
  /**
   * Sets the algorithm used to lay out <table> cells, rows, and columns.
   * The default value is 'auto', set value to 'fixed' would make the columns spacing more consistent
   * @link https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout
   */
  tableLayout?: 'auto' | 'fixed';
}

const Table = (props: TableProps) => {
  // https://tanstack.com/table/latest/docs/guide/tables#creating-a-table-instance
  const [data, setData] = useState<CustomTableColumns[]>(props.data);
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const navigate = useNavigate();

  const { styles, theme } = useThemedComponent([TableStyles]);

  /**
   * Get the sorting state for the header
   * @param header the header to get the sorting state for
   * @returns what to render for the sorting state
   */
  const getSortingIconState = useCallback(
    (header: Header<CustomTableColumns, unknown>) => {
      const headerSortState = header.column.getIsSorted();
      if (!headerSortState) {
        return '';
      }
      const iconBox = 0.9;
      return headerSortState === 'asc' ? (
        <ReactSVG
          src={UpArrow}
          beforeInjection={svg => {
            svg.setAttribute('color', theme.colors.primary);
            svg.setAttribute('width', `${iconBox}rem`);
            svg.setAttribute('height', `${iconBox}rem`);
            return svg;
          }}
        />
      ) : (
        <ReactSVG
          src={DownArrow}
          beforeInjection={svg => {
            svg.setAttribute('color', theme.colors.primary);
            svg.setAttribute('width', `${iconBox}rem`);
            svg.setAttribute('height', `${iconBox}rem`);
            return svg;
          }}
        />
      );
    },
    [theme.colors.primary],
  );

  /**
   * Get the helper icon for the header if needed
   * @param meta the meta data for the column
   * @returns the helper icon or null
   */
  const getHelper = useCallback(
    (meta?: CustomColumnDefintion) => {
      return meta?.headerHelperText ? (
        <div style={styles.helperContainer}>
          <ReactSVG
            src={QuestionMarkCircle}
            beforeInjection={svg => {
              svg.setAttribute('color', theme.colors.primary);
              svg.setAttribute('width', '1rem');
              svg.setAttribute('height', '1rem');
              return svg;
            }}
          />
        </div>
      ) : null;
    },
    [styles.helperContainer, theme.colors.primary],
  );

  /**
   * Memoized columns for the table, this is used to create the table
   * @link https://tanstack.com/table/latest/docs/guide/column-defs#column-helpers
   */
  const columns = useMemo(() => {
    const mainCols = props.columns.map(obj => {
      const columnKey =
        (obj.column as keyof CustomTableColumnKeys) ?? Error('No column key');
      return props.columnHelper.accessor(columnKey, {
        header: table => {
          return (
            <div
              style={{
                ...styles.headerCellContainer,
                ...(table.header.column.getIsSorted()
                  ? styles.inSortHeader
                  : {}),
                ...obj.headerStyle,
              }}>
              <button
                style={{
                  ...styles.headerText,
                  ...styles.headerButton,
                  ...(obj.sortable === false ? styles.notSortableHeader : {}),
                  textAlign: obj.headerStyle?.textAlign ?? 'center',
                }}
                onClick={() => {
                  onSortColumn(table.header, props.sorting, props.setSorting);
                }}>
                {table.header.isPlaceholder ? null : obj.headerName}
              </button>
              {getSortingIconState(table.header)}
              {getHelper(obj)}
            </div>
          );
        },
        cell: info => {
          const linkObj =
            (info.getValue() as TableLink<unknown>)?.value !== undefined;
          const value = linkObj
            ? (info.getValue() as TableLink<string>)?.value
            : info.getValue() instanceof Date
              ? (info.getValue() as Date)?.toLocaleString()
              : (info.getValue() as string)?.toString();

          return (
            <div style={{ ...styles.cellContainer, ...obj.cellStyle }}>
              {obj.cellTransform ? (
                obj.cellTransform.call(null, value)
              ) : (
                <span
                  style={{
                    ...styles.cellText,
                    ...{
                      color: obj.cellTextColor
                        ? obj.cellTextColor(value)
                        : 'inherit',
                    },
                    ...(obj.cellLink ? styles.link : {}),
                  }}
                  onClick={() => {
                    if (obj.cellLink) {
                      // TODO: provide current daap and subject id through context
                      // for now just list every parameter in the path
                      const path = ROUTES[obj.cellLink].buildPath({
                        subjectId: value,
                        daapId: value,
                        ...obj.cellParams,
                        ...(linkObj
                          ? (info.getValue() as TableLink<unknown>)?.params
                          : undefined),
                      });
                      if (typeof path === 'string') {
                        navigate(path);
                      }
                    }
                  }}>
                  {value}
                </span>
              )}
            </div>
          );
        },
      });
    });

    // if its not a selectable table, return the columns as is
    if (!props.selectable) {
      return mainCols;
    }

    // otherwise, add the select column
    return [
      {
        id: 'select',
        header: ({ table }: { table: any }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }: { row: any }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
      },
      ...mainCols,
    ];
  }, [
    props.columns,
    props.selectable,
    props.columnHelper,
    props.sorting,
    props.setSorting,
    styles.headerCellContainer,
    styles.inSortHeader,
    styles.headerText,
    styles.headerButton,
    styles.notSortableHeader,
    styles.cellContainer,
    styles.cellText,
    styles.link,
    getSortingIconState,
    getHelper,
    navigate,
  ]);

  /**
   * The table instance. This is used to render the table and control the table as a whole.
   * @link https://tanstack.com/table/latest/docs/api/core/table
   */
  const table = useReactTable({
    columns,
    data,
    state: {
      rowSelection: props.rowSelection,
    },
    enableRowSelection: props.selectable ?? false,
    enableMultiRowSelection: props.selectable ?? false,
    onRowSelectionChange: props.setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true, // use pre-sorted row model instead of sorted row model
    enableMultiSort: true,
    getRowId: props.getRowId,
  });

  // add the default sorting to the column
  useEffect(() => {
    // only when sorting is 1, so that we dont force the sort every new multi sort
    if (table && props.sorting && columns && props.sorting.length === 1) {
      const cols = table.getAllColumns();
      for (const col of cols) {
        if (col.id === props.sorting?.[0].colKey) {
          table
            .getColumn(col.id)
            ?.toggleSorting(
              props.sorting?.[0].order === SortOrder.ASC ? false : true,
              true,
            );
        }
      }
    }
  }, [table, props.sorting, columns]);

  return (
    <section
      style={{
        ...styles.container,
        ...props?.style,
        ...{ height: props.height ?? 'auto' },
      }}
      ref={props.tableRef}>
      <div
        style={{
          ...styles.tableContainer,
          ...{ height: props.height ?? 'auto' },
        }}>
        <table
          style={{ ...styles.table, tableLayout: props.tableLayout ?? 'auto' }}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id} style={styles.th}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {/* DIVIDER */}
          <tbody>
            <tr style={styles.divider}></tr>
          </tbody>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map(footerGroup => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map(header => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext(),
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
    </section>
  );
};

export default Table;
