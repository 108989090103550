/**
 * This is a list of fixed percentage point that should be corresponding to
 * the past 7 days movement counts in ascending order
 */
export const FIXED_PERCENTAGE = [0, 15, 30, 45, 60, 70, 85, 100];

/**
 * Are two numbers closely equal to each other?
 * Only if their difference falls between delta range
 * @param a first number
 * @param b second number
 * @param delta the absolute value taht the 2 numbers can be off by
 * @returns
 */
export function approximatelyEqual(a: number, b: number, delta: number) {
  return Math.abs(a - b) < delta;
}

/** GOAL CONVERSION EXAMPLE
 *   R            0     1     2     3     4      5      6      7
 *   P    =   [0%,  15%,  30%,  45%,  60%,  70%,   85%,   100%]  ~ PERCENTAGE FIXED POINTS
 *   M    =   [0,   10,   20,   40,   80,   120,   200,   500 ]  ~ ACTUAL MOVEMENT COUNTS IN ASC ORDER
 *
 *  Steps to convert:
 * 1. Find what range the goal falls within
 * 2. Calculate the difference between lower and upper values in the range
 * 3. Calculate an appropriate fraction from these differences
 * 4. Multiplies the fraction with the difference of the converting value and the lower value
 * 5. Adds an offset value
 *
 * Example: Converting 8 movements to percentage
 * 1. 8 falls within the first range (R[0])
 * 2. Percent diff: 15
 *    Movements diff: 10
 * 3. Fraction: Percent diff / movements diff = 15 / 10 = 1.5
 * 4 & 5. percentage = 1.5 * (8-0) + 0 = 12%
 * Offset is 0 (lowerbound %)
 *
 * Example: Converting 90 movements to percentage
 * 1. 90 falls within the fifth range (R[4])
 * 2. Percent diff: 10,
 *    Movements diff: 40
 * 3. Fraction: Percent diff / movements diff = 10 / 40 = 0.25
 * 4 & 5. percentage = 0.25 * (90-80) + 60 = 62.5%
 * Offset is 60 (lowerbound %)
 *
 * Example: Converting 80% to movements
 * 1. 80 falls within the 6th range (R[5])
 * 2. Percent diff: 15,
 *    Movements diff: 80
 * 3. Fraction: Movements diff / Percent diff = 80 / 15 = 5.33
 * 4 & 5. movements = 5.33 * (85-80) + 120 = 146.65
 * Offset is 120 (lowerbound movements)
 *
 */

/**
 * Converts a movement count to a percentage value
 * @param movementCount the number of movements
 * @param fixedPercents a list of fixed percentage points starting from 0 to 100, in ascending order
 * @param correspondingValues a list of actual movement values corresponding to
 * each fixed percentage point in the above list, also in ascending order
 * @returns the percentage value corresponding to the given movement count,
 * could be decimal value for precision-- handle rounding later if needed
 * @constrains args must have the same length
 */
export function movementsToPercent(
  movementCount: number,
  fixedPercents: number[],
  correspondingValues: number[],
): number {
  if (fixedPercents.length !== correspondingValues.length) {
    throw new Error("Given lists don't have same length. Can't convert");
  }
  const length = correspondingValues.length;

  // Step 1: let's find what range movement count falls within
  let i = -1; // index
  if (movementCount <= 0) {
    return 0;
  } else if (movementCount >= correspondingValues[length - 1]) {
    const percentage = (movementCount / correspondingValues[length - 1]) * 100;
    return percentage;
  } else {
    for (let j = 0; j < length - 1; j++) {
      if (
        correspondingValues[j] <= movementCount &&
        movementCount < correspondingValues[j + 1]
      ) {
        // FOUND the range!!
        i = j;
        break;
      }
    }
  }

  // safe to check-- but the conditions above should handle all cases and i should be invalid
  // excluding the case movementCount = correspondingValues[length-1] because the second condition already handle that
  if (i < 0 || i >= length - 1) {
    throw new Error('Invalid movement counts' + movementCount);
  }

  // Step 2
  const lowerBoundPercent = fixedPercents[i];
  const upperBoundPercent = fixedPercents[i + 1];
  const percentDiffInRange = upperBoundPercent - lowerBoundPercent;

  const lowerBoundCount = correspondingValues[i];
  const upperBoundCount = correspondingValues[i + 1];
  const countDiffInRange = upperBoundCount - lowerBoundCount;

  // Step 3
  const fractionInRange = percentDiffInRange / countDiffInRange;
  // Step 4 & 5
  // Offset value is the lowerBoundPercent %
  const percentage =
    lowerBoundPercent + fractionInRange * (movementCount - lowerBoundCount);

  return percentage;
}

/**
 * Converts a percentage value to an equivalent movement count
 * @param percent the percentage value
 * @param fixedPercents a list of fixed percentage points starting from 0 to 100, in ascending order
 * @param correspondingValues a list of actual movement values corresponding to
 * each fixed percentage point in the above list, also in ascending order
 * @returns the movement count corresponding to the given percentage value,
 * could be decimal value for precision-- handle rounding later if needed
 * @constrains args must have the same length
 */
export function percentToMovements(
  percent: number,
  fixedPercents: number[],
  correspondingValues: number[],
): number {
  if (fixedPercents.length !== correspondingValues.length) {
    throw new Error("Given lists don't have same length. Can't convert");
  }
  const length = fixedPercents.length;

  // Step 1: let's find what range the given percent value falls within
  let i = -1; // index
  if (percent <= 0) {
    return 0;
  } else if (percent >= 100) {
    const movements = (correspondingValues[length - 1] * percent) / 100;
    return movements;
  } else {
    for (let j = 0; j < length - 1; j++) {
      if (fixedPercents[j] <= percent && percent < fixedPercents[j + 1]) {
        // FOUND the range!!!
        i = j;
        break;
      }
    }
  }

  // safe to check-- but the conditions above should handle all cases and i should be invalid
  // excluding the case percent >= 100% here because the second condition already handle that
  if (i < 0 || i >= length - 1) {
    throw new Error('Invalid percentage' + percent);
  }

  // Step 2
  const lowerBoundPercent = fixedPercents[i];
  const upperBoundPercent = fixedPercents[i + 1];
  const percentDiffInRange = upperBoundPercent - lowerBoundPercent;

  const lowerBoundCount = correspondingValues[i];
  const upperBoundCount = correspondingValues[i + 1];
  const countDiffInRange = upperBoundCount - lowerBoundCount;

  // Step 3
  const fractionInRange = countDiffInRange / percentDiffInRange;
  // Step 4 & 5
  // Offset value is the lowerBoundCount movements
  const movements =
    lowerBoundCount + fractionInRange * (percent - lowerBoundPercent);

  return movements;
}
