import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ROUTES } from '../../../router';
import { useAuth } from '../../../providers/AuthProvider';
import { Link } from 'react-router-dom';
import NavBarStyles from './NavBarStyles';
import { useThemedComponent } from '../../../providers/ThemeProvider';
import { ReactSVG } from 'react-svg';
import Logo from '../../../assets/Logo.svg';
import Parse from '../../../parse';
import i18n from '../../../translations';

interface NavBarProps {
  style?: React.CSSProperties;
}

const NavBar = (props: NavBarProps) => {
  const { logout } = useAuth();
  const { styles, theme } = useThemedComponent([NavBarStyles]);
  const [date, setDate] = useState(new Date());
  const animationFrameId = useRef<number>();

  const tick = useCallback(() => {
    if (animationFrameId.current) {
      if (new Date().getMinutes() !== date.getMinutes()) {
        setDate(new Date());
      }
      animationFrameId.current = requestAnimationFrame(tick);
    }
  }, [date]);

  useEffect(() => {
    animationFrameId.current = requestAnimationFrame(tick);
    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, [tick]);

  return (
    <nav style={{ ...styles.nav, ...props.style }}>
      <ul style={{ ...styles.ul }}>
        <li style={{ ...styles.logo }}>
          <Link to={ROUTES.HOME.path}>
            <ReactSVG
              src={`${Logo}`}
              beforeInjection={svg => {
                svg.setAttribute('fill', theme.colors.white);
                return svg;
              }}
            />
          </Link>
        </li>
        <div style={styles.info}>
          <li style={{ ...styles.li, ...styles.bold }}>
            {i18n.t('welcome', {
              username: Parse.User.current()?.get('email') ?? '',
            })}
          </li>
          <li style={{ ...styles.li, ...styles.subText }}>
            {date.toLocaleDateString(i18n.locale, {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              timeZoneName: 'short',
            })}
          </li>
        </div>
        <li style={{ ...styles.li }}>
          <button
            style={styles.primaryButton}
            onClick={() => {
              logout();
            }}>
            {i18n.t('logout')}
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
